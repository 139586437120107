import React, { useState, useEffect } from 'react';
import { Box, FormControl, Typography } from '@material-ui/core';

import ComponentButtons from '../../Shared/ComponentButtons';
import UPFormSection from './UPFormSection';
import { clone } from '../../utils';
import UPFormHelperText from './UPFormHelperText';
import { CRMFormTypes, EMERGENCY_STRING } from '../../Shared/Constants';
import StyledButton from '../StyledButton';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';

const UPForm = ({ sections, onSubmit, onDraftSubmit, isSubmitting, isDraftSubmitting, error, showError, showEmergencyAlert, conditions, success, onChange, slot, sliderMapping, submitButtonText = 'NEXT' }) => {
    const [inputFields, setInputFields] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);
    const [sectionIndex, setSectionIndex] = useState(0);
    const [disableNextButton, setDisableNextButton] = useState(false);

    useEffect(() => {
        setInputFields(sections);
    }, [sections]);

    const handleFormChange = (formData, sIndex) => {
        let data = JSON.parse(JSON.stringify(inputFields));
        data[sIndex].questions = formData;
        setInputFields(data);
        onChange && onChange(data);
    };

    const handleDisableNextButton = (value) => {
        setDisableNextButton(value);
    };

    const handleFormSubmit = (e, sIndex, action) => {
        e.preventDefault();

        const isSectionIndexValid = typeof sIndex !== 'undefined';
        if(isSectionIndexValid && action && action === 'prev') {
            setSectionIndex((c) => c - 1);
            return;
        }

        let isValid = true;
        let emergency = false;
        const fullData = clone(inputFields);
        const data = isSectionIndexValid ? [fullData[sIndex]] : fullData;
       
        data.forEach((section) => {
            const questions = section.questions;
            questions.forEach((field, index) => {
                if(field.type === CRMFormTypes.SIGNATURE) {
                    isValid = false;
                    showError(`Following types are (${CRMFormTypes.SIGNATURE}) not supported by the form.`);
                    return;
                }

                if(field.answers && Array.isArray(field.answers) && field.required) {
                    const valid = field.answers.find((res) => res.selected);
                    if(!valid) {
                        questions[index] = { ...field, invalid: true };
                        isValid = false;
                        return;
                    }

                    if(field.name.replace(/\s/ig, '').toLowerCase().includes(EMERGENCY_STRING)) {
                        emergency = valid.value.toLowerCase() === 'yes';
                    }

                    questions[index] = { ...field, invalid: undefined };
                    return;
                }

                if((!field.answer && field.required)) {
                    isValid = false;
                    questions[index] = { ...field, invalid: true };
                    return;
                }

                isValid = !!isValid;
                questions[index] = { ...field, invalid: undefined };
            });
        });

        setInputFields(fullData);
        setIsInvalid(!isValid);

        if(!isValid) {
            return;
        }
        
        var section = '';
        if (data && data.length > 0) {
            data.forEach(d => {
                if (d.questions && d.questions.length > 0) {
                    d.questions.forEach(q => 
                    { section += q.name;
                        section += '';
                    }
                    );  
                }}
            );
        }

        sendLinkClick('Next Button', 'Next', window.location.pathname, window.location.pathname, section ?? '');        

        if(emergency) {
            showEmergencyAlert();
            return;
        }

        if(onDraftSubmit && isSectionIndexValid && action && action === 'draft') {
            onDraftSubmit(e, fullData);
            return;
        }

        if(isSectionIndexValid && action && action === 'next') {
            setSectionIndex((c) => c + 1);
            return;
        }

        onSubmit(e, fullData);
    };

    const formatSectionName = (name) => {
        if(name && name.toLowerCase().includes('[empty]')) {
            return '';
        }

        return name;
    };

    return (<Box sx={{ display: { md: 'flex', xl: 'flex' }, justifyContent: 'center', mt: 3 }}>
        {
            inputFields.length && <form onSubmit={handleFormSubmit} style={{ maxWidth: '960px', justifyContent: 'center' }}>
                <Typography variant="h4" style={{ color: 'white', fontWeight: 'bolder' }}>{formatSectionName(inputFields[sectionIndex]?.name)}</Typography>
                <Box sx={{ minHeight: '55vh', p: 2 }} className='flex-center-vh-col'>
                    <UPFormSection slot={slot} conditions={conditions} questions={inputFields[sectionIndex].questions} onChange={(formData, sectionIndex) => handleFormChange(formData, sectionIndex)} sectionIndex={sectionIndex} sliderMapping={sliderMapping} onDisableNextButton={(value) => handleDisableNextButton(value)} />
                    <FormControl component="fieldset" variant="standard" error={isInvalid}>
                        <UPFormHelperText makeVisible={isInvalid} style={{ textAlign: 'center' }}>Please make sure all fields are filled in correctly.</UPFormHelperText>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard" error={!!error} style={{ display: 'block' }}>
                        <UPFormHelperText makeVisible={!!error} style={{ textAlign: 'center' }}>{error}</UPFormHelperText>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard" style={{ display: 'block' }}>
                        <UPFormHelperText makeVisible={!!success} style={{ textAlign: 'center', color: '#ffffff' }}>{success}</UPFormHelperText>
                    </FormControl>
                </Box>
                <Box sx={{ p: 2 }} className="flex-center-vh">
                    {
                        <>
                            {onDraftSubmit && 
                    <StyledButton roundedInverse  style={{ marginRight: '16px' }} type="button" onClick={(e) => handleFormSubmit(e, sectionIndex, 'draft')}>SAVE AS DRAFT</StyledButton>
                            }
                            {sectionIndex > 0 && <StyledButton roundedInverse style={{ marginRight: '16px' }} type="button" onClick={(e) => handleFormSubmit(e, sectionIndex, 'prev')}>BACK</StyledButton>}
                            {(sectionIndex < inputFields.length - 1) && !disableNextButton && <StyledButton rounded type="button" onClick={(e) => handleFormSubmit(e, sectionIndex, 'next')}>NEXT</StyledButton>}
                            {sectionIndex === inputFields.length - 1 && <ComponentButtons
                                buttonOnly
                                previousPage={0}
                                singleButton={true}
                                isSubmitting={isSubmitting}
                                submitText={submitButtonText}
                                disableArrows={true}
                                rounded
                            />}
                        </>
                    }
                </Box>
                <Box sx={{ minHeight: 80, height: 80 }} >
                 
                </Box>
            </form >
        }
    </Box >);
};

UPForm.propTypes = {};

export default UPForm;
