import React, { useState, useEffect, useRef } from 'react';

import MyDNATextInput from '../../Shared/MyDNATextInput';
import { makeStyles } from '@material-ui/core';
import { commitCustomerDetails } from '../../HttpHelpers/CustomerServiceHttpHelper';
import {
    paymentDetailsCaptured,
    paymentDetailsCaptureFailed,
    paymentResponseCaptured,
} from '../../Actions/CustomerDetailsActions';
import { push } from 'connected-react-router';
import { Formik, Form } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { initialValues } from './PaymentService';
import ComponentButtons from '../../Shared/ComponentButtons';
import { sendRequiredRiskAssessment } from '../../HttpHelpers/EventsHttpHelper';
import { getRiskAssessmentFormByName } from '../../HttpHelpers/CRMFormsHttpHelper';
import PaymentSummary from './PaymentSummary';
import { Typography } from '@material-ui/core';
import SelectComponent from '../../Shared/SelectComponent';
import { QueryParameterNames } from '../../Shared/Constants';
import StyledButton from '../../components/StyledButton';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'inline-block',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row-reverse',
        },
    },
    leftPart: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '384px',
        [theme.breakpoints.up('md')]: {
            marginRight: '97px',
        },
    },
    rightPart: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            marginLeft: '126px',
        },
    },
    verticalDivider: {
        height: '0px',
        width: '0px',
        backgroundColor: '#000',
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#3F3F3F',
            height: '339px',
            width: '5px',
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '5%',
    },
    sectionContainer: {
        marginBottom: '60px',
        textAlign: 'left',
    },
}));

const CheckoutForm = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errorToDisplay, setErrorToDisplay] = useState(null);
    const [programName, setProgramName] = useState(null);
    const [flow, setFlow] = useState(null);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [pregnancyDueDate, setPregnancyDueDate] = useState();
    const [phiChoices, setPhiChoices] = useState(null);
    const [insurerChoices, setInsurerChoices] = useState(null);
    const [phiNameVisibility, setPhiNameVisibility] = useState(true);
    const [
        insuranceMembershipNumberVisibility,
        setInsuranceMembershipNumberVisibility,
    ] = useState(true);

    const classes = useStyles();
    const formikRef = useRef();

    useEffect(() => {
        const searchQuery = props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const programName = urlParams.get(QueryParameterNames.P) ?? '';
        const flow = urlParams.get(QueryParameterNames.F) ?? '';

        setProgramName(programName);
        setFlow(flow);
        if (!props?.subscription) {
            dispatch(
                push(
                    props.previousPage +
						(programName?.length > 0 ? `?p=${programName}` : '')
                )
            );
        }
        const phiChoices = [{ value: 'AIA', displayText: 'AIA' }];
        setPhiChoices(phiChoices);
        const insurers = [
            {
                value: 'Private Health Insurance',
                displayText: 'Private Health Insurance',
            },
        ];
        setInsurerChoices(insurers);
        formikRef.current.values.insurer = 'Private Health Insurance';
        formikRef.current.values.insurerName = 'AIA';
    }, []);

    async function createSubscription(subscriptionCode, paymentData) {
        const paymentDetails = {
            paymentMethodId: subscriptionCode,
            ...paymentData,
        };

        const customerDetails = {
            ...props,
            pregnancyDueDate,
            payment: paymentDetails,
            insuranceMembershipNumber: paymentData.insuranceMembershipNumber,
            insurer: paymentData.insurer,
            insurerName: paymentData.insurerName,
        };

        dispatch(paymentDetailsCaptured(paymentDetails));
        commitCustomerDetails(customerDetails)
            .then(async (response) => {
                if (!response.ok) {
                    response.json().then((data) => {
                        dispatch(paymentDetailsCaptureFailed(data));
                        setLoading(false);
                        setErrorToDisplay(data);
                    });
                } else {
                    if (programName?.length > 0) {
                        const token =
							props.auth?.user?.signInUserSession?.accessToken?.jwtToken;
                        const riskAssessmentFormResult =
							await getRiskAssessmentFormByName(token);
                        if (!riskAssessmentFormResult.ok)
                            await sendRequiredRiskAssessment(token);
                    }
                    const responseBody = await response.json();
                    dispatch(paymentResponseCaptured(responseBody));
                    dispatch(
                        push(`${props.nextPage}?p=${programName}&f=${flow}`)
                    );
                }
            })
            .catch((err) => {
                dispatch(paymentDetailsCaptureFailed(err.message));
                setLoading(false);
                setErrorToDisplay(
                    'We are unable to submit your order. Please try again, if persist contact support.'
                );
            });
    }
    const handleSubmit = async (paymentData) => {
        setLoading(true);

        sendLinkClick(
            'Confirm Pay Button',
            'Button',
			`${props.nextPage}`,
			window.location.pathname,
			''
        );

        const subscriptionCode = null; //no payment required
        await createSubscription(subscriptionCode, paymentData);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const goPrevStep = () => {
        props.dispatch(
            push(
				`${props.previousPage}${
					programName ? '?p=' + programName : ''
				}&f=${flow}`
            )
        );
    };
    const onChangeInsurerHandler = (value, setFieldValue) => {
        if (value) {
            var isPhi = value == 'Private Health Insurance';
            setPhiNameVisibility(isPhi);
            setInsuranceMembershipNumberVisibility(true);
            setFieldValue('insurerNameIsVisible', isPhi);
            setFieldValue('insuranceMembershipNumberIsVisible', true);
        } else {
            setPhiNameVisibility(false);
            setInsuranceMembershipNumberVisibility(false);
            setFieldValue('insuranceMembershipNumber', '');
            setFieldValue('insurerName', '');

            setFieldValue('insuranceMembershipNumberIsVisible', false);
            setFieldValue('insurerNameIsVisible', false);
        }
    };
    return (
        <div id="payment-form" className={classes.root}>
            <Formik
                innerRef={formikRef}
                enableReinitialize={false}
                validateOnChange={true}
                initialValues={{
                    ...initialValues,
                    firstName: props.auth?.user?.firstName,
                    lastName: props.auth?.user?.lastName,
                    country: 'Australia',
                    price: props.subscription.initialChargeAmount
                        ? props.subscription.initialChargeAmount
                        : props.subscription.InstalmentAmount,
                    coupon: props.couponDetails?.promoCode,
                    discountValue: 0,
                    insurerName: '',
                    insurerNameIsVisible: true,
                    insuranceMembershipNumber: '',
                    insuranceMembershipNumberIsVisible: true,
                    insurer: '',
                }}
                onSubmit={(data, { setSubmitting, setFieldError }) => {
                    if (!data.insuranceMembershipNumber) {
                        setFieldError(
                            'insuranceMembershipNumber',
                            'Insurance Membership Number is required'
                        );
                        setErrorToDisplay('');
                        return false;
                    }
                    //if (termsAndConditions) {
                    setSubmitting(true);
                    handleSubmit(data);
                    setSubmitting(false);
                    // } else {
                    //     setErrorToDisplay('Please accept the terms and conditions.');
                    // }
                }}
            >
                {({ errors, values, setFieldValue, submitForm }) => (
                    <Form>
                        <form id="payment-form">
                            <div className={classes.formContainer}>
                                <div className={classes.rightPart}>
                                    <PaymentSummary
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        dispatch={dispatch}
                                    />
                                </div>
                                <div className={classes.verticalDivider} />
                                <div className={classes.leftPart}>
                                    {
                                        <div
                                            className={classes.sectionContainer}
                                        >
                                            <Typography variant="h2">
												AIA Membership Details
                                            </Typography>
                                            <Typography variant="h4">
												Please fill out your AIA
												membership number below so that
												you can receive complimentary
												access to Universal Practice.
                                            </Typography>
                                            <SelectComponent
                                                name="insurer"
                                                label="Insurer"
                                                setFieldValue={setFieldValue}
                                                value={values.insurer}
                                                validationErrorText={errors['insurer']?.errorText}
                                                choices={insurerChoices}
                                                // onChange={(event)=> {
                                                //     onChangeInsurerHandler(event.target.value, setFieldValue);
                                                // }}
                                            />
                                            {phiNameVisibility && (
                                                <SelectComponent
                                                    name="insurerName"
                                                    label="Private Health Insurance Name"
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    value={values.insurerName}
                                                    validationErrorText={errors['insurerName']?.errorText}
                                                    choices={phiChoices}
                                                    helperStyle={{
                                                        color: '#FF0000',
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            )}
                                            {insuranceMembershipNumberVisibility && (
                                                <MyDNATextInput
                                                    name="insuranceMembershipNumber"
                                                    label="Membership Number"
                                                    maxLength="20"
                                                    setFieldValue={
                                                        setFieldValue
                                                    }
                                                    validationErrorText={errors['insuranceMembershipNumber']?.errorText}
                                                    value={values.insuranceMembershipNumber}
                                                    helperStyle={{
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                            {/* <CheckboxInput
                                                name={'termsAndConditions'}
                                                value={values.termsAndConditions}
                                                onChange={(event)=>{
                                                    setTermsAndConditions(event.target.checked);
                                                }}
                                            />
                                            <span className={'terms-conditions-font'}>I agree to the{' '}
                                                <a
                                                    href={TermsConditionsLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={'TermsAndConditionsLink'}
                                                >Terms & Conditions
                                                </a>
                                            </span> */}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="inline-buttons">
                                <StyledButton
                                    roundedInverse
                                    style={{ marginRight: '16px' }}
                                    type="button"
                                    onClick={(event) => goPrevStep(event)}
                                >
									BACK
                                </StyledButton>
                                <ComponentButtons
                                    buttonOnly
                                    previousPage={0}
                                    singleButton={true}
                                    isSubmitting={loading}
                                    submitText="Confirm"
                                    disableArrows={true}
                                    onClick={submitForm}
                                    rounded
                                />
                            </div>
                            <div className="error inline-buttons">
                                {errorToDisplay}
                            </div>
                        </form>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const PaymentForm = (props) => <CheckoutForm {...props} />;

function mapStateToProps(state) {
    return {
        ...state.customerDetails,
        subscription: state.customerDetails.subscription,
        couponDetails: state.customerDetails.couponDetails,
        router: state.router,
    };
}

export default connect(mapStateToProps)(PaymentForm);
