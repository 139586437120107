import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {NotificationSeverity, SnackbarAutoHideDuration} from './Constants';

let openSnackbarHandler;

class Notifier extends React.Component {
    state = {
        open: false,
        message: '',
    };

    componentDidMount() {
        openSnackbarHandler = this.openSnackbar;
    }

    handleSnackbarClose = () => {
        this.setState({
            open: false
        });
    };

    openSnackbar = (message, severity) => {
        this.setState({open: true, message, severity});
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={SnackbarAutoHideDuration}
                open={this.state.open}
                onClose={this.handleSnackbarClose}>
                <Alert onClose={this.handleSnackbarClose} severity={this.state.severity}>{this.state.message}</Alert>
            </Snackbar>
        );
    }
}

function openSnackbar(message, severity) {
    openSnackbarHandler(message, severity);
}

export default Notifier;

export function notify(message, severity) {
    if (Object.values(NotificationSeverity).includes(severity)) {
        openSnackbar(message, severity);
    }
}

