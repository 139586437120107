import React from 'react';
import { Auth } from 'aws-amplify';
import { SignIn, } from 'aws-amplify-react';
import { Box, Link, Typography } from '@material-ui/core';
import { RegisterActions, signedIn, signInFailure, confirmSignUp, confirmSignUpFailure, redirectAfterLogin } from '../Actions/RegisterActions';
import { notify } from '../Shared/Notifier';
import { Redirect } from 'react-router';
import { messages } from '../Shared/Messages';
import { AUTH_ACCESS_METHOD, SessionStorageVariables, QueryParameterNames, ErrorMessages, SubscriptionGroups, VpaProductId } from '../Shared/Constants';
import ComponentButtons from '../Shared/ComponentButtons';
import StyledTextField from '../components/StyledTextField';
import { autoLogin, setAuthAccessMethod } from '../HttpHelpers/AutoLoginHttpHelper';
import { getUser, savePatient, getSubscriptionDetails as getActiveSubscriptions, getPatient } from '../HttpHelpers/UserServiceHttpHelper';
import { Loading } from '../components/SplashScreen/Loading';
import { cognitoAutoLogin } from '../utils/auth';
import { clone } from '../utils';
import { push } from 'connected-react-router';
import { registrationWebSignedIn } from '../HttpHelpers/EventsHttpHelper';
import { appointmentCaptured, briefAssessmentCaptured, subscriptionCaptured } from '../Actions/CustomerDetailsActions';
import { callUpdateRegistrationDetails, commitCustomerDetails, getSubscriptionDetails } from '../HttpHelpers/CustomerServiceHttpHelper';
import { saveBriefAssessment } from '../HttpHelpers/CRMFormsHttpHelper';
import { validateEmailHost } from './RegistrationService';
import StyledButton from '../components/StyledButton';
import { connect } from 'react-redux';
import { joinProgramWithSubscription } from '../CustomerDetails/Subscription/SubscriptionServices';
import { AppStateContext } from '../Shared/UpAppStateProvider';
import { EmailRegex } from '../Shared/RegexLibrary';

class MyDnaSignIn extends SignIn {
    errorMessage = 'The link provided is expired or invalid';
    static contextType = AppStateContext;
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            programName: '',
            loading: true,
            isAutoLogin: false,
            flow: null,
            emailError: false,
            emailHelperText: null,
            recommended: ''
        };

        this._validAuthStates = [RegisterActions.SIGN_IN, RegisterActions.SIGNED_OUT, RegisterActions.SIGNED_UP];
    }

    async componentDidMount() {
        const searchQuery = this.props?.router?.location?.search;
        if (searchQuery)
        {
            const urlParams = new URLSearchParams(searchQuery);
            const programName = urlParams ? urlParams.get(QueryParameterNames.P) : '';
            const autoLoginToken = urlParams ? urlParams.get(QueryParameterNames.T) : '';
            const flow = urlParams ? urlParams.get(QueryParameterNames.F) : '';
            const recommended = urlParams ? urlParams.get(QueryParameterNames.R) : '';
            
            this.setState({ programName, flow, recommended });
            if(autoLoginToken) {
                this.setState({ isAutoLogin: true });
                //start new session 
                sessionStorage.clear();
                setAuthAccessMethod();
                await this.loginWithToken(autoLoginToken);
            }
        }  
              
        this.setState({ loading: false });
    }

    triggerAuthEvent(event) {
        notify(event.data, event.type);
    }

    async getCurrentUser(user, errorWhenNotFound = true) {
        return getUser(user.signInUserSession.accessToken.jwtToken)
            .then(res => {
                if(res.status === 200) {
                    return res.json();
                } else if(res.status === 204) {
                    // no content == user not found
                    if (errorWhenNotFound)
                        throw 'User not found. Please register using the link below';
                }
            })
            .then((json) => {
                if (json)
                {
                    user = { ...user, 
                        patientId: json.result.crmPatientId, 
                        firstName: json.result.firstName, 
                        lastName: json.result.lastName, 
                        username: json.result.userName };
                    return user;
                }
                else
                    return null;
            });
    }

    async loginWithToken(guid) {
        try {
            const res = await autoLogin(guid);
            const cognitoData = {
                idToken: '',
                refreshToken: '',
                accessToken: '',
            };

            if(!res?.data?.length) {
                this.setState({ loading: false, errorMessage: messages.userNotConfirmedException });
                return;
            }

            for(const { name, value } of res.data) {
                const key = name.split('.').pop();
                if(Object.keys(cognitoData).includes(key)) {
                    cognitoData[key] = value;
                }
                sessionStorage.setItem(name, value);
            }

            cognitoAutoLogin(cognitoData);
            
            const auth = await Auth.currentAuthenticatedUser();
            if(!auth) {
                this.setState({ loading: false, errorMessage: messages.userNotConfirmedException });
                return;
            } 
            const data = clone(auth);
            let user = await this.getCurrentUser(data);
            if(!user.patientId) {
                user.acceptedPrivacyPolicy = true; //existing user has accepted previously
                user = await this.registerPatient(user);
            }
            if(this.state.flow === AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING)
            {
                //auto select subscription to VPA
                if (this.state.programName.toLocaleLowerCase() === SubscriptionGroups.VPA) {
                    getSubscriptionDetails(this.state.programName)
                        .then((response) => {
                            response.json()
                                .then((data) => {
                                    const vpaProduct = data.find(d => d.id === VpaProductId.VPA1);
                                    this.props.dispatch(subscriptionCaptured(vpaProduct));
                                    this.props.dispatch(signedIn(user));
                                    this.props.dispatch(redirectAfterLogin(this.props));
                                });
                        });
                }
                //others than VPA is not supported, Follow up booking is for VPA only
            }
            if(this.state.flow === AUTH_ACCESS_METHOD.PHYSIO_RECOMMENDATION)
            {
                let getSubscriptionResponse = await getSubscriptionDetails('All'); //get all products
                if (getSubscriptionResponse && getSubscriptionResponse.ok) 
                {
                    let subscriptionData = await getSubscriptionResponse.json();
                    const recommended = subscriptionData.find(d => d.id === this.state.recommended);
                    const programSubscription = joinProgramWithSubscription(subscriptionData, {
                        recommendedPlan : recommended.id
                    }, this.state.programName);
                    this.context.setSubscriptions(programSubscription);
                    this.props.dispatch(subscriptionCaptured(recommended));
                    this.setUserExistingSubscription(user);
                }
                         
                //others than VPA is not supported, Follow up booking is for VPA only
            }
            else await this.setUserExistingSubscription(user);
        } catch(e) {
            console.log(e);
            this.setState({ loading: false, errorMessage: messages.userNotConfirmedException });
        }
    }

    logInUser(event) {
        event.preventDefault();
        const email = this.inputs.email?.trim();
        const password = this.inputs.password?.trim();
        
        var isEmailValid = email && EmailRegex.test(email);
        if (!isEmailValid)
        {
            this.setState({
                errorMessage: 'Invalid email address'
            });
            return;
        }

        Auth.signIn(email, password)
            .then((data) => {
                Auth.currentAuthenticatedUser().then(async (user) => {
                   
                    const token = user.signInUserSession.accessToken.jwtToken;
                    await registrationWebSignedIn(token, email);

                    if(!this.state.programName) {
                        const registeredUser = await this.getCurrentUser(user, false);
                        let patient;
                        if (registeredUser)
                        {
                            if (!registeredUser.patientId) //old UP users don't have patient account
                                user = await this.registerPatient(registeredUser);
                            
                            patient = await getPatient(user);
                        }
                        else
                        {
                            //partly registered user (not in our db)
                            //check if the user is registered in crm
                            let patient = await getPatient(user);
                            if (!patient)
                            {
                                this.props.dispatch(signInFailure(messages.IncompleteAccountCreationMessage));    
                                super.changeState(RegisterActions.SIGN_IN); 
                                return;
                            }
                        }

                              
                        //enrich user details, so on submission it will be carried
                        user.patientId = patient.id;
                        user.firstName = patient.firstName;
                        user.lastName = patient.lastName;
                        user.subscription = this.state.subscription;
                        user.dob = patient.dob;
                        user.acceptedPrivacyPolicy = patient.acceptedPrivacyPolicy;
                        user.contact = patient.mobile;

                        this.props.dispatch(signedIn(user));
                        this.props.dispatch(redirectAfterLogin(this.props));
                    } else {                        
                        user = await this.getCurrentUser(user, false);
                   
                        if (!user) //user not found in db
                        {
                            this.setState({ loading: false, errorMessage: messages.IncompleteAccountCreationMessage });
                            this.props.dispatch(signInFailure(messages.IncompleteAccountCreationMessage));    
                            super.changeState(RegisterActions.SIGN_IN); 
                        }
                        else {
                            this.setState({ loading: true });
                            if(!user.patientId) {
                                user.acceptedPrivacyPolicy = true; //existing user has accepted previously
                                user = await this.registerPatient(user);
                            }
                            await this.setUserExistingSubscription(user);
                            const selectedSubscription = this.props.customerDetails.subscription;
                            const healthReport = this.props.customerDetails.healthReport;
                            //ensure this object is sync to updatecustomerdetails method
                            const registrationDetails = {
                                emailAddress: user.username,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                subscriptionCode: selectedSubscription.id,
                                crmPatientId: user.patientId,
                                recurringPaymentPriceId: selectedSubscription.priceId,
                                timeZone: this.props.customerDetails.userDetails.timeZone,
                                healthReport: healthReport ? JSON.stringify(healthReport) : null,
                                emailReport: false,
                                promoCode: this.props.customerDetails?.couponDetails?.promoCode ?? null,
                            };

                            const appointmentData = this.props.customerDetails.appointment;
                            user.appointmentData = appointmentData;
                            if (appointmentData) {
                                appointmentData.patientId = user.patientId;
                                this.props.dispatch(appointmentCaptured((appointmentData)));
                            }
                            
                            const briefAssessmentForm = JSON.parse(sessionStorage.getItem(SessionStorageVariables.BriefAssessmentForm));
                            if (briefAssessmentForm)
                            {
                                try {
                                    const res = await saveBriefAssessment(briefAssessmentForm, user?.signInUserSession?.accessToken?.jwtToken);
                                    const saveForm = await res.json();
                                    this.props.dispatch(briefAssessmentCaptured(briefAssessmentForm));                  
                                } catch(err) {                                    
                                    console.log(err);
                                    throw Error('Failed to save form');
                                }
                            }
            
                            // Update registration details
                            await callUpdateRegistrationDetails(registrationDetails)
                                .then((result) => result.json())
                                .then((json) => {
                                    if(json.message === 'Updated') {
                                                                          
                                        if (this.state.flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION || 
                                            this.state.flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION)
                                        {                                            
                                            const customerDetails = {
                                                ... this.props.customerDetails,
                                                auth: {
                                                    user: user,
                                                    isAuthenticated: true,
                                                }
                                            };
                                            commitCustomerDetails(customerDetails)
                                                .then(async (response) => {
                                                    if(!response.ok) {
                                                        response.json()
                                                            .then((data) => {
                                                                console.log(data);
                                                                throw Error(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                                            });
                                                    } else {
                                                        this.props.dispatch(signedIn(user));
                                                        this.props.dispatch(subscriptionCaptured(selectedSubscription));
                                                        this.props.dispatch(redirectAfterLogin(this.props));
                                                        super.changeState(RegisterActions.SIGNED_IN);                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    throw Error(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                                });
                                        }
                                        else
                                        {
                                            this.props.dispatch(signedIn(user));
                                            this.props.dispatch(subscriptionCaptured(selectedSubscription));
                                            this.props.dispatch(redirectAfterLogin(this.props));
                                            super.changeState(RegisterActions.SIGNED_IN);
                                        }
                                        
                                    } else {
                                        throw Error(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                    }
                                    this.setState({ loading: false });
                                }).catch(() => {
                                    throw Error(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                });
                        }
                    }
                }).catch(e => {
                    if(e.type === 'UserNotConfirmedException') {
                        this.setState({
                            errorMessage: messages.userNotConfirmedException
                        });
                        this.props.dispatch(signInFailure(messages.userNotConfirmedException));
                    }
                    if  (e.message && e.message === messages.UnableToCreatePatient)
                        this.setState({
                            errorMessage: e.message
                        });
                    else
                        this.setState({
                            errorMessage: messages.loginFailed
                        });
                    this.props.dispatch(signInFailure(messages.loginFailed));
                    super.changeState(RegisterActions.SIGN_IN); 
                });
            })
            .catch((err) => {
                console.log(err.message);
                if(err.code === 'NotAuthorizedException') {
                    this.setState({
                        errorMessage: err.message
                    });
                } else if(err.code === 'UserNotConfirmedException') {
                    this.setState({
                        errorMessage: messages.userNotConfirmedException
                    });
                    this.props.dispatch(signInFailure(err.message));
                    Auth.resendSignUp(email)
                        .then(() => {
                            this.setState({
                                errorMessage: null
                            });
                            super.changeState(RegisterActions.CONFIRM_SIGN_UP, { username: email, password: password });
                            this.props.dispatch(confirmSignUp(messages.codeResent));
                        })
                        .catch(err => {
                            this.setState({
                                errorMessage: err.message
                            });
                            this.props.dispatch(confirmSignUpFailure(err.message));
                        });
                } else {
                    this.setState({
                        errorMessage: err.message
                    });
                    this.props.dispatch(signInFailure(err.message));
                }
            });
        this.setState({ loading: false });
    }

    async setUserExistingSubscription(user) {
        try {
            const result = await getActiveSubscriptions(user.signInUserSession.accessToken.jwtToken);
            if(result.status === 200) {
                const json = await result.json();
                if(json.result.subscriptions?.length > 0) {
                    user.subscriptions = json.result.subscriptions;
                    const foundProgramSubscription = json.result.subscriptions.find(x => x.subscriptionId.indexOf(`_${this.state.programName}_` ) >= 0);
                    if(foundProgramSubscription) {
                        user.activeSubscription = foundProgramSubscription;
                    } 
                }
                this.props.dispatch(signedIn(user));
                this.props.dispatch(redirectAfterLogin(this.props));
            }
            else
            {
                //existing user with no active subscription
                this.props.dispatch(signedIn(user));
                this.props.dispatch(redirectAfterLogin(this.props));
            }
        } catch(e) {
            console.log(e);
            this.setState({ loading: false, errorMessage: messages.userNotConfirmedException });
            this.props.dispatch(signInFailure(this.state.errorMessage));
        }
    }


    registerPatient(user) {
        return savePatient(user)
            .then((result) => 
            { 
                if (result.status && (result.status === 200 || result.status === 201 || result.status === 204)) {
                    return result.json(); 
                }
                throw Error(messages.UnableToCreatePatient);
            }
            )
            .then((patient) => {
                if (patient)
                {
                    user.patientId = patient.result;
                    return user;
                }
            });
    }

    forgotPassword() {
        this.setState({
            errorMessage: null
        });
        super.changeState(RegisterActions.FORGOT_PASSWORD);
    }
    
    goPrevStep() {
        this.props.dispatch(push(`${this.props.previousPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }

    handleEmailChanged(event)
    {
        if (this.state.flow && this.state.flow == AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION)
        {            
            const org = this.props.customerDetails.organisation;
            if (!validateEmailHost(event.target.value, org))
            {
                this.setState({ emailError: true, emailHelperText: `Invalid email. Please use ${org.name}'s email.`});
                return false;
            }
        }
        this.setState({ emailError: false, emailHelperText: null});
        this.handleInputChange(event);
    }

    showComponent(theme) {
        if(!this.props?.auth?.isAuthenticated && this.state.loading) {
            return <Loading />;
        }
        if(this.state.isAutoLogin) {
            return <Loading />;
        }
        return (this.props.auth.isAuthenticated && !this.state.programName ?
            <Redirect to="/subscription" /> :
            <div>
                <form onSubmit={(event) => this.logInUser(event)}>
                    <Typography variant="body2" >Login to your account to continue</Typography>
                    <div className='content content-width'>
                        <div>
                            <StyledTextField
                                id="email"
                                key="email"
                                name="email"
                                onChange={(event) => this.handleEmailChanged(event)}
                                type="text"
                                required={true}
                                title="Email Address"
                                length={254}
                                error={this.state.emailError}
                                helperText={this.state.emailHelperText}
                                helperStyle={{
                                    width: '100%',
                                }}
                            />
                        </div>
                        <div>
                            <StyledTextField
                                id="password"
                                key="password"
                                name="password"
                                onChange={this.handleInputChange}
                                type="password"
                                title='Password'
                                required={true}
                                length={25}
                            />
                            <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        </div>
                        {this.state.errorMessage && <div>
                            <p className="error">{this.state.errorMessage}</p>
                        </div>}
                        <div>
                            <Link
                                onClick={(event) =>
                                    this.forgotPassword(event)}>
                                <p style={{
                                    textDecorationLine: 'underline',
                                    textAlign: 'left'
                                }}>Forgot password</p>
                            </Link>
                        </div>
                        {(!this.state.programName) ?
                            <>                              
                                <Box className='flex-center-vh' sx={{ mt: '5rem' }}>
                                    <ComponentButtons
                                        rounded
                                        buttonOnly
                                        isSubmitting={false}
                                        dispatch={this.props.dispatch}
                                        previousPage={this.props.previousPage}
                                        singleButton={true}
                                        submitText={'Login'}
                                        backText={'Start a new registration'}
                                        disableArrows={true}
                                        backOnClick={() => super.changeState(RegisterActions.SIGN_UP)} />
                                </Box>
                            </>
                            :
                            <>
                                <div className='flex-center-vh' style={{ marginTop: '2rem' }}>
                                    <StyledButton roundedInverse style={{ marginRight: '16px' }} type="button" onClick={(event) => this.goPrevStep(event)}>BACK</StyledButton>
                                    <ComponentButtons
                                        buttonOnly
                                        isSubmitting={false}
                                        dispatch={this.props.dispatch}
                                        singleButton={true}
                                        submitText={'Login'}
                                        rounded
                                    />
                                </div>
                            </>                           
                        }
                    </div>
                </form>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(MyDnaSignIn);
