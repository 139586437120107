import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import StyledButton from './StyledButton';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    bannerContainer: {
        maxWidth: '460px',
        height: '62vh',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    banner: {
        fontFamily: 'Moderat',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '150%',
        textAlign: 'center',
        letterSpacing: '0.03em',
        color: '#FFFFFF',
    }
}));

const Banner = ({ onClose, children, buttonText, makeVisible }) => {
    const classes = useStyles();

    if(!makeVisible) {
        return null;
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.bannerContainer}>
                <Box className={classes.banner}>{children}</Box>
            </Box>
            <StyledButton type="button" onClick={(e) => onClose(e)} rounded>{buttonText || 'close'}</StyledButton>
        </Box>
    );
};

export default Banner;