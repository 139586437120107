import React, { Component } from 'react';
import ComponentContainerHeader from '../Shared/ComponentContainerHeader';
import { PrimaryTitles } from '../Shared/TitleTexts';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { selectApplinkURL } from '../Reducers/CustomerDetailsReducer';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { AUTH_ACCESS_METHOD, QueryParameterNames, SessionStorageVariables, SubscriptionGroups } from '../Shared/Constants';
import ImageMask from '../Shared/ImageMask';
import { signOut } from '../Actions/RegisterActions';
import { saveToken } from '../HttpHelpers/TokenServiceHttpHelper';
import { TokenPurposes } from '../Shared/Constants';
import { MY_ACCOUNT_URL } from '../Shared/Configs';
import StyledButton from '../components/StyledButton';

class Confirmation extends Component {
    constructor(props) {
        super(props);

        const searchQuery = this.props.router.location.search;
        const programName = new URLSearchParams(searchQuery).get(QueryParameterNames.P) ?? '';
        
        let stored = sessionStorage.getItem(SessionStorageVariables.AccessStatus);
        stored = stored && JSON.parse(stored);

        let patientMakePayment = true;
        let showWelcomeInfo = true;
        if(stored?.access === AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING) 
        {
            patientMakePayment = programName == SubscriptionGroups.VPA;
            showWelcomeInfo = false;
        }
        const appointment = this.props.customerDetails.appointment;
        let appointmentTime = null;
        if (appointment)
            appointmentTime = moment.utc(appointment.startTime).clone().tz(appointment.tz);

        this.state = {
            AppLinkURL: selectApplinkURL(props.barcode?.partner),
            programName: programName,
            subscription: this.props.customerDetails.subscription,
            programDurationInWeek: Math.floor(this.props.customerDetails.subscription.subscriptionInterval / 7),
            paymentResult: this.props.customerDetails.paymentResult,
            paymentDetails: this.props.customerDetails.payment, 
            showProductPrice: patientMakePayment,
            showWelcomeInfo: showWelcomeInfo,
            showPaymentInfo: patientMakePayment,
            image: appointment?.practitionerPhotoUrl ? appointment?.practitionerPhotoUrl : '',
            startTime: appointmentTime?.format('kk:mm'),
            endTime: appointmentTime?.add(30, 'minutes').format('kk:mm'),
            appointmentDate: appointmentTime?.format('dddd, Do MMM YYYY'),
            practitionerName: appointment?.practitionerName
        };

        this.AnimationContainer = React.createRef();
    }
    componentDidMount() {
        
        // Auth.signOut();
        // if (this.state.programName) {
        //     this.props.dispatch(signOut());    
        // }       

        const url = document.URL;
        window.history.pushState(null, null, url);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, url);
        });
        
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', () => { });
    }

    goNextStep() {
        this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}`));
    }

    getSubscriptionInformation()
    {
        if (this.state.subscription.group === SubscriptionGroups.VPA)
            return `Includes ${this.props.customerDetails.subscription.appointmentAllowance}x Online Physiotherapy ${this.props.customerDetails.subscription.appointmentAllowance > 1 ? 'Appointments' : 'Appointment'}.`;
        else if (this.state.subscription.group === SubscriptionGroups.MBS)
            return `Includes ${this.props.customerDetails.subscription.subscriptionInterval} ${this.props.customerDetails.subscription.subscriptionIntervalUnit} Membership`;

        else
        {
            var subscriptionInfo = `${this.state.programDurationInWeek} ${this.state.programDurationInWeek > 1 ? 'weeks' : 'week'} online physiotherapy program.`;
            // if (this.props.customerDetails.subscription.appointmentAllowance > 0)
            // {
            //     subscriptionInfo += ` Includes ${this.props.customerDetails.subscription.appointmentAllowance}x Online Physiotherapy ${this.props.customerDetails.subscription.appointmentAllowance > 1 ? 'Appointments' : 'Appointment'}.`;
            // }
            return subscriptionInfo;
        }
            
    }

    getTrialInformation() 
    {
        if (this.state.paymentResult.trialEnd !== null) {
            return `Trial ends: ${moment(this.state.paymentResult.trialEnd).format('DD MMM')}. First payment is on ${moment(this.state.paymentResult.trialEnd).format('DD MMM')}.`; 
        }

        return '';
    }

  

    getSubscriptionPrice() {
        if (this.state.paymentDetails) {
            const priceId = this.state.paymentDetails.priceId;
            if (priceId === this.state.subscription.priceId) {
                return `$${this.state.subscription.initialChargeAmount}`;
            } else if (priceId === this.state.subscription.instalmentPriceId) {
                if (this.state.subscription.instalmentUnit.includes('every')) {
                    return `${this.state.subscription.numberOfInstalment} x $${this.state.subscription.instalmentAmount} ${this.state.subscription.instalmentUnit}`;
                } else {
                    return `${this.state.subscription.numberOfInstalment} x $${this.state.subscription.instalmentAmount}/${this.state.subscription.instalmentUnit}`;
                }
            }
        }
    }

    getAppointmentInformation()
    {
        if (this.state.subscription.group === SubscriptionGroups.VPA)
            return 'Standard Consultation - 30 mins';
        else
            return 'Initial Consultation - 30 mins';
    }

    getFirstPaymentInformationAfterTrial()
    {
        let firstPayment = this.state.subscription.initialChargeAmount - this.state.paymentDetails.discountValue; 

        return `$${firstPayment}`;
    }

    async handleNavigateToMyAccount(evt) {
        saveToken(TokenPurposes.Login).then((response) => {
            if (response.isSuccess) {
              
                const url = `${MY_ACCOUNT_URL}/login/${response.result}`;
                window.open(url, '_self');
            }
        });
    }

    render() {
        return (
            <form onSubmit={(event) => this.goNextStep(event)}>
                <ComponentContainerHeader
                    primaryTitle={PrimaryTitles.Confirmation}
                    secondaryTitle={''}
                />
                <div className={'confirmation-container'}>

                    {this.state.programName && (
                        <>
                            <Box style={{ backgroundColor: '#000000', width: 'auto' }}>
                                
                                <Grid container spacing={2} style={{ marginBottom: '28px' }}>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ color: '#000000', display: 'flex', justifyContent: 'left' }}>
                                            {
                                                this.props.customerDetails.appointment && <ImageMask imageUrl={this.state?.image} />
                                            }
                                            <Box sx={{ alignItems: 'flex-start', display: 'flex', ml: '20px', flexDirection: 'column' }}>
                                                <Box sx={{ display: 'flex', mt: '24px' }}>
                                                    <Typography style={{ color: '#FFFFFF', fontSize: '24px', lineHeight: '100%', letterSpacing: '0.03em', textAlign: 'left' }}>  {this.state.practitionerName}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em' }}> {this.state.appointmentDate}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', marginBottom: '27px' }}>
                                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em' }}>  {this.state.startTime}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {  this.props.customerDetails.appointment && <Divider style={{ backgroundColor: '#FFFFFF' }} orientation='horizontal' variant='fullWidth' /> }
                                    </Grid>
                                </Grid>
                                <Box sx={{ backgroundColor: '#1C1C1C', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 20 }}>
                                    <Box sx={{ display: 'flex', p: 0, justifyContent: 'space-between', width: '100%', margin: 'auto' }}>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em', fontWeight: 400, textAlign: 'left' }}>
                                            {this.state.subscription.name}
                                        </Typography>
                                              
                                        {this.state.showProductPrice && (<Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em', fontWeight: '400' }}>
                                            {this.getSubscriptionPrice()}
                                        </Typography>)}
                                    </Box>
                                    {
                                        this.props.customerDetails.appointment &&
                                                <>
                                                    <Typography variant="subtitle1" color="textSecondary" component="p" style={{ color: '#FFFFFF', opacity: 0.6, fontWeight: 'bold',textAlign: 'left' }} >
                                                        {this.getAppointmentInformation()}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="textSecondary" component="p" style={{ color: '#FFFFFF', opacity: 0.6, fontWeight: 'bold', textAlign: 'left' }} >
                                                        {this.state.appointmentDate} {this.state.startTime}
                                                    </Typography>
                                                </>
                                    }
                                    <p></p>
                                    <Typography color="secondaryText" component="p" style={{ color: '#FFFFFF', opacity: 0.6, textAlign: 'start' }} >
                                        { this.getSubscriptionInformation() }
                                    </Typography>
                                    {this.state.paymentResult.trialEnd &&  <Typography color="secondaryText" component="p" style={{ color: '#FFFFFF', opacity: 0.6, textAlign: 'start' }} >
                                        { this.getTrialInformation() }
                                    </Typography>}
                                    {
                                        this.state.paymentDetails.discountValue > 0 &&
                                                <Box sx={{ display: 'flex', marginTop: '28px', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                                                    <Typography style={{ color: '#FFFFFF' }}>
                                                        {'Discount applied'}
                                                    </Typography>
                                                    <Typography style={{ color: '#FFFFFF' }}>
                                                        {`-$${this.state.paymentDetails.discountValue}`}
                                                    </Typography>
                                                </Box>
                                    }
                                    <Box component="div" sx={{ width: '100%', backgroundColor: '#FFFFFF', color: '#ffffff', height: 2, display: 'block', marginTop: 27 }}>
                                    </Box>                                          
                                </Box>
                                {this.state.showPaymentInfo && (<Box sx={{ backgroundColor: '#1C1C1C', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', padding: 20, paddingTop: 0 }}>
                                    <Typography gutterBottom variant="h5" component="h2" style={{ color: '#FFFFFF', fontWeight: 'bold', }}>
                                                Total Paid
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="h2" style={{ color: '#FFFFFF', fontWeight: 'bold', }}>
                                        {`$${this.state.paymentResult.amountPaid / 100}`}
                                    </Typography>
                                </Box>)}
                                {this.state.showPaymentInfo && this.state.paymentResult.trialEnd && this.state.paymentResult.amountPaid === 0 && <Box sx={{ backgroundColor: '#1C1C1C', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: 27, padding: 20, paddingTop: 0 }}>
                                    <Typography gutterBottom variant="h5" component="h2" style={{ color: '#FFFFFF', fontWeight: 'bold', }}>
                                                First Payment                                            </Typography>
                                    <Typography gutterBottom variant="h5" component="h2" style={{ color: '#FFFFFF', fontWeight: 'bold', }}>
                                        { this.getFirstPaymentInformationAfterTrial() }
                                                
                                    </Typography>
                                </Box>}
                            </Box>
                            <Box style={{ marginTop: 16 }}>
                                <Typography gutterBottom variant="h6" component="p">
                                Welcome to the Universal Practice Online Physiotherapy. <br/>
                                </Typography>
                                <Box>
                                    <br />
                                    <Typography gutterBottom variant="h6" component="p">
										To access your account, please click the
										button below.
                                    </Typography>
                                    <StyledButton rounded type="button" style={{ marginTop: 8 }}
                                        onClick={(e) =>
                                            this.handleNavigateToMyAccount(e)
                                        }
                                    >
										My Account
                                    </StyledButton>
                                </Box>
                                <Typography gutterBottom variant="h6" component="p">
                                    <br />
									Please download the below app to your phone.{' '}
                                    <br />
									If you are currently on a computer, please
									check your emails (make sure to check your
									junk) via your phone and you will find a
									link to download our app.
                                </Typography>
                                <a href={this.state.AppLinkURL.IOS_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/app-store-badge.png')} alt="IOS store icon" className={'store-badges'} /></a>
                                <a href={this.state.AppLinkURL.Android_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/google-play-badge.png')} alt="Play store icon" className={'store-badges'} /></a>
                            </Box>
                        </>)}
                    {!this.state.programName && (

                        <div>
                            <img
                                src={require('../Assets/registration-complete.png')}
                                alt="confirmation clip board"
                            />
                            <Typography gutterBottom variant="h6" component="p">
                                Welcome to the Universal Practice Online Physiotherapy. <br/>
                                It's time for our physiotherapists to help you reach your potential. <br/>
                            </Typography>
                            <Box>
                                <br />
                                <Typography gutterBottom variant="h6" component="p">
										To access your account, please click the
										button below.
                                </Typography>
                                <StyledButton rounded type="button" style={{ marginTop: 8 }}
                                    onClick={(e) =>
                                        this.handleNavigateToMyAccount(e)
                                    }
                                >
										My Account
                                </StyledButton>
                            </Box>
                            <p>
                                Now download the Universal Practice App from the App Store or Google Play.
                            </p>
                            <div className='confirmation-store-icons'>
                                <a href={this.state.AppLinkURL.IOS_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/app-store-badge.png')} alt="IOS store icon" className={'store-badges'} /></a>
                                <a href={this.state.AppLinkURL.Android_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/google-play-badge.png')} alt="Play store icon" className={'store-badges'} /></a>
                            </div>
                        </div>
                    )}
                </div>
            </form >
        );
    }
}
const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Confirmation);
