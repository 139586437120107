import { FormHelperText } from '@material-ui/core';
import React from 'react';

const UPFormHelperText = (props) => {
    const rest = { ...props };
    delete rest.style;
    delete rest.makeVisible;

    if(!props.makeVisible) {
        return <></>;
    }

    return (<FormHelperText {...rest} style={{ margin: 0, textAlign: 'center', fontStyle: 'italic', padding: 0, width: '100%', fontSize: '1rem', ...props.style }}>{props.children}</FormHelperText>);
};

export default UPFormHelperText;