import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SessionStorageVariables } from '../Shared/Constants';
import { AccountTab } from './AccountTab';

class AccountInformation extends Component {
 
    constructor(props) {
        super(props);
        this.state = { tabValue: 1};
    }

    componentDidMount() {
        const briefAssessmentFormString = sessionStorage.getItem(SessionStorageVariables.BriefAssessmentForm);

        const searchQuery = this.props.router.location.search;
        const programName = new URLSearchParams(searchQuery).get('p');
        if((!this.props.customerDetails?.subscription || !briefAssessmentFormString || 
            (this.props.customerDetails.subscription.appointmentRequired && !this.props.customerDetails.appointment)) && (!this.props.register?.auth?.user?.activeSubscription)) {
            this.props.dispatch(push(`/brief-assessment?p=${programName}`));
        } else {
            this.checkIfAlreadyAuthenticated(programName);
        }
    }

    async checkIfAlreadyAuthenticated(programName) {
        const currentSession = await Auth.currentSession();
        if (currentSession)
        {    
            this.props.dispatch(push(this.props.nextPage + (programName?.length > 0 ? `?p=${programName}` : '')));
        }
    }

    render() {
        return (
            <>
                <AccountTab tabValue={this.state.tabValue} dispatch={this.props.dispatch} router={this.props.router} register={this.props.register} nextPage={this.props.nextPage} previousPage={this.props.previousPage}></AccountTab>
            </>
        );
    }
}

export default connect(mapStateToProps)(AccountInformation);

function mapStateToProps(state) {
    return state;
}
