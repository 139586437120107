import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { RegisterActions, signIn } from '../Actions/RegisterActions';
import ComponentButtons from '../Shared/ComponentButtons';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Auth } from 'aws-amplify';
import { messages } from '../Shared/Messages';
import { connect } from 'react-redux';
import AuthenticationStepper from '../Shared/AuthenticationStepper';
import { AuthenticationTitle } from '../Shared/Constants';
import { notify } from '../Shared/Notifier';
import { NotificationSeverity } from '../Shared/Constants';
import MyDNATextInput from '../Shared/MyDNATextInput';
class MyDnaForgotPasswordConfirmation extends RequireNewPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = [RegisterActions.REQUIRE_NEW_PASSWORD];
        this.state = {
            password: '',
            errors: null
        };

        this.data = {
            email: '',
            password: '',
            repeat_password: '',
            confirmationCode: '',
        };

        this.validationSchema = yup.object({
            confirmationCode: yup.string()
                .required('Confirmation Code is Required'),
            password: yup.string()
                .required('Password is Required'),
            repeat_password: yup.string()
                .required('Repeat Password is Required'),
            email: yup.string().email('Invalid Email address').required('Email address is Required')
        });
    }

    componentWillUpdate() {
        this.data.email = this.props.forgotPassword.email;
    }

    confirmPasswordChange(data) {
        Auth.forgotPasswordSubmit(data.email, data.confirmationCode, data.password)
            .then(() => {
                this.setState({
                    errorMessage: null
                });
                notify(messages.changedPassword, NotificationSeverity.SUCCESS);
                super.changeState(RegisterActions.SIGN_IN);
                this.props.dispatch(signIn());
            })
            .catch((err) => {
                if (err.code === 'InvalidPasswordException') {
                    this.setState({
                        errorMessage: messages.invalidPassword
                    });
                }
                else if (err.code === 'CodeMismatchException') {
                    this.setState({
                        errorMessage: messages.CodeMismatchException
                    });
                } else if (err.code === 'InvalidParameterException') {
                    this.setState({
                        errorMessage: messages.invalidPassword
                    });
                }
                else {
                    this.setState({
                        errorMessage: err.message
                    });
                }
            });
    }

    confirmPasswordMatch(values) {
        return (values.password === values.repeat_password);
    }

    showComponent() {
        return (
            <Formik
                validateOnChange={true}
                initialValues={this.data}
                validationSchema={this.validationSchema}
                onSubmit={(data, actions) => {
                    if (this.confirmPasswordMatch(data)) {
                        actions.setSubmitting(true);
                        this.confirmPasswordChange(data);
                        actions.setSubmitting(false);
                    } else {
                        actions.setSubmitting(false);
                        actions.setFieldError('repeat_password', 'Passwords do not match');
                    }
                }} >
                {({ values, errors, isSubmitting, setFieldValue, setFieldError }) => (
                    <Form className={'content content-width'}>
                        <AuthenticationStepper title={AuthenticationTitle.ForgotPasswordConfirmation.title} progress={AuthenticationTitle.ForgotPasswordConfirmation.progress} />
                        <div>
                            <MyDNATextInput
                                name="email"
                                label="Email *"
                                maxLength="80"
                                setFieldValue={setFieldValue}
                                validationErrorText={errors['email']?.errorText}
                            />
                        </div>
                        <div>
                            <MyDNATextInput
                                name="confirmationCode"
                                label="Confirmation Code *"
                                maxLength="80"
                                setFieldValue={setFieldValue}
                                validationErrorText={errors['confirmationCode']?.errorText}
                            />
                        </div>
                        <div>
                            <MyDNATextInput
                                name="password"
                                label="New Password *"
                                maxLength="80"
                                setFieldValue={setFieldValue}
                                validationErrorText={errors['password']?.errorText}
                            />
                            <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        </div>
                        <div>
                            <MyDNATextInput
                                name="repeat_password"
                                label="Repeat Password *"
                                maxLength="80"
                                setFieldValue={setFieldValue}
                                validationErrorText={errors['repeat_password']?.errorText}
                                type="password"
                            />
                            <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        </div>
                        {this.state.errorMessage && <div>
                            <p className="error small">{this.state.errorMessage}</p>
                        </div>}
                        <ComponentButtons dispatch={this.props.dispatch} previousPage={this.props.previousPage} singleButton={true} isSubmitting={false} submitText={'Change Password'} rounded />
                    </Form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => {
    return state.register;
};

export default connect(mapStateToProps)((MyDnaForgotPasswordConfirmation));
