import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { ProgramFeatures } from '../RecommendedProgram/program-list';
import { useDispatch } from 'react-redux';
import { subscriptionCaptured, clearAppointment } from '../../Actions/CustomerDetailsActions';
import StyledButton from '../../components/StyledButton';
import styleClasses from './RecommendedProgramCardV2.module.scss';
import { VpaProductId } from '../../Shared/Constants';
import { sendAddToCart } from '../../Analytics/AnalyticsHelper';

const RecommendedProgramCardV2 = ({ onClick, program, isRecommended, otherProductWithInstalment, subscriptionOption, ctaText = 'Get Started' }) => {
    const dispatch = useDispatch();
    const handleProductSubmit = () => {
        dispatch(clearAppointment());
        dispatch(subscriptionCaptured(program.subscription));
        sendAddToCart('AUD', program.subscription.id, program.subscription.name, program.subscription?.subscriptionAmountPerPeriod);
        onClick(program.subscription.group);
    };
    const paymentInformation = () => {
        let info = `$${program.subscription?.subscriptionAmountPerPeriod}`;
        if (program.hasOneTimePayment)
        {
            if (program.subscription?.subscriptionAmountPerPeriod > 0)
                info += ' upfront';
        }
        else
        {
            if (program.subscription.instalmentPriceId?.length > 0)
            {
                info += ` per ${program.subscription.instalmentUnit}`;
            }
        }
        return info;                
    };

    const instalmentInformation = () => {
        if (program.subscription.instalmentPriceId?.length > 0 && program.subscription.numberOfInstalment > 0)
        {    
            let info = `$${program.subscription.instalmentAmount} `;
            if (program.subscription.instalmentUnit.includes('every')) {
                info += program.subscription.instalmentUnit;
            } else {
                info += `each ${program.subscription.instalmentUnit}`;
            }

            info += ` for ${program.subscription.numberOfInstalment} ${program.subscription.instalmentUnit}${program.subscription.numberOfInstalment > 1 ? 's' : ''}`;

            return info;
        }

        return '';
    };

    const durationInformation = () => {
        let info = '';
        if (program.subscription.id === VpaProductId.VPA1)
            return '';
        if (program.subscription && program.subscription.subscriptionInterval && program.subscription.numberOfInstalment >  0)
        {
            switch (program.subscription?.subscriptionIntervalUnit.toLowerCase())
            {
                case 'day':
                {
                    info += `${program.subscription?.subscriptionInterval / 7} week`;
                    break;
                }
                case 'month':
                {
                    if (program.subscription?.subscriptionInterval === 1)
                        info += `${program.subscription?.subscriptionInterval} month`;    
                    else
                        info += `${program.subscription?.subscriptionInterval} months`;  
                    break;
                }
            }

            info += ' exercise program';
                     
        }
        return info;
    };
    const featureDetails  = (feature) => {
        if (!feature)
            return '';
        if (feature.type === ProgramFeatures.INDIVIDUALISED_PROGRAM)
        {
            if (program.subscription?.subscriptionInterval && program.subscription?.subscriptionInterval === 'days')                
                return `${program.subscription?.subscriptionInterval / 7} weeks ${feature.details}`;
        }
        if (feature.type === ProgramFeatures.ONLINE_APPOINTMENT)
        {
            return `${program.subscription.appointmentAllowance} x ${feature.details}${program.subscription.appointmentAllowance > 1 ? 's' : ''}`;
        }                
        return feature.details;
    };
    const hasOnlineAppointment = (features) => {
        return features.find(f => f.type === ProgramFeatures.ONLINE_APPOINTMENT);
    };
    const cardClass = () => {
        return isRecommended ? `${ styleClasses.card_content } ${styleClasses.card_recommended}` :  styleClasses.card_content;
    };
    const cardContainer = () =>
    {
        return isRecommended ? `${ styleClasses.card_container } ${styleClasses.card_recommended}` :  styleClasses.card_container;
    };
    const textClassName  = () =>
    {
        return isRecommended ?  styleClasses.card_recommended : '';
    };
    
    return (
        <Box className={cardContainer()} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: 42 }}>
                {
                    program.subscription.subscriptionOption !== subscriptionOption && isRecommended &&
                        <Box className={styleClasses.card_recommendedBox}>
                            <Typography>
                                RECOMMENDED
                            </Typography>
                        </Box>
                }
                {
                    program.subscription.subscriptionOption === subscriptionOption && 
                    <Box className={styleClasses.card_recommendedBox}>
                        <Typography>
                            EXISTING
                        </Typography>
                    </Box>
                }
            </Box>
            
            <Box className={cardClass()} >               
                <Typography className={`${styleClasses.card_title} ${textClassName()}`}>
                    {program.subscription?.name}
                </Typography>
                <Box sx={{ marginBottom: 24, textAlign: 'left' }}>
                    <Typography variant="h5" className={`${styleClasses.card_price} ${textClassName()}`}> 
                        {paymentInformation()}
                        {
                            (program.subscription.instalmentPriceId?.length > 0 && program.subscription.numberOfInstalment > 0) ?
                                <> or <br /> {instalmentInformation()}
                                </> : otherProductWithInstalment && 
                            <>
                                <br />
                                <br />
                            </>
                        }
                    </Typography>
                    <Typography variant="h6" className={textClassName()}>
                        {program.planDetails}
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: 24, textAlign: 'left' }}>
                Included: <br/>
                    {
                        hasOnlineAppointment(program.features) ?
                            <>
                                {featureDetails(program.features[0])} <br/>
                                {
                                    durationInformation() != '' ?
                                        <>
                                            {durationInformation()}<br/>
                                        </>
                                        : <br />
                                }<br />

                                {featureDetails(program.features[1])} <br/>
                                {featureDetails(program.features[2])} <br/>
                                {featureDetails(program.features[3])} <br/>
                                {featureDetails(program.features[4])} <br/>
                                {featureDetails(program.features[5])} <br/>
                            </> 
                            : <>                              
                                {featureDetails(program.features[0])} <br/>
                                {featureDetails(program.features[1])} <br/>
                                {featureDetails(program.features[2])} <br/>
                                {featureDetails(program.features[3])} <br/>
                                {featureDetails(program.features[4])} <br/>
                                {featureDetails(program.features[5])} <br/>
                            </>                       
                    }
                   
                </Box>
            </Box> 
            
            {
                program.subscription.subscriptionOption !== subscriptionOption ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24 }}>
                        <StyledButton type="button" roundedInverse onClick={() => handleProductSubmit()}>{ctaText}</StyledButton>
                    </Box> :
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 30, marginTop: 24 }}>
                        <br />
                    </Box>
            
            }
        </Box>
    );
};

export default RecommendedProgramCardV2;