import { 
    CHRONICITY_ANSWER_MAP, 
    PRIMARY_CONCERN_TYPE_QUESTION, 
    PAIN_AREAS, 
    PRIMARY_PAIN_QUESTION, 
    AGE_QUESTION, 
    CAPACITY_ANSWER_MAP, 
    CAPACITY_QUESTION, 
    CHRONICITY_LIMIT, 
    FIRST_PAIN_ON_QUESTION, 
    HEALTH_ISSUES_PREVENTING_EXERCISE_QUESTION, 
    PAIN_LEVEL_QUESTION, 
    PREGNANCY_DUE_DATE_QUESTION, 
    PREGNANCY_CAN_EXERCISE } from '../../Constants/BriefAssessment';
import { PregnancyProductId, SubscriptionGroups, VpaProductId } from '../../Shared/Constants';
import moment from 'moment';

export const hasPainAssessment = (programName) =>
{
    if (!programName)
        return false;
    switch (programName)
    {
        case SubscriptionGroups.PRG:
        {
            return false;
        }
        default:
        {
            return true;
        }
    }
};

export const calculateRecommendation = (formData, programName)  => {
    let errorMsg, recommendedPlan, primaryPain;
    recommendedPlan = VpaProductId.VPA1;
    if (hasPainAssessment(programName))
    {
        const report = getPainAssessmentReport(formData);
        const painAssessmentReport = report.painAssessmentReport;
        errorMsg = report.errorMsg;
    
        if(errorMsg.length) {
            return {
                errorMessages : errorMsg,
                recommendedPlan: null,
                primaryPain: null
            };
        }
           
        if (programName.length > 0)
        {
            const painArea = getPainAreaFromBriefAssessment(formData);

            if (painArea)
            {
                if (painAssessmentReport.chronicityConclusion !== 'Acute') {
                    if (painAssessmentReport.noComorbidity === true) {
                        if (painAssessmentReport.ageLessSeventyFive === true) {
                            if (painAssessmentReport.painLevel <= 4) {
                                recommendedPlan = painAssessmentReport.capacityQuestion.result === 1 ? painArea.productIds.Strength1 : painArea.productIds.Strength2;
                            } else if (painAssessmentReport.painLevel <= 8) {
                                recommendedPlan = painArea.productIds.Rehab;
                            }
                        }
                    }
                }        
            } 
        } 
    
        primaryPain = painAssessmentReport.primaryPain;
    }
    else
    {
        errorMsg = [];
        
        switch (programName)
        {
            case SubscriptionGroups.PRG:
            {
                const report = getPregnancyAssessmentReport(formData);
                if (report.canExercise) {
                    const dueDate = moment(report.dueDate);
                    recommendedPlan =  dueDate.isAfter(moment()) ? PregnancyProductId.AnteNatal : PregnancyProductId.PostNatal;
                } else {
                    recommendedPlan = VpaProductId.VPA1;
                }
                break;
            }
            default:
            {
                break;
            }
        }
    }

    return {
        errorMessages : errorMsg,
        recommendedPlan: recommendedPlan,
        primaryPain: primaryPain
    };
};

export const getPainAssessmentReport = (formData) => {
    let painAssessmentReport = { primaryPain: {}, capacityQuestion : {} };
    let errorMsg = [];

    formData?.content?.sections?.forEach((section) => {
        section?.questions?.forEach((question) => {
            if(question?.name === AGE_QUESTION && question?.answer && isNaN(question?.answer)) {
                errorMsg.push('Invalid age. Age should be number.');
            }

            switch(question?.name) {
                case PRIMARY_PAIN_QUESTION: {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.primaryPain.location = result?.value;
                    break;
                }
                case PRIMARY_CONCERN_TYPE_QUESTION:
                {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.primaryPain.concernType = result?.value;
                    break;       
                }
                case AGE_QUESTION: {
                    painAssessmentReport.age = errorMsg.length ? null : Number(question?.answer);
                    painAssessmentReport.ageLessSeventyFive = errorMsg.length ? false : Number(question?.answer) < 75;
                    break;
                }
                case FIRST_PAIN_ON_QUESTION: {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.lessChronicity = result?.value !== CHRONICITY_LIMIT;
                    painAssessmentReport.chronicity = result?.value;
                    painAssessmentReport.chronicityConclusion = CHRONICITY_ANSWER_MAP[painAssessmentReport.chronicity].conclusion;

                    break;
                }
                case HEALTH_ISSUES_PREVENTING_EXERCISE_QUESTION: {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.noComorbidity = result?.value?.toLowerCase() === 'no';
                    break;
                }
                case PAIN_LEVEL_QUESTION: {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.painLevel = result?.value && !isNaN(result?.value) ? Number(result?.value) : null;
                    break;
                }
                case CAPACITY_QUESTION: {
                    const result = question?.answers?.find(({ selected }) => selected);
                    painAssessmentReport.capacityQuestion.result = result?.value ? CAPACITY_ANSWER_MAP[result?.value].value : null;
                    painAssessmentReport.capacityQuestion.conclusion = result?.value ? CAPACITY_ANSWER_MAP[result?.value].conclusion : null;
                    
                    break;
                }
            }

        });
    });
    return {
        painAssessmentReport,
        errorMsg
    };
};

export const getPregnancyAssessmentReport = (formData) => {
    let pregnancyReport = { dueDate: null, canExercise: true };
    formData?.content?.sections?.forEach((section) => {
        section?.questions?.forEach((question) => {
            switch(question?.name) {
                case PREGNANCY_DUE_DATE_QUESTION: {
                    pregnancyReport.dueDate = question?.answer ;
                    break;
                }
                case PREGNANCY_CAN_EXERCISE: {
                    const answer = question?.answers.find(x => x.selected == true);
                    if (answer) {
                        pregnancyReport.canExercise = answer.value === 'No';
                    }
                    break;
                }
            }
        });
    });

    return pregnancyReport;
};

export const getPainAreaFromBriefAssessment = (formData) => {
    const report = getPainAssessmentReport(formData);
    const painAssessmentReport = report.painAssessmentReport;
  
    for (const property in PAIN_AREAS) {
        if (painAssessmentReport.primaryPain.location.toLowerCase() == PAIN_AREAS[property].briefAssessmentValue)
        {
            return PAIN_AREAS[property];
        }
    }

    return null;
};