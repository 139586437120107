export const PractitionerActions = {
    FETCH_PRACTITIONERS: 'fetchPractitioner'
};

export function practitioners(practitioner) {
    return dispatch => {
        dispatch({
            type: PractitionerActions.FETCH_PRACTITIONERS,
            error: null,
            payload: {
                practitioners: practitioner
            }
        });
    };
}