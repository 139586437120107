import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { getBriefAssessmentForm, getFormTemplates, saveBriefAssessment } from '../../HttpHelpers/CRMFormsHttpHelper';
import UPForm from '../../components/UPFormComponents/UPForm';
import { clone, formatCRMFormData, getFormConditions } from '../../utils';
import UPLoader from '../../Shared/UPLoader';
import { CRMFormNames, LocalStorageVariables, SubscriptionGroups, SessionStorageVariables, QueryParameterNames } from '../../Shared/Constants';
import UPModal from '../../components/UPFormComponents/UPModal';
import { briefAssessmentFormConditions, briefAssessmentFormDefaults, briefAssessmentSliderMap } from '../../Constants/BriefAssessment';
import UPFormHelperText from '../../components/UPFormComponents/UPFormHelperText';
import BriefAssessmentBanner from '../../components/Banner';
import Slot from './Slot';
import { Auth } from 'aws-amplify';
import { isUserAuthenticated } from '../../HttpHelpers/AuthServiceHelper';
import { programList } from '../RecommendedProgram/program-list';
import { AppStateContext } from '../../Shared/UpAppStateProvider';
import { briefAssessmentCaptured } from '../../Actions/CustomerDetailsActions';
import { getPainAreaFromBriefAssessment } from './BriefAssessmentService';
import { signedIn } from '../../Actions/RegisterActions';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';

class BriefAssessment extends Component {
    static contextType = AppStateContext;

    state = {
        programName: null,
        user: null,
        briefAssessmentForm: null,
        isSubmitting: false,
        formError: '',
        error: '',
        userFormId: null,
        briefAssessmentFormTemplateId: null,
        showEmergencyAlert: false,
        conditions: null,
        loading: true,
        showWelcome: false,
        programList: programList,
        flow: null
    };  

    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const programName = new URLSearchParams(searchQuery).get('p') ?? '';
        const patientId = this.props.register?.auth.user?.patientId;

        let PatientBriefAssessmentFormId = localStorage.getItem(LocalStorageVariables.PatientBriefAssessmentFormId);
        let briefAssessmentForm = sessionStorage.getItem(SessionStorageVariables.BriefAssessmentForm);
        briefAssessmentForm = briefAssessmentForm ? JSON.parse(briefAssessmentForm) : null;
        briefAssessmentForm = briefAssessmentForm && briefAssessmentForm?.patientId === patientId ? { ...briefAssessmentForm, formTemplateContent: briefAssessmentForm.content } : null;

        const flow = new URLSearchParams(searchQuery).get(QueryParameterNames.F) ?? '';
        this.setState({
            programName,
            user: this.props.register?.auth.user,
            briefAssessmentForm: briefAssessmentForm,
            isSubmitting: false,
            userFormId: briefAssessmentForm?.id || PatientBriefAssessmentFormId || null,
            flow,
            template: null
        });

        if(briefAssessmentForm) {
            this.setState({ loading: false });
            return;
        }

        this.init();
    }

    async init() {
        try {
            this.setState({ showWelcome: true });
            const templates = await this.getTemplates();

            let template;
            switch (this.state.programName)
            {
                case SubscriptionGroups.PRG:
                {                    
                    template = templates.find((form) => form.name === CRMFormNames.BRIEF_ASSESSMENT_PREGNANCY);
                    break;
                }
                default:
                {
                    template = templates.find((form) => form.name === CRMFormNames.BRIEF_ASSESSMENT);
                }
            }
             
           
            if(!template) {
                this.showError('Failed to load the form');
                this.setState({ loading: false });
                return;
            } else 
            {
                this.setState({template: template});
            }
            localStorage.setItem(LocalStorageVariables.Templates, JSON.stringify(templates));
            this.setState({ briefAssessmentFormTemplateId: template.id });

            const data = await this.getForm(template.id);
            if(data.errorMessages) {
                this.showError('Failed to load the form');
                this.setState({ loading: false });
                return;
            }

            const conditions = getFormConditions(briefAssessmentFormConditions, data?.formTemplateContent?.sections || []);
            const sections = formatCRMFormData(data, briefAssessmentFormDefaults);
            this.setState({ conditions });

            data.formTemplateContent.sections = sections;
            this.setState({ briefAssessmentForm: data }, () => {
                this.setState({ loading: false });
            });
        } catch(err) {
            console.log(err);
            this.showError('Failed to load the form');
            this.setState({ loading: false });
            return;
        }
    }

    async handleSubmit(event, sections) {
        this.setState({ isSubmitting: true });
        const submittedForm = clone(this.state.briefAssessmentForm);
        submittedForm.formTemplateContent.sections = sections;

        this.setState({ briefAssessmentForm: submittedForm });

        const formData = {
            id: null, // Should be added after creating the form
            completed: true,
            content: { sections },
            templateName: this.state.template?.name
        };
       

        this.setState({ isSubmitting: false });

        const briefAssessmentForm = formData;
        const isAuthenticated = await isUserAuthenticated();
        
        if (this.state.programName !== SubscriptionGroups.PRG)
        {
            const painArea = getPainAreaFromBriefAssessment(briefAssessmentForm);
            this.setState({programName: painArea ? painArea.abbreviation : SubscriptionGroups.VPA});
            if (painArea && this.state.user) {
                const user = this.state.user;
                if (user && user.subscriptions) {
                    const activeSubscription = user.subscriptions.find(x => x.subscriptionId.includes(painArea.abbreviation));
                    user.activeSubscription = activeSubscription;
                    this.props.dispatch(signedIn(user));    
                }
            }
        }
        
        //save the form if user is authenticated (either signin or from auto login) in case any changes
        if (isAuthenticated && briefAssessmentForm)
        {
            try {
                const user = await Auth.currentSession();
                const res = await saveBriefAssessment(briefAssessmentForm, user.accessToken.jwtToken);
                if (!res.ok)
                {
                    this.showFormError('Failed save form. Please try again. If problem persist, please contact support.');
                    return;
                }
                   
            } catch(err) {
                this.showFormError('Failed save form. Please try again. If problem persist, please contact support.');
                return;
            }
        }
        
        this.props.dispatch(briefAssessmentCaptured(formData));
        
        
    
        this.goNextStep();
    }

    async getTemplates() {
        try {
            if(process.env.NODE_ENV === 'test') {
                return;
            }

            const res = await getFormTemplates();
            const json = await res.json();
            return json;
        } catch(err) {
            this.showFormError('Failed creating patient form.');
            return;
        }
    }

    async getForm(briefAssessmentFormTemplateId) {
        try {
            if(!briefAssessmentFormTemplateId) {
                this.showFormError('Invalid arguments');
                return;
            }

            const res = await getBriefAssessmentForm(briefAssessmentFormTemplateId);
            const json = await res.json();
            return json;
        } catch(err) {
            this.showFormError('Failed creating patient form.');
            return;
        }
    }

    // async createForm(patientId, briefAssessmentFormTemplateId, token) {
    //     try {
    //         if(!patientId || !briefAssessmentFormTemplateId || !token) {
    //             this.showFormError('Invalid arguments');
    //             return;
    //         }

    //         const res = await createBriefAssessmentForm(patientId, briefAssessmentFormTemplateId, token);
    //         const json = await res.json();
    //         return json;
    //     } catch(err) {
    //         this.showFormError('Failed creating patient form.');
    //         return;
    //     }
    // }

    // async saveForm(formData, token) {
    //     try {
    //         if(!formData || !token) {
    //             this.showFormError('Invalid arguments');
    //             return;
    //         }

    //         const res = await saveBriefAssessment(formData, token);
    //         const json = await res.json();
    //         return json;
    //     } catch(err) {
    //         this.showFormError('Failed save form.');
    //         return;
    //     }

    // }

    showFormError(message) {
        this.setState({ formError: message });
    }

    showError(message) {
        this.setState({ error: message });
    }

    goNextStep() {
        sendLinkClick('Next Button', 'Next', `${this.props.nextPage}`, window.location.pathname, null);
        this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }

    toggleAlert() {
        this.setState(prevState => ({ showEmergencyAlert: !prevState.showEmergencyAlert }));
    }

    handleEmergencyAlert() {
        this.setState({ showEmergencyAlert: true });
    }

    handleBannerClose() {
        this.setState({ showWelcome: false });
    }

    render() {
        return (<>

            {
                this.state.loading && <UPLoader />
            }
            {
                (!this.state.loading && this.state.error) && <UPFormHelperText makeVisible={!!this.state.error} style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</UPFormHelperText>
            }
            {
                <BriefAssessmentBanner onClose={(e) => this.handleBannerClose(e)} buttonText='NEXT' makeVisible={!this.state.loading && this.state.showWelcome}>
                    To recommend a personalised pathway for you, we will need to ask you some questions about your health and wellbeing. This will only take 3 minutes.
                </BriefAssessmentBanner>
            }
            {
                (!this.state.showWelcome && !this.state.error && !this.state.loading && this.state.briefAssessmentForm?.formTemplateContent?.sections?.length) ?
                    <UPForm
                        sections={this.state.briefAssessmentForm.formTemplateContent.sections}
                        onSubmit={(e, sections) => this.handleSubmit(e, sections)}
                        isSubmitting={this.state.isSubmitting}
                        error={this.state.formError}
                        showFormError={(msg) => this.showFormError(msg)}
                        showEmergencyAlert={() => this.handleEmergencyAlert()}
                        conditions={this.state.conditions}
                        slot={{
                            // Question name without space or special characters, don't remove numbers if any
                            radiobuttons: {
                                'pleaseshowuswhereyourpainorprimaryconcernis': Slot,
                                'doyouhaveanyotherhealthconditionsorareasofpainthatlimityoufromparticipatinginexercise': Slot,
                            }
                        }}
                        sliderMapping={briefAssessmentSliderMap}
                        onChange={(data) => {
                            const conditions = getFormConditions(briefAssessmentFormConditions, data || []);
                            this.setState({ conditions });
                        }}
                    /> : <></>
            }
            <UPModal
                toggleAlert={() => this.toggleAlert()}
                open={this.state.showEmergencyAlert}
                title="Medical Emergency"
            >
                <Typography component="span" variant='h6' style={{ fontWeight: 'bold', color: '#000000' }} >If you have a medical emergency, please contact triple zero (000).</Typography>
            </UPModal>
        </>);
    }
}

const mapStateToProps = (state) => {
    return state;
};

export { BriefAssessment };
export default connect(mapStateToProps)(BriefAssessment);
