import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { Formik, Form } from 'formik';
import ComponentButtons from '../../Shared/ComponentButtons';
import {
    subscriptionCaptureFailed,
    subscriptionCaptured,
    couponDetailsReset
} from '../../Actions/CustomerDetailsActions';
import { updateCustomerDetails } from '../../HttpHelpers/CustomerServiceHttpHelper';
import SubscriptionOption from './SubscriptionOption';
import { calculateCost, calculateTimeToPay, setDefaultSubscriptionOption,  } from './SubscriptionServices';
import { connect, useDispatch } from 'react-redux';
import { getSubscriptionDetails } from '../../HttpHelpers/CustomerServiceHttpHelper';
import { makeStyles } from '@material-ui/core/styles';
import UPLoader from '../../Shared/UPLoader';
import { sendAddToCart } from '../../Analytics/AnalyticsHelper';

const useStyles = makeStyles(theme => ({
    finePrintContainer: {
        marginTop: 30,
        marginBottom: 30
    }
}));

const Subscription = (props) => {
    const classes = useStyles();
    const [validationError, setValidationError] = useState('');
    const [subscriptionOptions, setSubscriptionOptions] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const dispatch = useDispatch();
    React.useEffect(() => {
        getSubscriptionDetails()
            .then((response) => {
                response.json()
                    .then((data) => {
                        setSubscriptionOptions(setDefaultSubscriptionOption(data));
                    });
            });
    }, []);

    const updateCustomerSubscription = async (store) => {
        setValidationError('');
        await updateCustomerDetails(store)
            .then((response) => {
                if (response.ok) {
                    dispatch(push(props.nextPage));
                    return response.json();
                }
                throw Error(response.status);
            })
            .catch((err) => {
                setValidationError(
                    'There was an error updating your subscription option. Please try again.'
                );
                dispatch(subscriptionCaptureFailed(err.message));
            });
    };

    const onOptionSelect = (value, setFieldValue) => {
        if (selectedProduct !== value) {
            dispatch(couponDetailsReset());
            setFieldValue('coupon', '');
            setSuccessMessage(null);
            setValidationError(null);
        }
        setSelectedProduct(value);
    };
    let defaultOption = subscriptionOptions?.find(element => element.isDefault === true);
    if (subscriptionOptions) {
        return (
            <div>
                <Formik
                    validateOnChange={true}
                    initialValues={{ subscriptionOptionId: defaultOption?.id, subscriptionOptionPriceId: defaultOption?.priceId }}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        // change the subscriptionOptionId to crmProductCode
                        const product = subscriptionOptions.find(x => x.id === data.subscriptionOptionId);
                        dispatch(subscriptionCaptured(product));
                        const customerDetails = {
                            ...props,
                            subscription: product
                        };
                        updateCustomerSubscription(customerDetails);
                        sendAddToCart('AUD', customerDetails.subscription.id, customerDetails.subscription.name, customerDetails.subscription.subscriptionAmountPerPeriod);
                        setSubmitting(false);
                    }}
                >
                    {({ values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className='content'>
                                <div className='subscription-options-container'>
                                    <SubscriptionOption
                                        setFieldValue={setFieldValue}
                                        onSelect={(id) => onOptionSelect(id, setFieldValue)}
                                        subscriptionOptionId={values.subscriptionOptionId}
                                        subscriptionOptions={subscriptionOptions}
                                    />
                                </div>
                                <div className={classes.finePrintContainer}>
                                    <p>
                    All memberships offer you the first 7 days free, unrestricted access to the Universal Practice App. <br/>
                    All memberships are recurring and will automatically renew at the end of each subscription period. <br/>
                    You can unsubscribe at any time.
                                    </p>
                                </div>
                                
                                {successMessage && <p className="success">{successMessage}</p>}
                                {validationError && (
                                    <p className="error">{validationError}</p>
                                )}
                                <div className={'order-total-block'}>
                                    <div className={'order-total-container'}>
                                        <div className={'order-total'}>
                                            <div>Your first payment of ${calculateCost(subscriptionOptions, values?.subscriptionOptionId, 0)} will occur on the {calculateTimeToPay(subscriptionOptions, values?.subscriptionOptionId)}</div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                               
                                <ComponentButtons
                                    isSubmitting={isSubmitting}
                                    dispatch={dispatch}
                                    singleButton={true}
                                    rounded
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    } else {
        return <UPLoader/>;
    }

};

function mapStateToProps(state) {
    return state.customerDetails;
}

export default connect(mapStateToProps)(Subscription);
