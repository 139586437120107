import LBPAndRehabImage from '../../Assets/programs/LBP-1.jpg';
import LBPPrevention1Image from '../../Assets/programs/LBP-Strength-1.jpg';
import LBPPrevention2Image from '../../Assets/programs/LBP-Strength-2.jpg';
import VPB from '../../Assets/programs/VPB.png';
import AppointmentsIcon from '../../Shared/Icons/Appointments';
import IndividualisedRehabilitationProgramIcon from '../../Shared/Icons/IndividualisedRehabilitationProgram';
import SupportIcon from '../../Shared/Icons/Support';
import BodyMappingIcon from '../../Shared/Icons/BoadyMapping';
import TrackingIcon from '../../Shared/Icons/Tracking';
import StrengthIcon from '../../Shared/Icons/Strength';
import MeditationIcon from '../../Shared/Icons/Meditation';
import OngoingProgramIcon from '../../Shared/Icons/OngoingProgram';
import RehabilitationIcon from '../../Shared/Icons/Rehabilitation';
import PersonalisedTreatmentPlanIcon from '../../Shared/Icons/PersonalisedTreatmentPlan';
import OngoingUseAppIcon from '../../Shared/Icons/OngoingUseApp';

export const ProgramFeatures = {
    ONLINE_APPOINTMENT:'Online Physiotherapy Appointment',
    BODY_MAPPING: 'Body Mapping',
    INDIVIDUALISED_PROGRAM: 'Individualised',
    SUPPORT: 'Support',
    LOGGING_AND_TRACKING: 'Logging and Tracking',
    MEDITATION_AND_MINDFULNESS: 'Meditation and Mindfulness'
};

export const ProgramFeaturesDescriptions = {
    BODY_MAPPING: 'Body Mapping Technology',
    LOGGING_AND_TRACKING: 'Tracking & Logging',
    SUPPORT: '24/7 Support, Care and Education'
};

const VPA = { Description : 'Ideal for those with recent injury and complex or specific needs, our Online Physiotherapy Appointments provide individualised pain and rehabilitation solutions all from the comfort of your home. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.' };
const RehabilitationBasicFeatures =  [{
//     icon: AppointmentsIcon,
//     details: 'Online Check-in Appointment',
//     type: ProgramFeatures.ONLINE_APPOINTMENT,
// },  {
    icon: BodyMappingIcon,
    details: ProgramFeaturesDescriptions.BODY_MAPPING,
    type: ProgramFeatures.BODY_MAPPING,
}, {
    icon: SupportIcon,
    details: ProgramFeaturesDescriptions.SUPPORT,
    type: ProgramFeatures.SUPPORT,
}, {
    icon: TrackingIcon,
    details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
    type: ProgramFeatures.LOGGING_AND_TRACKING,
}, {
    icon: IndividualisedRehabilitationProgramIcon,
    details: 'Online Rehabilitation Program',
    type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
}, {
    icon: MeditationIcon,
    details: 'Meditation and Mindfulness',
    type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
}];

const StrengthBasicFeatures = [{
//     icon: AppointmentsIcon,
//     details: 'Online Check-in Appointment',
//     type: ProgramFeatures.ONLINE_APPOINTMENT,
// }, {
    icon: BodyMappingIcon,
    details: ProgramFeaturesDescriptions.BODY_MAPPING,
    type: ProgramFeatures.BODY_MAPPING,
}, {
    icon: SupportIcon,
    details: ProgramFeaturesDescriptions.SUPPORT,
    type: ProgramFeatures.SUPPORT,
}, {
    icon: TrackingIcon,
    details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
    type: ProgramFeatures.LOGGING_AND_TRACKING,
}, {
    icon: StrengthIcon,
    details: 'Online Strength Program',
    type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
}, {
    icon: MeditationIcon,
    details: 'Meditation and Mindfulness',
    type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
}];

const RehabilitationProductDescription = 'An 10 week program, ideal for those with pain or injury needing a clear pathway to rehabilitate and strengthen. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.';
const Strength1ProductDescription = 'An 10 week program, ideal for those with niggles or occasional pain needing a clear pathway to strengthen and optimise. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.';
const RebateInformation = 'Private Health Insurance Rebates Apply ';
export const programList = [
    {
        id: 'unipr_mbs_1mon_au001',
        image: null,
        title: '',
        description: 'Daily Workout',
        features: [
            {
                icon: SupportIcon,
                details: ProgramFeaturesDescriptions.SUPPORT,
                type: ProgramFeatures.SUPPORT,
            }, {
                icon: TrackingIcon,
                details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
                type: ProgramFeatures.LOGGING_AND_TRACKING,
            }, {
                icon: IndividualisedRehabilitationProgramIcon,
                details: 'Individualised Physiotherapy Program',
                type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
            }, {
                icon: MeditationIcon,
                details: 'Meditation and Mindfulness',
                type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
            }],
        planDetails: '',
        information: ''
    },
    {
        id: 'unipr_mbs_3mon_au001',
        image: null,
        title: '',
        description: 'Daily Workout',
        features: [
            {
                icon: SupportIcon,
                details: ProgramFeaturesDescriptions.SUPPORT,
                type: ProgramFeatures.SUPPORT,
            }, {
                icon: TrackingIcon,
                details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
                type: ProgramFeatures.LOGGING_AND_TRACKING,
            }, {
                icon: IndividualisedRehabilitationProgramIcon,
                details: 'Individualised Physiotherapy Program',
                type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
            }, {
                icon: MeditationIcon,
                details: 'Meditation and Mindfulness',
                type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
            }],
        planDetails: '',
        information: ''
    },
    {
        id: 'unipr_mbs_12mon_au001',
        image: null,
        title: '',
        description: 'Daily Workout',
        features: [
            {
                icon: SupportIcon,
                details: ProgramFeaturesDescriptions.SUPPORT,
                type: ProgramFeatures.SUPPORT,
            }, {
                icon: TrackingIcon,
                details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
                type: ProgramFeatures.LOGGING_AND_TRACKING,
            }, {
                icon: IndividualisedRehabilitationProgramIcon,
                details: 'Individualised Physiotherapy Program',
                type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
            }, {
                icon: MeditationIcon,
                details: 'Meditation and Mindfulness',
                type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
            }],
        planDetails: '',
        information: ''
    },
    {
        id: 'unipr_lbp_onetime_au001',
        image: LBPAndRehabImage,
        description: 'An 10 week program, ideal for those with pain or injury needing a clear pathway to rehabilitate and strengthen. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.',
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: `Our evidence based pain rehabilitation program is specifically designed to move you from a state of injury and pain and return you to health and your daily pursuits.

    Pain presents in the body in response to injury and perceived threat. Although extremely beneficial when an acute injury presents, pain can become itself a barrier to recovery long after damaged tissue has settled.
     
    This program begins by addressing our stabilising muscles. These muscles are inhibited when our bodies are in pain, after injury we know that they are ‘switched off’ reducing local stability at our joints. At the same time as our deeper stabilising system switches off, our  stronger more global muscles often tighten, in compensation. This additionally contributes to stiffness, soreness and poor movement quality, further perpetuating the pain cycle.. The early weeks of this program target gentle release in  these outer compensatory muscles while activating our deeper stabilising muscles to bring the body back to its equilibrium. 
    
    Once the foundations are laid, we move on to applying our stabilising muscles to movement and control. So that the body can begin to move well again. These exercises are all about quality to ensure that we lay down new habits and neural pathways and prevent old dormant injuries from resurfacing down the track through ongoing poor movement patterning.
     
    The final leg is about strength. Once our stabilisers are active and we have learnt how to move well again, we need to build strength and resilience in the body, so that we have capacity to take on more through our days and return to the activities that bring us joy.
     
    During this phase we also challenge the body by introducing more dynamic daily workouts so that patients are then ready to move into our more dynamic preventative stream.
    `
    },{
        id: 'unipr_lbp_onetime_au002',
        image: LBPPrevention1Image,
        description: 'An 10 week program, ideal for those with niggles or occasional pain needing a clear pathway to strengthen and optimise. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.',
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: `Our preventative pathway is designed to build and maintain strength, resilience and mobility in our bodies to keep us moving forward, on track and away from pain.
 
    It's normal for pain to be present from time to time as our bodies signal activities that may be perceived as harmful, and as a warning sign that we may be taking on too much. We don't want to be ignoring any level of pain, as it's an opportunity to listen to the body and respond in a way that addresses any underlying stiffness, weakness and limitation. This not only prevents little niggles and warning signs from becoming an injury or issue, but it optimises our bodies strength, flexibility, capacity, endurance and overall performance.
         
    This program is about bringing out the best in ourselves. Exercises and Daily Workouts are prescribed with quality to focus on our stabilising system, our global strength, mobility, control and integrated movement patterns. This will leave you feeling strong, energised and confident in your ability to actively partake in activity, recreation and pursuit that brings you joy.
         
    We have two levels of preventative stream, as we understand that age, underlying fitness and body type will yield different capacities. Based on your physical capacity we have recommended Prevention Level 1. As the name suggests Prevention Level 1 is less challenging than Prevention Level 2 and does not assume a base level of fitness. Once you begin your program you are able to swap between the streams as life events surface or your ability changes. Our physiotherapy team will also guide and support you to ensure you are doing what's best for you.
    `
    }, {
        id: 'unipr_lbp_onetime_au003',
        image: LBPPrevention2Image,
        description: 'An 10 week program, ideal for those with niggles or occasional pain needing a clear pathway to strength and performance. Let our online practice combine technology and human connection to ensure you’re on the path to health and happiness.',
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: `Our preventative pathway is designed to build and maintain strength, resilience and mobility in our bodies to keep us moving forward, on track and away from pain.
 
    It's normal for pain to be present from time to time as our bodies signal activities that may be perceived as harmful, and as a warning sign that we may be taking on too much. We don't want to be ignoring any level of pain, as it's an opportunity to listen to the body and respond in a way that addresses any underlying stiffness, weakness and limitation. This not only prevents little niggles and warning signs from becoming an injury or issue, but it optimises our bodies strength, flexibility, capacity, endurance and overall performance.
         
    This program is about bringing out the best in ourselves. Exercises and Daily Workouts are prescribed with quality to focus on our stabilising system, our global strength, mobility, control and integrated movement patterns. This will leave you feeling strong, energised and confident in your ability to actively partake in activity, recreation and pursuit that brings you joy.
         
    We have two levels of preventative stream, as we understand that age, underlying fitness and body type will yield different capacities. Based on your physical capacity we have recommended Prevention Level 2. As the name suggests Prevention Level 2 is more challenging than Prevention Level 1 and assumes an underlying level of fitness. Once you begin your program you are able to swap between the streams as life events surface or your ability changes. Our physiotherapy team will also guide and support you to ensure you are doing what's best for you.
    `
    
    },
    {
        id: 'unipr_vpa_onetime_au001',
        image: VPB,
        title: 'Book an Online Physio Appointment',
        description: VPA.Description,
        features: [{
            icon: AppointmentsIcon,
            details: 'Online Physiotherapy Appointment',
            type: ProgramFeatures.ONLINE_APPOINTMENT,
        },  {
            icon: BodyMappingIcon,
            details: ProgramFeaturesDescriptions.BODY_MAPPING,
            type: ProgramFeatures.BODY_MAPPING,
        }, {
            icon: SupportIcon,
            details: ProgramFeaturesDescriptions.SUPPORT,
            type: ProgramFeatures.SUPPORT,
        }, {
            icon: TrackingIcon,
            details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
            type: ProgramFeatures.LOGGING_AND_TRACKING,
        }, {
            icon: IndividualisedRehabilitationProgramIcon,
            details: 'Individualised Physiotherapy Program',
            type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
        }, {
            icon: MeditationIcon,
            details: 'Meditation and Mindfulness',
            type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
        }],
        planDetails: 'Private Health Insurance Rebates Apply ',
        information: `If you have recently injured yourself, are in pain or have inflammation or swelling we recommend an Online physio appointment.

        Our Online Physiotherapy Appointments are designed to assess, diagnose, manage and reduce pain, tissue damage and fear associated with a new or severe injury. In these appointments our primary goal is to provide education, advice and strategies to ensure you are able to move about your day safely and comfortably.
    
        Gentle and appropriate self-release techniques are also prescribed, alongside exercises that are targeted toward pain reduction and relief. Their primary goal is to settle pain and get the body to a place where it is ready to start its rehabilitation.
     
        Individual Online Physiotherapy Appointments are also recommended for patients over the age of 75, those with comorbidities (eg. Diabetes or history of neurological conditions), and those with other injuries or needs that would benefit from a highly individualized one-on-one approach.
     
    The Online Physiotherapy Appointments may also act as a pathway into our rehabilitation streams once your physiotherapist has assessed your injury and individual needs 
    `
    }, 
    {
        id: 'unipr_vpa_onetime_au002',
        image: VPB,
        title: 'Book an Online Physio Appointment',
        description: VPA.Description,
        features: [{
            icon: AppointmentsIcon,
            details: 'Online Physiotherapy Appointment',
            type: ProgramFeatures.ONLINE_APPOINTMENT,
        },  {
            icon: BodyMappingIcon,
            details: ProgramFeaturesDescriptions.BODY_MAPPING,
            type: ProgramFeatures.BODY_MAPPING,
        }, {
            icon: SupportIcon,
            details: ProgramFeaturesDescriptions.SUPPORT,
            type: ProgramFeatures.SUPPORT,
        }, {
            icon: TrackingIcon,
            details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
            type: ProgramFeatures.LOGGING_AND_TRACKING,
        }, {
            icon: IndividualisedRehabilitationProgramIcon,
            details: 'Individualised Physiotherapy Program',
            type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
        }, {
            icon: MeditationIcon,
            details: 'Meditation and Mindfulness',
            type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
        }],
        planDetails: 'Private Health Insurance Rebates Apply ',
        information: `If you have recently injured yourself, are in pain or have inflammation or swelling we recommend an Online physio appointment.

        Our Online Physiotherapy Appointments are designed to assess, diagnose, manage and reduce pain, tissue damage and fear associated with a new or severe injury. In these appointments our primary goal is to provide education, advice and strategies to ensure you are able to move about your day safely and comfortably.
    
        Gentle and appropriate self-release techniques are also prescribed, alongside exercises that are targeted toward pain reduction and relief. Their primary goal is to settle pain and get the body to a place where it is ready to start its rehabilitation.
     
        Individual Online Physiotherapy Appointments are also recommended for patients over the age of 75, those with comorbidities (eg. Diabetes or history of neurological conditions), and those with other injuries or needs that would benefit from a highly individualized one-on-one approach.
     
    The Online Physiotherapy Appointments may also act as a pathway into our rehabilitation streams once your physiotherapist has assessed your injury and individual needs 
    `
    }, 
    {
        id: 'unipr_vpa_onetime_au003',
        image: VPB,
        title: 'Book an Online Physio Appointment',
        description: VPA.Description,
        features: [{
            icon: AppointmentsIcon,
            details: 'Online Physiotherapy Appointment',
            type: ProgramFeatures.ONLINE_APPOINTMENT,
        },  {
            icon: BodyMappingIcon,
            details: ProgramFeaturesDescriptions.BODY_MAPPING,
            type: ProgramFeatures.BODY_MAPPING,
        }, {
            icon: SupportIcon,
            details: ProgramFeaturesDescriptions.SUPPORT,
            type: ProgramFeatures.SUPPORT,
        }, {
            icon: TrackingIcon,
            details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
            type: ProgramFeatures.LOGGING_AND_TRACKING,
        }, {
            icon: IndividualisedRehabilitationProgramIcon,
            details: 'Individualised Physiotherapy Program',
            type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
        }, {
            icon: MeditationIcon,
            details: 'Meditation and Mindfulness',
            type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
        }],
        planDetails: 'Private Health Insurance Rebates Apply ',
        information: `If you have recently injured yourself, are in pain or have inflammation or swelling we recommend an Online physio appointment.

        Our Online Physiotherapy Appointments are designed to assess, diagnose, manage and reduce pain, tissue damage and fear associated with a new or severe injury. In these appointments our primary goal is to provide education, advice and strategies to ensure you are able to move about your day safely and comfortably.
    
        Gentle and appropriate self-release techniques are also prescribed, alongside exercises that are targeted toward pain reduction and relief. Their primary goal is to settle pain and get the body to a place where it is ready to start its rehabilitation.
     
        Individual Online Physiotherapy Appointments are also recommended for patients over the age of 75, those with comorbidities (eg. Diabetes or history of neurological conditions), and those with other injuries or needs that would benefit from a highly individualized one-on-one approach.
     
    The Online Physiotherapy Appointments may also act as a pathway into our rehabilitation streams once your physiotherapist has assessed your injury and individual needs 
    `
    }, 
    {
        id: 'unipr_org_generic_au001',
        image: null,
        title: '',
        description: 'Daily Workout',
        features: [
            {
                icon: SupportIcon,
                details: ProgramFeaturesDescriptions.SUPPORT,
                type: ProgramFeatures.SUPPORT,
            }, {
                icon: TrackingIcon,
                details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
                type: ProgramFeatures.LOGGING_AND_TRACKING,
            }, {
                icon: IndividualisedRehabilitationProgramIcon,
                details: 'Individualised Physiotherapy Program',
                type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
            }, {
                icon: MeditationIcon,
                details: 'Meditation and Mindfulness',
                type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
            }],
        planDetails: '',
        information: ''
    },{
        id: 'unipr_prg_subscription_au001',
        description: '',
        features: [{
            icon: SupportIcon,
            details: ProgramFeaturesDescriptions.SUPPORT,
            type: ProgramFeatures.SUPPORT,
        }, {
            icon: TrackingIcon,
            details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
            type: ProgramFeatures.LOGGING_AND_TRACKING,
        }, {
            icon: IndividualisedRehabilitationProgramIcon,
            details: 'Online Rehabilitation Program',
            type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
        }, {
            icon: MeditationIcon,
            details: 'Meditation and Mindfulness',
            type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
        }],
        planDetails: 'Private Health Insurance Rebates Apply ',
        instalmentDetails: '',
        information: ''
    },
    {
        id: 'unipr_prg_subscription_au002',
        description: '',
        features: [{
            icon: SupportIcon,
            details: ProgramFeaturesDescriptions.SUPPORT,
            type: ProgramFeatures.SUPPORT,
        }, {
            icon: TrackingIcon,
            details: ProgramFeaturesDescriptions.LOGGING_AND_TRACKING,
            type: ProgramFeatures.LOGGING_AND_TRACKING,
        }, {
            icon: IndividualisedRehabilitationProgramIcon,
            details: 'Online Rehabilitation Program',
            type: ProgramFeatures.INDIVIDUALISED_PROGRAM,
        }, {
            icon: MeditationIcon,
            details: 'Meditation and Mindfulness',
            type: ProgramFeatures.MEDITATION_AND_MINDFULNESS,
        }],
        planDetails: 'Private Health Insurance Rebates Apply ',
        instalmentDetails: '',
        information: ''
    }, 
    {
        id: 'unipr_hip_onetime_au001',
        image: LBPAndRehabImage,
        description:RehabilitationProductDescription,
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    },{
        id: 'unipr_hip_onetime_au002',
        image: LBPPrevention1Image,
        description: Strength1ProductDescription,
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    }, {
        id: 'unipr_hip_onetime_au003',
        image: LBPPrevention2Image,
        description: Strength1ProductDescription,
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    
    }, 
    {
        id: 'unipr_nck_onetime_au001',
        image: LBPAndRehabImage,
        description: RehabilitationProductDescription,
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '$54 per week',
        information: ''
    },{
        id: 'unipr_nck_onetime_au002',
        image: LBPPrevention1Image,
        description: Strength1ProductDescription,
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    }, 
    {
        id: 'unipr_upb_onetime_au001',
        image: LBPAndRehabImage,
        description: RehabilitationProductDescription,
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    }, 
    {
        id: 'unipr_kne_onetime_au001',
        image: LBPAndRehabImage,
        description: RehabilitationProductDescription,
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    },{
        id: 'unipr_kne_onetime_au002',
        image: LBPPrevention1Image,
        description: Strength1ProductDescription,
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    }, 
    {
        id: 'unipr_shl_onetime_au001',
        image: LBPAndRehabImage,
        description: RehabilitationProductDescription,
        features: RehabilitationBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    },{
        id: 'unipr_shl_onetime_au002',
        image: LBPPrevention1Image,
        description: Strength1ProductDescription,
        features: StrengthBasicFeatures,
        planDetails: RebateInformation,
        instalmentDetails: '',
        information: ''
    }, 
// {
//     id: 'unipr_fot_onetime_au001',
//     image: LBPAndRehabImage,
//     description: RehabilitationProductDescription,
//     features: RehabilitationBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// },{
//     id: 'unipr_fot_onetime_au002',
//     image: LBPPrevention1Image,
//     description: Strength1ProductDescription,
//     features: StrengthBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// },{
//     id: 'unipr_fot_onetime_au003',
//     image: LBPPrevention1Image,
//     description: Strength1ProductDescription,
//     features: StrengthBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// }, 
// {
//     id: 'unipr_ank_onetime_au001',
//     image: LBPAndRehabImage,
//     description: RehabilitationProductDescription,
//     features: RehabilitationBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// },{
//     id: 'unipr_ank_onetime_au002',
//     image: LBPPrevention1Image,
//     description: Strength1ProductDescription,
//     features: StrengthBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// },{
//     id: 'unipr_ank_onetime_au003',
//     image: LBPPrevention1Image,
//     description: Strength1ProductDescription,
//     features: StrengthBasicFeatures,
//     planDetails: RebateInformation,
//     instalmentDetails: '',
//     information: ''
// }, 
];
