import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { QueryParameterNames } from '../Shared/Constants';
import { AccountTab } from './AccountTab';

class AccountInformationDirect extends Component {
 
    constructor(props) {
        super(props);
        this.state = { tabValue: 1, programName: null, flow: null, };
    }

    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const searchParams = new URLSearchParams(searchQuery);
        const programName = searchParams.get(QueryParameterNames.P) ?? '';
        const flow = searchParams.get(QueryParameterNames.F) ?? '';
        this.setState({ programName, flow });
        this.checkIfAlreadyAuthenticated();
    }

    async checkIfAlreadyAuthenticated() {
        try  
        {
            const currentSession = await Auth.currentSession();
            if (currentSession)
                this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}&f=${this.state.flow}`));
        }
        catch (e)
        {
            console.log(e);
        }
    }

    render() {
        return (
            <>
                <AccountTab tabValue={this.state.tabValue} dispatch={this.props.dispatch} router={this.props.router} register={this.props.register} nextPage={this.props.nextPage} previousPage={this.props.previousPage}></AccountTab>
            </>
        );
    }
}

export default connect(mapStateToProps)(AccountInformationDirect);

function mapStateToProps(state) {
    return state;
}
