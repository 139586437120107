import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledButton from '../components/StyledButton';
const ComponentButtons = (props) => {
    const Button = () => {
        const style = props.style ? { background: '#FFFDF9', ...props.style } : { background: '#FFFDF9' };
        return <StyledButton
            disabled={props.disabled || props.isSubmitting}
            type={props.type || 'submit'}
            value={props.value || 'Submit'}
            variant="contained"
            color="secondary"
            style={style}
            onClick={(e) => props.onClick?.(e)}
            outlined={props.outlined}
            rounded={props.rounded}
        >
            {props.isSubmitting === true ? <CircularProgress size={25} style={{ color: 'black' }} /> : props.submitText ?? <p style={{ color: 'black' }}>Next</p>}
        </StyledButton>;
    };

    if(props.buttonOnly) {
        return <Button />;
    }

    return (
        <React.Fragment>
            <div className="inline-buttons" >
                <Button />
            </div><br />
            {props.singleButton ? (
                <></>
            ) : (
                <div>
                    <StyledButton
                        className={'BackButton'}
                        disabled={props.isSubmitting}
                        disableElevation
                        variant="contained"
                        onClick={() => props.backOnClick?.()}
                        outlined={props.outlined || true}
                    >
                        {(props.disableArrows) ? '' : <ArrowBackIosIcon fontSize={'small'} />}
                        {props.backText ?? 'BACK'}
                    </StyledButton>
                </div>
            )}
        </React.Fragment>
    );
};

ComponentButtons.propTypes = {
    isSubmitting: PropTypes.bool,
};

export default ComponentButtons;
