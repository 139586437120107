export const SecondaryTitles = {
    Create: '',
    Verify:
    'Please check your email (including your Junk Mail) and enter the code below.',
    SignIn: 'Your myDNA membership is only a few steps away!',
    Subscription: 'Your first 7 days are free.',
    SubscriptionLine2: 'You will not be charged until after 7 days.',
    Payment: 'Billing address'
};
export const PrimaryTitles = {
    PaymentDetails: 'You\'re only one step away',
    Create: '',
    Verify: 'We\'ve sent you an email to verify your account',
    SignIn: 'Please login to continue your registration',
    Confirmation: '',
};
