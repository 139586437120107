import { CustomerDetailsActions } from '../Actions/CustomerDetailsActions';
import { AppLinkURLS } from '../Shared/AppLinkURLS';

const initialValue = {
    state: CustomerDetailsActions.PERSONAL_DETAILS,
    error: {},
    userDetails: {},
    subscription: null,
    stepNum: 0,
    subcriptionOptions: null,
    couponDetails: null,
    payment: null,
    appointment: null,
    briefAssessmentForm: null
};

// set initial values for customer details
let timeZone = 'Australia/Sydney';
if (window.Intl && typeof window.Intl === 'object') {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
}

initialValue.userDetails['timeZone'] = timeZone;
const subscriptionJson = localStorage.getItem('Subscription');
initialValue.subscription = subscriptionJson && subscriptionJson?.length > 0 ?  JSON.parse(subscriptionJson) : null;
const appointmentJson = sessionStorage.getItem('PatientAppointmentData');
initialValue.appointment = appointmentJson?.length > 0 ? JSON.parse(appointmentJson) : null;

export const selectApplinkURL = (partner = 'DEFAULT') => {
    partner = partner ?? 'DEFAULT';
    var country = initialValue.place?.country?.toUpperCase() ?? 'DEFAULT';
    var url = AppLinkURLS.find(element => ((element.Country.toUpperCase() === country.toUpperCase()) && (element.Partner.toUpperCase() === partner.trim().toUpperCase())));
    url = url ?? AppLinkURLS.find(element => (element.Country === 'DEFAULT' && element.Partner === 'DEFAULT'));
    return url;
};

export function CustomerDetailsReducer(
    state = {
        ...initialValue,
    },
    action
) {
    if (!Object.values(CustomerDetailsActions).includes(action.type))
        return state;

    if (action.type === CustomerDetailsActions.APPOINTMENT_CAPTURED) {
        initialValue.appointment = action.payload.appointment;
    }

    if (action.type === CustomerDetailsActions.COUPON_DETAILS_CAPTURED 
        || action.type === CustomerDetailsActions.COUPON_DETAILS_RESET) {
        initialValue.couponDetails = action.payload.couponDetails;
    } 

    if (action.type === CustomerDetailsActions.SUBSCRIPTION_CAPTURED
        || action.type  === CustomerDetailsActions.SUBSCRIPTION_CAPTURE_FAILED) {
        initialValue.subscription = action.payload.subscription;
    }

    if (action.type === CustomerDetailsActions.PAYMENT_DETAILS) {
        initialValue.auth = action.payload.auth;
        initialValue.subscription = action.payload.subscription;
        initialValue.stepNum = action.payload.stepNum;
        initialValue.payment = action.payload.payment;
    }

    if (action.type === CustomerDetailsActions.APPOINTMENT_CAPTURED || action.type === CustomerDetailsActions.APPOINTMENT_CLEARED) {
        initialValue.appointment = action.payload.appointment;        
    }

    if (action.type === CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED) {
        initialValue.payment = action.payload.payment;
    }

    if (action.payload?.userDetails) {
        initialValue.userDetails = action.payload.userDetails;
    }

    if (action.type === CustomerDetailsActions.BRIEF_ASSESSMENT_CAPTURED) {
        initialValue.briefAssessmentForm = action.payload.briefAssessmentForm;
    }
    if (action.type === CustomerDetailsActions.HEALTH_REPORT_CAPTURED) {
        initialValue.healthReport = action.payload.healthReport;
        initialValue.healthReport.firstName = action.payload.firstName;
        initialValue.healthReport.email = action.payload.email;
    }
    if (action.type === CustomerDetailsActions.PAYMENT_RESULT_CAPTURED) {
        initialValue.paymentResult = action.payload.paymentResult;
    }
    
    if (action.type === CustomerDetailsActions.ORGANISATION_CAPTURED) {
        initialValue.organisation = action.payload.organisation;
    }

    return Object.assign({}, state, initialValue);
}
