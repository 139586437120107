import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useField } from 'formik';

export default function SelectComponent(props) {
    const [field, meta] = useField(props.name);

    function handleChange(name, e, setFieldValue) {
        setFieldValue(name, e.target.value);
    }

    function getErrorText() {
        if (meta.error && meta.touched) return meta.error;
        if (props.validationErrorText) return props.validationErrorText;

        return '';
    }

    return (
        <FormControl fullWidth variant={'outlined'} style={{marginTop: 16, marginBottom: 16}}>
            <InputLabel id="select-label" style={{color: '#fff'}}>{props.label}</InputLabel>
            <Select
                labelId="select-label"
                id="select-control"
                value={props?.value}
                label={props?.label}
                onChange={(e)=>{
                    handleChange(props.name, e, props.setFieldValue);
                    if (props.onChange)
                        props.onChange(e);
                }}
            >
                {props?.choices?.map((choice, index) => (<MenuItem key={index} value={choice.value}>{choice.displayText}</MenuItem>))};
            </Select>
            { getErrorText().length > 0 && <FormHelperText style={props.helperStyle || {}}>{getErrorText()}</FormHelperText> }
        </FormControl>
    );
}