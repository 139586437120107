import { SessionStorageVariables, LocalStorageVariables } from '../Shared/Constants';
export const CustomerDetailsActions = {
    SET_SUBSCRIPTION_PRODUCTS: 'setSubscriptionProducts',
    SUBSCRIPTION_CAPTURED: 'subscriptionCaptured',
    SUBSCRIPTION_CAPTURE_FAILED: 'subscriptionCaptureFailed',
    PAYMENT_DETAILS: 'paymentDetails',
    PAYMENT_DETAILS_CAPTURED: 'paymentDetailsCaptured',
    PAYMENT_DETAILS_CAPTURED_FAILED: 'paymentDetailsCapturedFailed',
    PAYMENT_RESULT_CAPTURED: 'paymentResultCaptured',
    COUPON_DETAILS_CAPTURED: 'couponDetailsCaptured',
    COUPON_DETAILS_RESET: 'couponDetailsReset',
    APPOINTMENT_CAPTURED: 'appointmentCaptured',
    APPOINTMENT_CLEARED: 'appointmentCleared',
    BRIEF_ASSESSMENT_CAPTURED: 'briefAssessmentCaptured',
    HEALTH_REPORT_CAPTURED: 'healthReportCaptured',
    ORGANISATION_CAPTURED: 'organisationCaptured'
};


export const couponDetailsCaptured = (couponDetails) => (
    dispatch
) => {
    dispatch({
        type: CustomerDetailsActions.COUPON_DETAILS_CAPTURED,
        error: null,
        payload: {
            couponDetails: couponDetails,
        },
    });
};

export const couponDetailsReset = () => (
    dispatch
) => {
    dispatch({
        type: CustomerDetailsActions.COUPON_DETAILS_RESET,
        error: null,
        payload: {
            couponDetails: null,
        },
    });
};

export const subscriptionCaptured = (subscription) => (
    dispatch
) => {
    localStorage.setItem(LocalStorageVariables.Subscription, JSON.stringify(subscription));
    dispatch({
        type: CustomerDetailsActions.SUBSCRIPTION_CAPTURED,
        error: null,
        payload: {
            subscription: subscription,
        },
    });
};

export const subscriptionCaptureFailed = (error) => (
    dispatch
) => {
    dispatch({
        type: CustomerDetailsActions.SUBSCRIPTION_CAPTURE_FAILED,
        error: error,
        payload: {
            subscription: {},
        },
    });
};

export const paymentDetails = (props) => (dispatch) => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS,
        error: null,
        payload: {
            auth: props.auth,
            subscription: props.subscription,
            payment: {},
            stepNum: 3,
        },
    });
};

export const paymentDetailsCaptured = (payment) => (dispatch) => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED,
        error: null,
        payload: {
            payment: payment,
        },
    });
};

export const paymentResponseCaptured = (paymentResult) => (dispatch) => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_RESULT_CAPTURED,
        error: null,
        payload: {
            paymentResult: paymentResult
        }
    });
};

export const paymentDetailsCaptureFailed = (error) => (dispatch) => {
    dispatch({
        type: CustomerDetailsActions.PAYMENT_DETAILS_CAPTURED_FAILED,
        error: error,
    });
};

export const appointmentCaptured = (appointment) => (dispatch) => {
    sessionStorage.setItem(SessionStorageVariables.PatientAppointmentData, JSON.stringify(appointment));

    dispatch({
        type: CustomerDetailsActions.APPOINTMENT_CAPTURED,
        error: null,
        payload: {
            appointment: appointment
        }
    });
};

export const clearAppointment = () => (dispatch) => {
    sessionStorage.removeItem(SessionStorageVariables.PatientAppointmentData);

    dispatch({
        type: CustomerDetailsActions.APPOINTMENT_CLEARED,
        error: null,
        payload: {
            appointment: null
        }
    });
};

export const briefAssessmentCaptured = (form) => (dispatch) => {
    sessionStorage.setItem(SessionStorageVariables.BriefAssessmentForm, JSON.stringify(form));

    dispatch({
        type: CustomerDetailsActions.BRIEF_ASSESSMENT_CAPTURED,
        error: null,
        payload: {
            briefAssessmentForm: form
        }
    });
};


export const healthReportCaptured = (report, firstName, email) => (dispatch) => {
    sessionStorage.setItem(SessionStorageVariables.HealthReport, JSON.stringify(report));

    dispatch({
        type: CustomerDetailsActions.HEALTH_REPORT_CAPTURED,
        error: null,
        payload: {
            healthReport: report,
            firstName: firstName,
            email: email 
        }
    });
};


export const organisationCaptured = (organisation) => (dispatch) => {
    sessionStorage.setItem(SessionStorageVariables.Organisation, JSON.stringify(organisation));
    dispatch({
        type: CustomerDetailsActions.ORGANISATION_CAPTURED,
        error: null,
        payload: {
            organisation: organisation,
        }
    });
};