import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const StyledSwitch = withStyles({
    switchBase: {
        color: '#767676',
        '&$checked': {
            color: '#FFFFFF',
        },
        '& + $track': {
            backgroundColor: '#505250',
        },
        '&$checked + $track': {
            backgroundColor: '#FFFFFF',
            opacity: 0.5,
        },
    },
    checked: {},
    track: {},
})(Switch);



const UPSwitch = (props) => <StyledSwitch {...props} />;

export { UPSwitch };
export default UPSwitch;
