import React, { useEffect } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { buildSubscriptionOptions } from './SubscriptionServices';

const useStyles = makeStyles(theme => ({
    selectedBorderStyle: {
        border: '1px solid #FFF',
        width: '17rem',
        height: '14rem',
        backgroundColor: 'white',
        paddingTop: 10
    },
    unselectedBorderStyle: {
        width: '17rem',
        height: '14rem',
        color: '#FFF',
        backgroundColor: 'black',
        border: '1px solid #FFF',
        paddingTop: 10
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    chargeContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    toggleBtnContent: {
        paddingBottom: 20
    }
}));

const SubscriptionOption = (props) => {

    useEffect(() => {
        // make initial focus (when options overflow the screen) to the default option
        const defaultOption = props.subscriptionOptions?.find((option) => option.isDefault);
        if (defaultOption)
            document.getElementById(defaultOption.id).scrollIntoView({ inline: 'center' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classes = useStyles();
    const subscriptionOptions = buildSubscriptionOptions(props.subscriptionOptions);
    const getDefaultClassName = (isDefault, selectedId, id) => {
        const isSelected = selectedId === id;

        if (isDefault && isSelected) 
            return 'invert';
        if (isSelected)
            return 'invert';
        return ''; 
    };
    if (Array.isArray(subscriptionOptions)) {
        return (
            <div className="subscription-plan">
                <ToggleButtonGroup
                    value={props.subscriptionOptionId}
                    exclusive>
                    {subscriptionOptions?.map((value) => {
                        return (                            
                            <ToggleButton
                                value={value.id}
                                key={value.id}
                                id={value.id}
                                className="toggle-button"
                                onClick={() => {
                                    props.setFieldValue('subscriptionOptionId', value.id);
                                    props.setFieldValue('subscriptionOptionPriceId', value.priceId);
                                    props.onSelect(value.id);
                                }}
                            >
                                <Paper
                                    elevation={1}
                                    key={value.id}
                                    className={[props.subscriptionOptionId === value.id ? classes.selectedBorderStyle : classes.unselectedBorderStyle, classes.toggleBtnContent].join(' ')}>
                                    <div className={classes.optionContainer}>
                                        <div className="value-container">
                                            {value.isDefault ?
                                                <div><span className="value-indicator">Most Popular!</span></div>
                                                :
                                                <div><span className="value-indicator-empty"></span></div>
                                            }
                                            <span className={`titleLevel1 ${getDefaultClassName(value.isDefault, props.subscriptionOptionId, value.id)}`}>
                                                {`${value.subscriptionInterval} ${value.subscriptionInterval > 1 ? 'MONTHS' : 'MONTH'}`}
                                            </span>
                                        </div>
                                        <div className={classes.chargeContainer}>
                                            <p>
                                                <strong>
                                                    
                                                    <span className={`chargeAmountFont ${getDefaultClassName(value.isDefault, props.subscriptionOptionId, value.id)}`}>
                                                        ${value.costPerMth.dollarValue}
                                                    </span>
                                                    <span className={`chargeAmountSubtleFont ${getDefaultClassName(value.isDefault, props.subscriptionOptionId, value.id)}`}>
                                                        {value.costPerMth.centValue ? `.${value.costPerMth.centValue}` : ''} / month
                                                    </span>
                                                </strong>
                                            </p>
                                        </div>
                                        <div>
                                            <p className={`chargeAmountSubtleFont ${getDefaultClassName(value.isDefault, props.subscriptionOptionId, value.id)}`}>
                        Price: ${value.initialChargeAmount} <br />
                                                <span dangerouslySetInnerHTML={{__html: value.savingPercentage > 0 ?
                          `(save ${value.savingPercentage}%)`
                                                    :
                                                    '&nbsp'
                                                }} />
                                            </p>
                                        </div>
                                    </div>
                                </Paper>
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </div >
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
};

export default SubscriptionOption;
