import React from 'react';

const UPLoader = ({ containerHeight, height, position, opacity, marginBottom }) => {
    return (
        <div className="up-loading" style={{
            display: 'flex',
            height: containerHeight ? containerHeight : '150vh',
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            opacity: opacity ? opacity : '0.5',
            position: position ? position : 'absolute',
            left: 0,
            top: 0,
            zIndex: 500,
            overflow: height ? 'hidden' : 'visible',
            marginBottom: marginBottom ? marginBottom : '0px'
        }}>
            <img alt="loading" src={require('../Assets/UPLoading.gif')}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto',
                    height: height ? height : 'auto'
                }}
            />
        </div>
    );
};

export default UPLoader;
