import React from 'react';

export const Loading = ({logo})=> {
    return (
        <div className="splash-screen">
            {logo && <img className="img" src={logo} alt="splash_screen" />}
            <p className="para"></p>
            <div className="loading">...</div>
        </div>
    );
};