import React from 'react';
import { TextField } from '@material-ui/core';
export const StyledTextFieldAlignment =
{
    LEFT: 'Left',
    CENTER: 'Center'
};
export default function StyledTextField({ title, name, onChange, type, helperText, error, value, required, helperStyle, length = 40, style, inputProps, textClassName, fieldAlignment = StyledTextFieldAlignment.LEFT, inputLabelProps, inputRef }) {
    const renderContainer = (style) => 
    {
        switch(style)
        {
            case StyledTextFieldAlignment.CENTER:
            {
                return {
                    justifyContent: 'center'
                };
            }
            default:
                return {
                    display: 'flex',
                    flexDirection: 'column'
                };
        }
    };

    return (
        <div style={renderContainer(fieldAlignment)}>
            <TextField
                id="text-field-standard"
                className={textClassName ? textClassName : 'up-standard-input' }
                name={name}
                onChange={onChange}
                type={type}
                value={value}
                required={required}
                InputLabelProps={{...inputLabelProps}}
                InputProps={{...inputProps, maxLength: length }}
                label={title}
                style={style}
                inputRef={inputRef}
            />
            {error && <div className="error small" style={helperStyle || {}}>{helperText}</div>}
        </div>
    );
}
