/* eslint-disable quotes */
/* eslint-disable indent */
import React, { useContext, useState, useEffect } from "react";
// interface AppStateValue {
// 	currentUserInfo: string | undefined;
// 	setCurrentUserInfo: React.Dispatch<
// 		React.SetStateAction<string | undefined>
// 	>;
// 	subscriptions: Array | undefined;
// 	setSubscriptions: React.Dispatch<React.SetStateAction<Array | undefined>>;
// }

export const AppStateContext = React.createContext();
export function useAppState() {
	const state = useContext(AppStateContext);

	if (!state) {
		throw new Error("useAppState must be used within AppStateProvider");
	}

	return state;
}

export function AppStateProvider({ children }) {
	const [subscriptions, setSubscriptions] = useState(undefined);
	useEffect(() => {
		/* Load a canvas that will be used as the replacement image for Background Replacement */
	}, []);

	const providerValue = {
		subscriptions,
		setSubscriptions,
	};

	return <AppStateContext.Provider value={providerValue}>{children}</AppStateContext.Provider>;
}

