import moment from 'moment';

import { EXP_API_EVENTS_KEY, EXP_API_EVENTS_URL } from '../Shared/Configs';
import { SessionStorageVariables } from '../Shared/Constants';
import { EventsApiEndpoints } from './HttpConstants';
const eventSource = 'RegistrationWeb';

const getPatientData = () => {
    try {
        const patientString = sessionStorage.getItem(SessionStorageVariables.Patient);
        if(!patientString) {
            return;
        }

        const patient = JSON.parse(patientString);
        return patient;
    } catch(err) {
        console.log(err);
        return;
    }
};

export const sendRequiredRiskAssessment = async (tokenId) => {
    try {
        const patient = getPatientData();
        if(!patient) {
            return;
        }

        const params = {
            'source': eventSource,
            'eventType': 'RiskAssessmentRequired',
            'username': patient?.username,
            'additionalData': [
                {
                    'name': 'EndpointAddress',
                    'value': patient?.username
                },
                {
                    'name': 'EndpointType',
                    'value': 'Email'
                }
            ],
            'userAttributes': [
                {
                    'name': 'FirstName',
                    'value': [patient?.firstName]
                }
            ]
        };

        const result = await callPostEventEndPoint(params, tokenId);
        return result;
    } catch(err) {
        console.log(err);
        return;
    }
};

export const completedRequiredBriefAssessment = async (tokenId) => {
    try {
        const patient = getPatientData();
        if(!patient) {
            return;
        }

        const params = {
            'source': eventSource,
            'eventType': 'RiskAssessmentCompleted',
            'username': patient?.username,
            'additionalData': [
                {
                    'name': 'EndpointAddress',
                    'value': patient?.username
                },
                {
                    'name': 'EndpointType',
                    'value': 'Email'
                }
            ],
            'userAttributes': [
                {
                    'name': 'RiskAssessmentCompleted',
                    'value': [moment().utc().format('YYYY-MM-DDThh:mm:ss[Z]')]
                },
                {
                    'name': 'FirstName',
                    'value': [patient?.firstName]
                }
            ]
        };

        const result = await callPostEventEndPoint(params, tokenId);
        return result;
    } catch(err) {
        console.log(err);
        return;
    }
};



export const registrationWebSignedIn = async (tokenId, userName) => {
    try {     
        const params = {
            'source': eventSource,
            'eventType': 'RegistrationWebSignedIn',
            'username': userName,
            'additionalData': [
                {
                    'name': 'EndpointAddress',
                    'value': userName
                },
                {
                    'name': 'EndpointType',
                    'value': 'Email'
                }
            ],
            'userAttributes': [
                {
                    'name': 'RegistrationWebSignedIn',
                    'value': [moment().utc().format('YYYY-MM-DDThh:mm:ss[Z]')]
                }
            ]
        };

        const result = await callPostEventEndPoint(params, tokenId);
        return result;
    } catch(err) {
        console.log(err);
        return;
    }
};

const callPostEventEndPoint = (params, tokenId) => {
    return fetch(`${EXP_API_EVENTS_URL}${EventsApiEndpoints.Events}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': EXP_API_EVENTS_KEY,
            Authorization: `Bearer ${tokenId}`
        },
        body: JSON.stringify(params)
    });
};