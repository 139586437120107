import { isProduction } from '../../Shared/Services/EnvironmentService';
export const getHealthInsuranceList = () => {
    if (isProduction())
        return HealthInsuranceListProd;
    return HealthInsuranceList;
};
const HealthInsuranceList =  [{
    'name': 'ACA',
    'token': 'b8895a06-f301-4f13-ab6e-200311ec1427'
},
{
    'name': 'AHM',
    'token': '37aff623-6c52-45e6-8f27-02aaa6fbb2ae'
},
{
    'name': 'AIA',
    'token': 'f69de5c7-fb30-4901-8881-29ec78a9f798'
},
{
    'name': 'Australian Unity',
    'token': '137c88cd-e853-4b6a-89b2-dedc1d98a162'
},
{
    'name': 'Bupa',
    'token': '83801f97-e431-412d-abfb-4b368ffe1f05'
},
{
    'name': 'HCF',
    'token': 'c7a907b0-f16e-444e-bea6-8cfe241a5610'
}];


const HealthInsuranceListProd =  [
    {
        'name': 'ACA',
        'token': '32ae3973-a61d-4763-9718-c04a63fccb0c'
    },
    {
        'name': 'AHM',
        'token': '53a32bff-7a30-4560-9990-33f6bb511ca3'
    },
    {
        'name': 'AIA',
        'token': '184910a9-b258-4f0b-84e8-97718c5b03aa'
    },
    {
        'name': 'Australian Unity',
        'token': '61a80db7-cec7-4a84-ac92-5a99b166d697'
    },
    {
        'name': 'Bupa',
        'token': 'a9fdfe9f-89b6-41d4-96db-5e3d990cd4a3'
    },
    {
        'name': 'CBHS Corporate',
        'token': '098347cb-d32b-4b0e-a727-3279004e9d3f'
    },
    {
        'name': 'CBHS',
        'token': 'e47bce9e-36c1-4c49-8e67-44f3770842d8'
    },
    {
        'name': 'CDH',
        'token': '84cab823-c349-45ea-88af-dad4b45c2063'
    },
    {
        'name': 'CUA',
        'token': '8da3c9ec-e2b7-4bea-a21b-b5fd8ae7d3a8'
    },
    {
        'name': 'Defence Health Limited',
        'token': 'ae88dde9-63e6-4cbe-904d-a1db9980f456'
    },
    {
        'name': 'Doctors Health Fund',
        'token': '43e375cc-8ca3-42c2-92b5-1f5af93620f6'
    },
    {
        'name': 'GMHBA',
        'token': '0c4db168-d6f6-4986-9265-7cc68fd85b4c'
    },
    {
        'name': 'GU',
        'token': 'cc2e46ea-d9a0-44ab-8e22-9259f7fc8be9'
    },
    {
        'name': 'HBF',
        'token': '90c4eae7-d488-4769-941a-1149a03d6cf3'
    },
    {
        'name': 'HCF',
        'token': '93e95607-dd2c-48c5-b4dc-0d40a8eabf31'
    },
    {
        'name': 'HCi',
        'token': '316008a3-240f-433b-ba02-af87e123fb00'
    },
    {
        'name': 'Health Insurance Fund of Australia Limited',
        'token': '932218f4-9cb2-43b7-b240-969e7c6c7599'
    },
    {
        'name': 'Health Partners',
        'token': '8a6aea39-d0ca-458b-80c6-b513f0c22694'
    },
    {
        'name': 'Latrobe',
        'token': '70a54b43-23a4-4da8-b261-dd2d5e2910ba'
    },
    {
        'name': 'Medibank',
        'token': '91c0b122-638f-45dd-a0b5-ee85208d40fb'
    },
    {
        'name': 'Mildura Health Fund',
        'token': '6c9c5b92-6e71-43cd-af5e-a5899f7f8ba2'
    },
    {
        'name': 'National Health Benefits Australia',
        'token': 'acf98afa-c6d2-49b5-b16c-bf2f92683c54'
    },
    {
        'name': 'Navy Health',
        'token': '073b19b3-d027-4a20-9fab-ff263b70443c'
    },
    {
        'name': 'NIB',
        'token': 'cd16237c-9ec2-4cdb-9e55-b4f570035fca'
    },
    {
        'name': 'Peoplecare',
        'token': 'f40bc73f-f606-4b2f-b5e1-538effa7e642'
    },
    {
        'name': 'Phoenix',
        'token': '4dee64a8-baea-4577-beb0-997e866b9fe9'
    },
    {
        'name': 'Police Health',
        'token': '5f0aabf3-255f-47a6-9507-5eee216ef338'
    },
    {
        'name': 'Queensland Country',
        'token': 'b3569da5-345f-4192-aa55-3a695b07cd86'
    },
    {
        'name': 'Reserve Bank',
        'token': '97ed0bf8-5249-4b3f-a6fd-c5d46f934fa7'
    },
    {
        'name': 'RT Health',
        'token': 'e9f83ba3-9725-4707-92c5-4675dee91922'
    },
    {
        'name': 'St Lukes Health',
        'token': '89fe28fa-ada4-4c59-99ba-4b803360a71a'
    },
    {
        'name': 'Teachers Health',
        'token': 'bf088b11-a9d2-4370-867e-a816e9da9699'
    },
    {
        'name': 'Transport Health',
        'token': '6f081a88-6ac2-4ea6-9415-bd59d3ed07d3'
    },
    {
        'name': 'TUH Health',
        'token': '2790b821-3f73-4956-92df-e52636c343ca'
    },
    {
        'name': 'Westfund',
        'token': 'da00c5dc-9af0-4712-bfd0-9536e4fe1b54'
    }
];
    