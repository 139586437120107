import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { styled, withStyles } from '@material-ui/core';

const UPToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },

    },

}))(ToggleButtonGroup);


const UPToggleButton = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: '#FFFFFF !important',
        backgroundColor: 'rgba(249, 245, 236, 0.2)',
        border: ' 1px solid #FFFFFF',
    },
    '&.MuiToggleButton-root:hover': {
        color: '#FFFFFF !important',
        backgroundColor: 'rgba(249, 245, 236, 0.2)',
    },
    color: '#FFFFFF !important',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '10px 30px',
    gap: '8px',
    height: '44px',
    background: '#282828',
    borderRadius: '0px !important'
});

export { UPToggleButtonGroup, UPToggleButton };