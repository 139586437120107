import { FormControl, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
}));



export default function UPSelectFormControl(props) {
    const classes = useStyles();
    return (<FormControl variant="outlined" className={classes.formControl} {...props}>{props.children}</FormControl>);
}

