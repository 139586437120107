import { PractitionerServiceEndpoints, ExperienceApiEndpoints } from './HttpConstants';
import { EXP_API_BASE_URL, EXP_API_KEY } from '../Shared/Configs';
import { getDefaultTimeZone } from '../Register/RegistrationService';

export function getUser(tokenId) {
    return fetchGetEndpoint(`${EXP_API_BASE_URL}${ExperienceApiEndpoints.GetUser}`, tokenId);      
}

export function getSubscriptionDetails(tokenId) {
    return fetchGetEndpoint(`${EXP_API_BASE_URL}${ExperienceApiEndpoints.ActiveSubscription}`, tokenId);      
}

export function getBillingDetails(tokenId) {
    return fetchGetEndpoint(`${EXP_API_BASE_URL}${ExperienceApiEndpoints.BillingDetails}`, tokenId);      
}

export async function getPatient(user) {   
    return await fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.GetPatient}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`
            }
        }
    ).then(res => {  
        if(res.ok) {
            return res.json();
        }})
        .then((json) => {
            if (json.result.length > 0)
            {
                let patient = json.result[0];
                patient.mobile = patient.patientPhoneNumbers.find(n => n.phoneType == 'Mobile')?.number;
                return patient;
            }             
            return null;
        });
}

export function savePatient(user) {
    const patient = {
        id: user.id ?? '',
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.username,
        dob: user.dob,
        acceptedPrivacyPolicy: user.acceptedPrivacyPolicy,
        timeZone: user.appointmentData?.tz ?? getDefaultTimeZone(), //when saving patient with no appointment then use default timezone
        patientPhoneNumbers: user.contact ?  [{
            phoneType: 'mobile',
            number: user.contact
        }] : null
    };

    return fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.SavePatient}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`
            },
            body: JSON.stringify(patient),
        }
    );
}

export function saveAppointment(appointment, jwtToken) {
    return fetch(
        `${EXP_API_BASE_URL}${PractitionerServiceEndpoints.BookPractitionerAppointment}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${jwtToken}`
            },
            body: JSON.stringify(appointment),
        }
    );
}

function fetchPostEndpoint(url, tokenId, data) {
    return fetch(
        `${url}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            },
            body: data ? JSON.stringify(data) : null,
        }
    );  
}

function fetchGetEndpoint(url, tokenId) {
    return fetch(
        `${url}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            },
        }
    );  
}