import React from 'react';
import { CardExpiryElement } from '@stripe/react-stripe-js';
import { StripeInput } from './MyDNACardNumber';
import { TextField } from '@material-ui/core';

const MyDNAExpiryElement = ({ otherProps }) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [shrink, setShrink] = React.useState(false);
    const [empty, setEmpty]=React.useState(true);

    function handleElementChange({ error, empty }) {
        setShrink(!empty);
        setEmpty(empty);
        if (error) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage(null);
        }
    }

    const hasError = errorMessage !== null;
    return (
        <TextField
            className="up-standard-input"
            fullWidth
            label="Expiry"
            error={hasError}
            helperText={hasError ? errorMessage || 'Invalid' : ''}
            onChange={handleElementChange} 
            InputLabelProps={{
                shrink
            }}
            onFocus={()=> setShrink(true)}
            onBlur={()=> setShrink(!empty)}        
            InputProps={{
                inputProps: {
                    component: CardExpiryElement,
                    options: {
                        placeholder: '',
                        style: {
                            base: {
                                fontSize: '18px',
                                color: '#fff',
                                '::placeholder': {
                                    color: '#fff',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }
                },
                inputComponent: StripeInput,
                className: 'up-standard-input',
            }}
            {...otherProps}
        />
    );
};

export default MyDNAExpiryElement;