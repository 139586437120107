import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#000000',
        maxHeight: '56px',
        position: 'absolute',
        top: '-10px',
        width: '450px',
        left: '50%',
        marginLeft: '-245px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& .MuiStepIcon-completed': {
            fill: '#000000'
        },
        '& .MuiStepIcon-active': {
            fill: '#FFFFFF !important'
        },
        '& .MuiStepIcon-root': {
            fill: '#000000',
            transform: 'scale(1.5)',
            fontWeight: 400
        },
        '& .MuiStepIcon-root text': {
            fill: '#000000'
        }
    }
}));

const containerStyle = { width: '42px', height: '42px', borderRadius: '50%', border: '1px solid #ffffff' };
const pStyle = { fontWeight: 500, fontSize: '20px', textAlign: 'center', color: '#ffffff', height: '42px' };

function StepIcon(props) {
    const { completed, label } = props;

    return (
        <Box className='flex-center-vh'>
            {completed ?
                <Box className='flex-center-vh' sx={{ backgroundColor: '#ffffff', color: '#000000', ...containerStyle }}>
                    <Box className='flex-center-vh' sx={{ textAlign: 'center' }}>
                        <Typography className='flex-center-vh' style={{ ...pStyle, color: '#000000' }}>{label}</Typography>
                    </Box>
                </Box>
                : <Box className='flex-center-vh' sx={{ backgroundColor: '#000000', color: '#ffffff', ...containerStyle }}>
                    <Box className='flex-center-vh' sx={{ textAlign: 'center' }}>
                        <Typography className='flex-center-vh' style={pStyle}>{label}</Typography>
                    </Box>
                </Box>}
        </Box>
    );
}


const UPStepper = (props) => {
    const [steps, setSteps] = React.useState([]);
    const classes = useStyles();

    React.useEffect(() => {
        if(!props?.numberOfSteps || isNaN(props?.numberOfSteps) || Number(props.numberOfSteps) <= 1) {
            setSteps([]);
            return;
        }

        const arr = [];
        for(let i = 1; i <= props.numberOfSteps; i++) {
            arr.push({ label: i, isCompleted: !!(props?.activeStep && !isNaN(props.activeStep) && i <= Number(props.activeStep)) });
        }

        setSteps(arr);
    }, [props.numberOfSteps, props.activeStep]);

    return (
        <Box sx={{ maxWidth: '960px', margin: 'auto' }}>{
            steps.length ? <Stepper nonLinear activeStep={props.activeStep} className={classes.root} connector={<></>} >
                {steps.map(({ label, isCompleted }) => (
                    <Step key={label} completed={isCompleted}>
                        <StepLabel StepIconComponent={(p) => StepIcon({ ...p, label })}></StepLabel>
                        {/* {props.onClick ? <StepButton color="inherit" onClick={(e) => props.onClick(index, e)}></StepButton> : <StepLabel StepIconComponent={StepIcon}></StepLabel>} */}
                    </Step>
                ))}
            </Stepper> : <></>
        }</Box>
    );
};

UPStepper.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    activeStep: PropTypes.number.isRequired,
    numberOfSteps: PropTypes.number.isRequired,
};

export default UPStepper;