import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styleClasses from './StyledButton.module.scss';

// We can inject some CSS into the DOM.
const styles = {
    root: {
        fontFamily: 'Moderat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '100%',
        display: 'flex',
        letterSpacing: '0.03em',
        color: '#101010',
        height: 58,
        boxShadow: '0 3px 5px 2px rgba(128, 128, 128, .3)',
        padding: '20px 40px',
        gap: '10px',
        minWidth: '120px',
        background: '#FFFDF9',
        border: '1px solid #FFFDF9',
        borderRadius: 0,
        '& span': {
            fontFamily: 'inherit',
        },
        '&:hover': {
            background: '#FFFDF9',
        }
    },
    outlined: {
        fontFamily: 'Moderat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '100%',
        display: 'flex',
        letterSpacing: '0.03em',
        color: '#ffffff',
        height: 58,
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '20px 40px',
        gap: '10px',
        minWidth: '120px',
        background: 'transparent !important',
        border: '1px solid #FFFDF9',
        borderRadius: 0,
        '& span': {
            fontFamily: 'inherit'
        }
    },
    tertiary: {
        fontFamily: 'Moderat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '100%',
        display: 'flex',
        letterSpacing: '0.03em',
        color: '#FFFDF9',
        height: 58,
        padding: '15px 75px',
        gap: '10px',
        minWidth: '120px',
        background: '#101010',
        border: '1px solid #101010',
        borderRadius: 0,
        '& span': {
            fontFamily: 'inherit',
        },
        '&:hover': {
            background: '#101010',
        }
    }
};

function StyledButton(props) {
    const { classes, children, className, outlined, tertiary, rounded, roundedInverse, roundedInversePlain,  ...other } = props;

    return (
        <Button className={clsx(
            outlined ? classes.outlined : 
                tertiary ? classes.tertiary : 
                    rounded ? styleClasses.styledButton__rounded :
                        roundedInverse ? styleClasses.styledButton__roundedInv :
                            roundedInversePlain ? styleClasses.styledButton__roundedInvPlain :
                                classes.root, className)} {...other}>
            {children || 'class names'}
        </Button>
    );
}

StyledButton.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(StyledButton);
