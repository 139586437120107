import { EXP_API_BASE_URL, EXP_API_KEY, EXP_API_PUBLIC_BASE_URL, EXP_API_KEY_PUBLIC } from '../Shared/Configs';
import { PractitionerServiceEndpoints } from './HttpConstants';


export async function fetchPractitioners() {
    const data = fetch(`${EXP_API_PUBLIC_BASE_URL}${PractitionerServiceEndpoints.ListPractitionerDetails}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY_PUBLIC
            },
        }
    )
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => {
            console.error('Error:', error);
        });
    return data;
}

export async function fetchPractitionerAvailability(params) {
    const data = fetch(
        `${EXP_API_PUBLIC_BASE_URL}${PractitionerServiceEndpoints.PractitionerAvailability}${params.id}?PractitionerId=${params.id}&Timezone=${params.timeZone}&From=${params.from}&To=${params.to}&appointmentcode=${params.appointmentCode}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY_PUBLIC
            },

        })
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => {
            console.error('Error:', error);
        });
    return data;
}

export async function fetchPractitionersAvailability(params) {
    let fetchEndpoint;
    if (params.id && params.id !== '0')
        fetchEndpoint = `${EXP_API_PUBLIC_BASE_URL}${PractitionerServiceEndpoints.PractitionerAvailability}${params.id}?PractitionerId=${params.id}&Timezone=${params.timeZone}&From=${params.from}&To=${params.to}&appointmentcode=${params.appointmentCode}`;
    else
        fetchEndpoint = `${EXP_API_PUBLIC_BASE_URL}${PractitionerServiceEndpoints.PractitionersAvailability}?Timezone=${params.timeZone}&From=${params.from}&To=${params.to}&appointmentcode=${params.appointmentCode}`;
 
    const data = fetch(
        fetchEndpoint,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY_PUBLIC
            },

        })
        .then((response) => response.json())
        .then((result) => result)
        .catch((error) => {
            console.error('Error:', error);
        });
    return data;
}

export async function bookTempAppointment(tokenId, params) {
    const data = fetch(`${EXP_API_BASE_URL}${PractitionerServiceEndpoints.BookTempAppointment}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            },
            body: JSON.stringify(params)
        }
    );
    return data;
}
