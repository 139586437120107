import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from '@material-ui/core';
import StyledButton from '../../components/StyledButton';
import UPLoader from '../../Shared/UPLoader';
const UPModal = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.toggleAlert()}
            aria-labelledby="emergency-dialog-title"
            aria-describedby="emergency-dialog-description"
            PaperProps={{
                style: {
                    backgroundColor: '#000000',
                    boxShadow: 'none',
                    borderColor: '#ffffff',
                    borderStyle: 'solid',
                    borderWidth: '1px'
                },
            }}
        > 
            {   props.onSuccessText &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 36 }}>
                            <Typography style={{margin: 24}}>{ props.onSuccessText }</Typography> 
                            <StyledButton type="button" roundedInverse onClick={() => props.toggleAlert()}>Close</StyledButton>
                        </Box> 
            }
            {
                !props.onSuccessText && 
                <form action="/" method="POST" onSubmit={(e) => { props.onSubmit(e); } }>
                    <DialogTitle style={{ color: '#0000000' }}>{props.title}</DialogTitle>
                    <DialogContent>
                        {props.children}
                    </DialogContent>
                    <DialogActions>
                        { props.onLoading && 
                        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center'}}>
                            <UPLoader position='relative' opacity='1' height='28px' containerHeight='28px' marginBottom='24px'/>
                        </Box>
                        }
                        { !props.onLoading && 
                         <StyledButton type="button" roundedInverse onClick={() => props.toggleAlert()}>Close</StyledButton>
                         
                        }
                        {
                            !props.onLoading && props.submitButtonText &&
                     <StyledButton type="submit" rounded autoFocus >{props.submitButtonText}</StyledButton>
                        }
                    </DialogActions>
                </form >
            }
        </Dialog>
    );
};

export default UPModal;