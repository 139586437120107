export const PIN_PAYMENTS_PUBLISHABLE_API_KEY = process.env.REACT_APP_PIN_PAYMENTS_PUBLISHABLE_API_KEY;
export const PIN_PAYMENTS_URL = process.env.REACT_APP_PIN_PAYMENTS_URL;
export const CUSTOMER_MICROSERVICE_BASE_URL = process.env.REACT_APP_CUSTOMER_MICROSERVICE_BASE_URL;
export const EXP_API_BASE_URL = process.env.REACT_APP_EXP_API_BASE_URL;
export const EXP_API_KEY = process.env.REACT_APP_EXP_API_KEY;
export const COUNTRY = 'AU'; // UP @ Home Registration is only available in Australia at the moment.
export const EXP_API_EVENTS_URL = process.env.REACT_APP_EXP_API_EVENTS_URL;
export const EXP_API_EVENTS_KEY = process.env.REACT_APP_EXP_API_EVENTS_KEY;
export const EXP_API_PUBLIC_BASE_URL = process.env.REACT_APP_EXP_API_PUBLIC_BASE_URL;
export const EXP_API_KEY_PUBLIC = process.env.REACT_APP_EXP_API_KEY_PUBLIC;
export const MY_ACCOUNT_URL = process.env.REACT_APP_MY_ACCOUNT_URL;
