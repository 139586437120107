import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Box, CircularProgress, Typography, withStyles } from '@material-ui/core';

import AnimatedTickMark from '../Shared/AnimatedTick/AnimatedTickMark';
import { QueryParameterNames, AUTH_ACCESS_METHOD, OrgProductId, VpaProductId } from '../Shared/Constants';
import { calculateRecommendation } from './BriefAssessment/BriefAssessmentService';
import { getSubscriptionDetails } from '../HttpHelpers/CustomerServiceHttpHelper';
import { programList } from './RecommendedProgram/program-list';
import { AppStateContext } from '../Shared/UpAppStateProvider';
import { joinProgramWithSubscription } from './Subscription/SubscriptionServices';

let interval;

const useStyles = () => ({
    root: {
        position: 'relative',
        height: '70vh',
        maxWidth: '960px',
        margin: 'auto'
    },
    timer: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '64px',
        lineHeight: '70px',
    }
});

class CalibrationWheel extends Component {
    static contextType = AppStateContext;
    state = {
        programName: null,
        recommendedProgram: null,
        progress: 0,
        flow: null,
        programList: programList, 
        errorMessage: null
    };

    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const searchParams = new URLSearchParams(searchQuery);
        const programName = searchParams.get(QueryParameterNames.P) ?? '';
        const flow = searchParams.get(QueryParameterNames.F) ?? '';

        interval = setInterval(() => {
            this.handleProgress().then((ready) => {
                if(!ready) {
                    return;
                }

                clearInterval(interval);
            });
        }, 50);

        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            this.goNextStep();
        }, (50 * 100) + 2000);

        this.setState({ programName, flow });
        this.calculateProgramRecommendation(programName, flow);
    }

    componentWillUnmount() {
        clearInterval(interval);
    }

    handleProgress() {
        return new Promise((resolve) => {
            this.setState((prevState) => {
                const progress = prevState.progress >= 100 ? 100 : prevState.progress + 1;
                resolve(progress >= 100);
                return { progress };
            });
        });
    }

    calculateProgramRecommendation(programName, flow) {
        const formData = this.props.customerDetails?.briefAssessmentForm;
        const recommendationResult = calculateRecommendation(formData, programName);
        if (recommendationResult.errorMessages && recommendationResult.errorMessages.length > 0)
        {
            this.showFormError(recommendationResult.errorMessages.join('\n'));
            return;
        }
        if (flow && flow === AUTH_ACCESS_METHOD.BOOK_NOW)
        {
            recommendationResult.recommendedPlan = VpaProductId.VPA1; //override recommendation to VPA
        }
        if (flow && flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION)
        {
            getSubscriptionDetails('All', this.props.customerDetails.organisation.partner)
                .then(res => res.json())
                .then((subscriptionData) => {
                    if(!subscriptionData) {
                        return;
                    } else if(subscriptionData?.errorMessage) {
                        this.showError(subscriptionData.errorMessage);
                    } else if(subscriptionData?.length > 0) {
                        const genericOrgDailyWorkout = this.state.programList.find(p => p.id === OrgProductId.Generic);
                        let subscription = subscriptionData[0];
                        let programSubscription = [{
                            id : subscription.id.toLowerCase(),
                            description: genericOrgDailyWorkout.description,
                            features: genericOrgDailyWorkout.features,
                            subscription: subscription,
                            planDetails: genericOrgDailyWorkout.planDetails,
                            isRecommended: true
                        }];
                        recommendationResult.recommendedPlan = subscription.id.toLowerCase();
                        this.context.setSubscriptions(programSubscription);
                        this.setState({recommendedProgram: recommendationResult.recommendedPlan});
                    } else if(subscriptionData?.length === 0) {
                        this.showError('All program returns 0 subscription');
                    }
                });
        }
        else
        {           
            getSubscriptionDetails('All')
                .then(res => res.json())
                .then((subscriptionData) => {
                    if(!subscriptionData) {
                        return;
                    } else if(subscriptionData?.errorMessage) {
                        this.showError(subscriptionData.errorMessage);
                    } else if(subscriptionData?.length > 0) {
                        const programSubscription = joinProgramWithSubscription(subscriptionData, recommendationResult, this.state.programName);
                        this.context.setSubscriptions(programSubscription);
                        this.setState({recommendedProgram: recommendationResult.recommendedPlan});
                    } else if(subscriptionData?.length === 0) {
                        this.showError('All program returns 0 subscription');
                    }
                });
        }

    }


   
    goNextStep() {
        if (this.state.recommendedProgram && this.state.recommendedProgram?.length > 0)
            this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}&r=${this.state.recommendedProgram}&f=${this.state.flow}`));
        else {
            setTimeout(() => {
                this.goNextStep();
            }, 2000);
        }
    }

    render() {
        return (<Box className={this.props.classes.root}>
            <Box style={{ maxWidth: '700px', alignContent: 'center', margin: 'auto', padding: '16px' }}>
                <Typography variant='h5'>Thank you, we are now reviewing your health and wellbeing assessment.</Typography>
                <Box position="relative" display="inline-flex" style={{marginTop: 50, marginBottom: 50}}>
                    <CircularProgress style={{ width: '200px', height: '200px', color: 'white' }} thickness={1} value={100} variant='determinate' />
                    <CircularProgress style={{ width: '200px', height: '200px', color: '#a3f89b', position: 'absolute' }} thickness={1} value={this.state.progress} variant='determinate' />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography className={this.props.classes.timer} variant="h3" component="div" style={{ color: '#FFF', fontFamily:'Recoleta' }}>{`${Math.round(
                            this.state.progress
                        )}%`}</Typography>
                    </Box>
                </Box>
                <Box style={{ margin: '0 auto', width: 310 }}>
                    <Box display='flex' >
                        <AnimatedTickMark progress={this.state.progress >= 20} />
                        <Typography 
                            variant="body2" style={{  padding: '15px', paddingBottom: '0px' }} align="left">
                            Reviewing your profile
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <AnimatedTickMark progress={this.state.progress >= 40} />
                        <Typography
                            variant="body2" style={{  padding: '15px', paddingBottom: '0px' }} align="left">
                            Applying insights
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <AnimatedTickMark progress={this.state.progress >= 60} />
                        <Typography 
                            variant="body2" style={{ padding: '15px', paddingBottom: '0px' }} align="left">
                            Reviewing goals
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <AnimatedTickMark progress={this.state.progress >= 80} />
                        <Typography 
                            variant="body2" style={{ padding: '15px', paddingBottom: '0px' }} align="left">
                            Reviewing programs
                        </Typography>
                    </Box>
                    <Box display='flex'>
                        <AnimatedTickMark progress={this.state.progress >= 100} />
                        <Typography 
                            variant="body2" style={{ padding: '15px', paddingBottom: '0px' }} align="left">
                            Finalising recommendations
                        </Typography>
                    </Box>
                </Box>
                {
                    this.state.errorMessage &&
                    <Box style={{}}>
                        {this.state.errorMessage}
                    </Box>
                }
            </Box>
        </Box>);
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(withStyles(useStyles)(CalibrationWheel));
