import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as SvgImage } from './tracking.svg';
const Tracking = ({ iconColor, style, ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 34 35" fill="none" style={{ width: '34px', height: '35px', ...style }}>
            <SvgImage/>
        </SvgIcon>
    );
};

export default Tracking;