import React from 'react';
import './splash-screen.css';
import logo from '../../Assets/Universal_Practice_CMYK_White-300-100.png';

export default function LoadingScreen() {
    return (
        <div className="splash-screen">
            <img className="img" src={logo} alt="splash_screen" />
            <p className="para">
                Sorry we're down for scheduled maintenance right now.
            </p>
            <div className="loading">...</div>
        </div>
    );
}
