import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import RegisterReducer from './Reducers/RegisterReducer';
import { CustomerDetailsReducer } from './Reducers/CustomerDetailsReducer';
import { PractitionerReducer } from './Reducers/PractitionerReducer';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    register: RegisterReducer,
    customerDetails: CustomerDetailsReducer,
    practitioner: PractitionerReducer
}
);

export default rootReducer;
