import { makeStyles, Select } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
        background: '#1E1E1E',
        color: '#FFFFFF',
        border: '1px solid #FFFFFF !important',
        borderRadius: 0,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'left',
        padding: '20px 15px',
        gap: '40px',
        width: '282px',
        height: '56px'
    }
}));

export default function UPSelect({ className, children, ...props }) {
    const classes = useStyles();
    return (
        <Select {...props} className={clsx(classes.root, className)} variant="outlined">{children}</Select>
    );
}
