import React, { Component } from 'react';

import { Loading } from './Loading';

import logo from '../../Assets/Universal_Practice_CMYK_White-300-100.png';

import './splash-screen.css';



function SplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }
        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 2500);
            } catch(err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            if(this.state.loading) return <Loading logo={logo} />;

            return <WrappedComponent {...this.props} />;
        }
    };
}

export default SplashScreen;
