import {  ExperienceApiEndpoints} from './HttpConstants';
import { LocalStorageVariables, CRMFormNames } from '../Shared/Constants';
import { EXP_API_BASE_URL, EXP_API_KEY, EXP_API_PUBLIC_BASE_URL, EXP_API_KEY_PUBLIC } from '../Shared/Configs';

const getFormTemplates = async (patientFormTemplateId) => {
    return callFormTemplatesEndPoint(patientFormTemplateId);
};

const getBriefAssessmentForm = async (patientFormTemplateId) => {
    return callGetFormEndPoint(patientFormTemplateId);
};

const getRiskAssessmentForm = async (patientFormTemplateId, token) => {
    return callGetFormEndPoint(patientFormTemplateId, token);
};

const getRiskAssessmentFormByName = async (token) => {
    return callGetDraftFormEndPoint(CRMFormNames.RISK_ASSESSMENT, token);
};

const createRiskAssessmentForm = async (patientId, patientFormTemplateId, token) => {
    const data = { patientFormTemplateId };
    return callCreateFormEndPoint(data, token);
};

const saveBriefAssessment = async (patientForm, token) => {
    let createFormResult = null;
    if (!patientForm.id)
    {
        const templates = JSON.parse(localStorage.getItem(LocalStorageVariables.Templates));
        const template = templates.find((form) => form.name === patientForm.templateName);
        const data = { patientFormTemplateId: template.id };
        createFormResult = await callCreateFormEndPoint(data, token);
        const creationJson = await createFormResult.json();
        if (!creationJson.isSuccess)
            throw 'Error when creating brief assessment form';
        patientForm.id = creationJson.result;
    }
    return callSavePatientFormEndPoint(patientForm, token);
};

const saveRiskAssessment = async (patientForm, token) => {
    return callSavePatientFormEndPoint(patientForm, token);
};

const callFormTemplatesEndPoint = () => {
    return fetch(
        `${EXP_API_PUBLIC_BASE_URL}${ExperienceApiEndpoints.PatientFormTemplates}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY_PUBLIC
            }
        }
    );
};

const callGetFormEndPoint = (patientFormTemplateId) => {
    return fetch(
        `${EXP_API_PUBLIC_BASE_URL}${ExperienceApiEndpoints.PatientFormTemplate}/${patientFormTemplateId}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY_PUBLIC
            }
        }
    );
};

const callCreateFormEndPoint = (data, tokenId) => {
    return fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.PatientForm}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            },
            body: JSON.stringify(data),
        }
    );
};

const callSavePatientFormEndPoint = (patientForm, tokenId) => {
    return fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.PatientForm}`,
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            },
            body: JSON.stringify(patientForm),
        }
    );
};

const callGetDraftFormEndPoint = (patientFormType, tokenId) => {
    return fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.PatientForm}/name/${patientFormType}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${tokenId}`
            }
        }
    );
};

export {
    getFormTemplates,
    getBriefAssessmentForm,
    saveBriefAssessment,
    getRiskAssessmentForm,
    createRiskAssessmentForm,
    saveRiskAssessment,
    getRiskAssessmentFormByName
};