import { CognitoUserPool, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import awsCognitoConfig from '../aws-cognito-config.json';

export const cognitoAutoLogin = (cognitoData) => {
    const userPool = new CognitoUserPool({
        UserPoolId: awsCognitoConfig.aws_user_pools_id,
        ClientId: awsCognitoConfig.aws_user_pools_web_client_id,
        Storage: sessionStorage
    });
  
    const cognitoIdToken = new CognitoIdToken({
        IdToken: cognitoData.idToken,
    });
  
    const cognitoAccessToken = new CognitoAccessToken({
        AccessToken: cognitoData.accessToken,
    });
  
    const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: cognitoData.refreshToken,
    });
  
    const username = cognitoIdToken.payload.email;
  
    const user = new CognitoUser({
        Username: username,
        Pool: userPool,
        Storage: sessionStorage
    });
  
    const cognitoUserSession = new CognitoUserSession({
        AccessToken: cognitoAccessToken,
        IdToken: cognitoIdToken,
        RefreshToken: cognitoRefreshToken,
    });
  
    user.setSignInUserSession(cognitoUserSession);
};