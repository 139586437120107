import { CRMFormTypes } from '../Shared/Constants';

export const clone = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const formatCRMFormData = (data, defaultFields) => {
    const content = (data?.formTemplateContent || data?.content) ?? null;
    if(!content) {
        return [];
    }

    return content?.sections.map((section) => {
        section.questions = section.questions.map((question) => {
            const defaultVal = defaultFields?.[question?.type]?.[removeSpecialCharacters(question?.name).toLowerCase()] || '';
            if(defaultVal) {
                const index = question?.answers?.findIndex((q) => q.value.toLowerCase() === defaultVal.default.toLowerCase());
                if(index > -1) {
                    question.answers[index] = { ...question?.answers[index], selected: true };
                }
            }

            if(question.type === CRMFormTypes.TEXT || question.type === CRMFormTypes.PARAGRAPH) {
                const quest = { ...question };
                delete quest.answers;
                return { ...quest, answer: quest?.answer || undefined };
            }

            return question;
        });

        return section;
    });
};

export const removeSpecialCharacters = (str) => {
    if(!str) {
        return '';
    }

    return str.replace(/[^a-zA-Z0-9]/g, '');
};

export const getFormConditions = (conditions, sections) => {
    if(!conditions) {
        return {};
    }

    const answerMap = {};
    (sections || []).forEach((section) => {
        (section.questions || []).forEach((question) => {
            if(question.type !== CRMFormTypes.RADIOBUTTONS) {
                return;
            }

            const cleanedQuestion = removeSpecialCharacters(question?.name)?.toLowerCase();
            const answer = (question?.answers || []).find((x) => x.selected);
            answerMap[cleanedQuestion] = answer?.value;
        });
    });

    const conditionsMap = {};
    Object.keys(conditions).forEach((key) => {
        conditionsMap[removeSpecialCharacters(key)?.toLowerCase()] = {
            source: removeSpecialCharacters(conditions[key].source)?.toLowerCase(),
            replaceText: 'pain'
        };
    });

    return { answers: answerMap, conditions: conditionsMap };
};


export const textCapitalize = (str) => {
    if(!str || typeof str !== 'string') {
        return str;
    }

    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};