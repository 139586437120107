import { RegisterActions } from '../Actions/RegisterActions';

const initialValue = {
    auth: {
        state: RegisterActions.SIGN_UP,
        user: {},
        error: null,
        message: null,
        isAuthenticated: false
    },
    forgotPassword:{
        email:''
    }
};

export default function RegisterReducer(state = {
    ...initialValue
}, action) {
    if (!Object.values(RegisterActions).includes(action.type)) return state;

    let auth = state.auth;
    let forgotPassword={
        email:''
    };

    if (action.type === RegisterActions.SIGNED_IN) {
        auth = action.payload.auth;
    }
    if (action.type === RegisterActions.FORGOT_PASSWORD) {
        forgotPassword = {email: action.payload.forgotPassword.email};
    }

    return Object.assign({}, state,
        { auth: auth, forgotPassword: forgotPassword});
}
