export const HAVE_PAIN_QUESTION = 'Do you have pain or any active areas of concern?';
export const PRIMARY_PAIN_QUESTION = 'Please show us where your pain or primary concern is?';
export const AGE_QUESTION = 'What is your age?';
export const FIRST_PAIN_ON_QUESTION = 'When did you first experience your pain?';
export const HEALTH_ISSUES_PREVENTING_EXERCISE_QUESTION = 'Do you have any other health conditions or areas of pain that limit you from participating in exercise?';
export const CAPACITY_QUESTION = 'How would you rate your capacity for exercise and physical activity?';
export const PAIN_LEVEL_QUESTION = 'What is your pain out of 10?';
export const CHRONICITY_LIMIT = 'More than 3 months ago';
export const PRIMARY_CONCERN_TYPE_QUESTION = 'Which word best describes your primary concern?';
export const PREGNANCY_DUE_DATE_QUESTION = 'When is your baby due? If you have delivered, please enter your baby\'s birth date.';
export const PREGNANCY_CAN_EXERCISE = 'Understanding more about your health, helps us to ensure your treatment is both specific and safe for you. Do you have any of the following conditions or presentations?';

export const INFO_TEXTS =
    {   
        [HAVE_PAIN_QUESTION]: {
            'No': { 
                text: 'That\'s great! As you are not experiencing any pain, we would recommend our Daily Workout stream. It\'s designed to build stability, control, strength, power and mobility. Please visit our <a href=\'https://virtual.universalpractice.com.au/conditions/daily-workouts\' class=\'upform__link\'>Daily Workout Program</a>.',
                allowContinue: false                
            }
        }
    }
;

export const briefAssessmentFormConditions = {
    'What is your pain out of 10?': {
        source: 'Which word best describes your primary concern',
        replaceText: 'pain'
    },
    'When did you first experience your pain?': {
        source: 'Which word best describes your primary concern',
        replaceText: 'pain'
    }
};

export const briefAssessmentFormDefaults = {
    radiobuttons: {
        'pleaseshowuswhereyourpainis': {
            default: 'Lower Back'
        }, // Question name without space or special characters, don't remove numbers if any
    }
};

export const briefAssessmentSliderMap = {
    'whatisyourpainoutof10': {  // Question name without space or special characters, don't remove numbers if any
        0: { label: 'Pain Free', color: '#e4fbfa' },
        1: { label: 'Very Mild', color: '#b2fcff' },
        2: { label: 'Discomforting', color: '#7dffb0' },
        3: { label: 'Tolerable', color: '#45ff5d' },
        4: { label: 'Distressing', color: '#bbff54' },
        5: { label: 'Very Distressing', color: '#f1ff00' },
        6: { label: 'Intense', color: '#fccf00' },
        7: { label: 'Very Intense', color: '#ffa500' },
        8: { label: 'Utterly Horrible', color: '#ff6400' },
        9: { label: 'Excruciating Unbearable', color: '#ff2c00' },
        10: { label: 'Unimaginable Unspeakable', color: '#e90000' },
    }
};


export const CAPACITY_ANSWER_MAP = {
    'I can walk around the block': { value:  1, conclusion: 'Low'} ,
    'I can walk up and down 3 flights of stairs': { value:  1, conclusion: 'Moderate'} ,
    'I can power walk for an hour': { value:  1, conclusion: 'Moderate'} ,
    'I can go to the gym or go for run' : { value: 2, conclusion: 'High'} ,
    'I can run and quickly change direction': { value: 2, conclusion: 'High'} ,
    'I am serious about fitness or play competitive sport': { value: 2, conclusion: 'High'} ,
};

export const CHRONICITY_ANSWER_MAP = {
    'More than 3 months ago':  { conclusion: 'Chronic' },
    'In the last 3 months': { conclusion: 'Subacute' },
    'In the last 7 days': { conclusion: 'Acute' },
};


export const PAIN_AREAS = {
    LOWER_BACK: {briefAssessmentValue: 'lower back', abbreviation: 'lbp', productIds: {
        Rehab: 'unipr_lbp_onetime_au001',
        Strength1: 'unipr_lbp_onetime_au002',
        Strength2: 'unipr_lbp_onetime_au003',
    }},
    HIP: {briefAssessmentValue: 'hip', abbreviation: 'hip', productIds: {
        Rehab: 'unipr_hip_onetime_au001',
        Strength1: 'unipr_hip_onetime_au002',
        Strength2: 'unipr_hip_onetime_au002',
    }},
    NECK: {briefAssessmentValue: 'neck', abbreviation: 'nck', productIds: {
        Rehab: 'unipr_nck_onetime_au001',
        Strength1: 'unipr_nck_onetime_au002',
        Strength2: 'unipr_nck_onetime_au002',
    }},
    UPPER_BACK: {briefAssessmentValue: 'upper back', abbreviation: 'upb', productIds: {
        Rehab: 'unipr_upb_onetime_au001',
        Strength1: 'unipr_upb_onetime_au001',
        Strength2: 'unipr_upb_onetime_au001',
    }},
    KNEE: {briefAssessmentValue: 'knee', abbreviation: 'kne', productIds: {
        Rehab: 'unipr_kne_onetime_au001',
        Strength1: 'unipr_kne_onetime_au002',
        Strength2: 'unipr_kne_onetime_au002',
    }},
    SHOULDER: {briefAssessmentValue: 'shoulder', abbreviation: 'shl', productIds: {
        Rehab: 'unipr_shl_onetime_au001',
        Strength1: 'unipr_shl_onetime_au002',
        Strength2: 'unipr_shl_onetime_au002',
    }},
    // FOOT: {briefAssessmentValue: 'foot', abbreviation: 'fot', productIds: {
    //     Rehab: 'unipr_fot_onetime_au001',
    //     Strength1: 'unipr_fot_onetime_au002',
    //     Strength2: 'unipr_fot_onetime_au003',
    // }},
    // ANKLE: {briefAssessmentValue: 'ankle', abbreviation: 'ank', productIds: {
    //     Rehab: 'unipr_ank_onetime_au001',
    //     Strength1: 'unipr_ank_onetime_au002',
    //     Strength2: 'unipr_ank_onetime_au003',
    // }}
};
