import React from 'react';
import { useField } from 'formik';
import StyledTextField from '../components/StyledTextField';
//useful to have a different component for the payment
//text inputs because they can receive validation
//externally from pin payments as well

export default function MyDNATextInput(props) {
    const [field, meta] = useField(props.name);

    function getErrorText() {
        if (meta.error && meta.touched) return meta.error;
        if (props.validationErrorText) return props.validationErrorText;

        return '';
    }

    function handleChange(name, e, setFieldValue) {
        setFieldValue(name, e.target.value);
    }
    return (
        <StyledTextField
            field={field}
            type={props.type?? 'text'}
            title={props.label}
            helperText={getErrorText()}
            error={!!getErrorText()}
            inputProps={{ ...props.inputProps, maxLength: '10' }}
            name={props.name}
            label={props.label}
            value={props?.value}
            className="input-control"
            onChange={ (e)=>{
                handleChange(props.name, e, props.setFieldValue);
            }}
            helperStyle={props.helperStyle || {}}
        />
    );
}
