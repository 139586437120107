/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Box } from '@material-ui/core';


const SliderBoxShadow = '0px 0px 0px 8px rgba(255,255,255,0.5)';
const StyledSlider = withStyles({
    root: {
        color: '#ffffff',
        height: 2,
        padding: '0px',
        margin: '0px',
        marginTop: '3rem',
        width: '100%'
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: (props) => props['data-color'] ? props['data-color'] : '#ffffff',
        marginTop: -10,
        marginLeft: -10,
        '&:focus, &:hover, &$active': {
            boxShadow: SliderBoxShadow,
        },
    },
    active: {
    },
    valueLabel: {
        left: 'calc(-50% + 3px)',
        top: -32,
        '& *': {
            background: 'transparent',
            color: (props) => props['data-color'] ? props['data-color'] : '#ffffff',
        },
    },
    track: {
        height: 2,
        color: (props) => props['data-color'] ? props['data-color'] : '#ffffff',
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#f2f2f2',
    },
    mark: {
        backgroundColor: '#f2f2f2',
        height: 16,
        width: 1,
        marginTop: -8,
    },
    markLabel: {
        color: '#ffffff',
        marginTop: 5
    },
    markActive: {
        opacity: 1,
        backgroundColor: (props) => props['data-color'] ? props['data-color'] : '#ffffff'
    }
})(Slider);

/**
 * Demos:
 * - [Slider](https://mui.com/components/slider/)
 *
 * API:
 * - [Slider API](https://mui.com/api/slider/)
 */
export const UPSlider = ({ value: val, onChange, labelMap, ...props }) => {
    const [value, setValue] = useState(val && !isNaN(val) ? val : 0);
    const [color, setSetColor] = useState();

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const handleLabelMap = (value) => {
        if(!labelMap || !Object.keys(labelMap).length || !labelMap?.[value]) {
            return value;
        }

        if(typeof labelMap[value] === 'string') {
            return labelMap[value];
        }

        setSetColor(labelMap[value]?.color);
        return <Box sx={{ color: `${labelMap[value]?.color} !important` }}>{labelMap[value]?.label}</Box>;
    };

    return (
        <StyledSlider
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            {...props}
            step={props.step}
            defaultValue={props.min}
            min={props.min}
            max={props.max}
            value={value}
            valueLabelFormat={handleLabelMap}
            onChange={handleChange}
            data-color={color}
        />
    );
};
