import { Box, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { UPToggleButtonGroup, UPToggleButton } from '../components/StyledToggleButtonGroup';
import moment from 'moment-timezone';

const useStyles = makeStyles(() => ({
    timeSlot: { display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto', maxHeight: '200px' },
    timeSlotTitle: { color: '#FFFFFF', fontSize: '18px' }
}));

export default function PractitionerTimeSlots(props) {
    const classes = useStyles();
    const [appointment, setAppointment] = useState();
    const [displayDates, setDisplayDates] = useState({ morning: [], afternoon: [], evening: [] });

    useEffect(() => {
        if(!props.appointmentTime.length) {
            setAppointment(null);
            return;
        }

        setDisplayDates(formatDates(props.appointmentTime));
    }, [props.appointmentTime]);

    const handleChange = (event, time) => {
        setAppointment(time);
        props.handleAppointmentTime(time);
    };
   
    const formatDates = (appointmentTime) => {
        const morning = [];
        const afternoon = [];
        const evening = [];

        appointmentTime.forEach((time) => {
            if(Number(time.twentyFourHr) < 12) {
                morning.push(time.formatted);

            } else if(Number(time.twentyFourHr) < 18) {
                afternoon.push(time.formatted);
            } else {
                evening.push(time.formatted);
            }
        });

        return { morning, afternoon, evening };
    };

    return (
        <Card style={{ color: '#FFFFFF', backgroundColor: '#000000' }}>
            <CardContent style={{ padding: 0, margin: 0 }}>
                {/* {props.appointmentTime.length > 0 && <Typography variant="h6" style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Available appointments</Typography>} */}
                <Box sx={{ m: 0, color: '#000000', minHeight: '100px' }}>
                    {!props.appointmentTime.length ?
                        <Typography variant="subtitle1" style={{ color: '#FFFFFF', textAlign: 'left' }} >
                            {`No appointments available for ${moment(props.selectedDate).format('DD MMM yyyy')}. Please select different date from calendar.`}
                        </Typography> :
                        <Grid container spacing={2}>
                            <Grid item xs={4} md={4}>
                                <Typography variant="h6" className={classes.timeSlotTitle}>Morning</Typography>
                                <UPToggleButtonGroup
                                    value={appointment}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="text alignment"
                                    orientation="vertical"
                                    className={`${classes.timeSlot} scroll-bar`}
                                >
                                    {displayDates.morning.map((time, index) => (<UPToggleButton value={time} aria-label={time} key={index} > {time}</UPToggleButton>))}
                                </UPToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4} md={4} direction='column'>
                                <Typography variant="h6" className={classes.timeSlotTitle}>Afternoon</Typography>
                                <UPToggleButtonGroup
                                    value={appointment}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="text alignment"
                                    orientation="vertical"
                                    className={`${classes.timeSlot} scroll-bar`}
                                >
                                    {displayDates.afternoon.map((time, index) => (<UPToggleButton value={time} aria-label={time} key={index} > {time}</UPToggleButton>))}
                                </UPToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="h6" className={classes.timeSlotTitle}>Evening</Typography>
                                <UPToggleButtonGroup
                                    value={appointment}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="text alignment"
                                    orientation="vertical"
                                    className={`${classes.timeSlot} scroll-bar`}
                                >
                                    {displayDates.evening.map((time, index) => (<UPToggleButton value={time} aria-label={time} key={index} > {time}</UPToggleButton>))}

                                </UPToggleButtonGroup>
                            </Grid>
                        </Grid>}
                </Box>
            </CardContent>
        </Card>
    );
}
