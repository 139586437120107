export const CustomerServiceEndpoints = {
    UpdateRegistrationDetails: '/UniversalPracticeRegistration/UpdateRegistrationDetails',
    CommitRegistrationDetails: '/UniversalPracticeRegistration/CommitRegistrationDetails',
};

export const PractitionerServiceEndpoints = {
    ListPractitionerDetails: '/api/v1/practitioners',
    PractitionerAvailability: '/api/v1/appointmentavailability/practitioner/',
    PractitionersAvailability: '/api/v1/appointmentavailability/practitioners/',
    BookPractitionerAppointment: '/api/v1/appointment',
    BookTempAppointment: '/api/v1/appointment/book/temp'
};

export const ExperienceApiEndpoints = {
    PatientForm: '/api/v1/patientform',
    PatientFormTemplate: '/api/v1/formtemplate',
    PatientFormTemplates: '/api/v1/formtemplates',
    GetUser: '/api/v1/user',
    GetPatient: '/api/v1/patient',
    SavePatient: '/api/v1/patient',
    ActiveSubscription: '/api/v1/usersubscription/active',
    BillingDetails: '/api/v1/billingdetails',
    SaveToken: '/api/v1/userdata/token'
};


export const EventsApiEndpoints = {
    Events: '/v1/events'
};