import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';


import bodyMap from '../../Assets/body-map.svg';
import bodyPointer from '../../Assets/body-pointer.svg';

const bodyMapping = {
    Head: { x: 49, y: 6 },
    Neck: { x: 49, y: 35 },
    Shoulder: { x: 23, y: 57 },
    Arm: { x: -7, y: 177 },
    'Upper Back': { x: 49, y: 77 },
    Ribs: { x: 216, y: 124 },
    Abdomen: { x: 233, y: 160 },
    'Lower Back': { x: 50, y: 168 },
    Pelvis: { x: 234, y: 195 },
    Hip: { x: 28, y: 180 },
    Knee: { x: 234, y: 297 },
    Ankle: { x: 38, y: 385 },
    Foot: { x: 233, y: 400 },
    Leg: { x: 233, y: 333 }
};
const bodyPoint = (pointerColor) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.6" cx="15.5975" cy="15.5975" r="15.5975" fill={pointerColor}/>
        <circle id="bodymap-pointer" cx="15.5975" cy="15.5979" r="8.6653" fill={pointerColor}/>
    </svg>

);
const Slot = ({ value, pointerColor }) => {
    const position = bodyMapping[value];
    if (!pointerColor)
        pointerColor = '#96FF94';

    return (
        <Box sx={{ height: '422px', width: '314px', margin: 'auto', position: 'relative' }}>
            <img src={bodyMap} alt={'body mapping'} height="100%" width="100%" />
            {(position) && 
            <div style={{ position: 'absolute', left: `${position.x}px`, top: `${position.y}px` }}>
                {bodyPoint(pointerColor)}
            </div>
            }
        </Box>
    );
};

Slot.propTypes = {
    value: PropTypes.string
};

export default Slot;