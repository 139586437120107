import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { SubscriptionGroups, VpaProductId, QueryParameterNames, AUTH_ACCESS_METHOD, SubscriptionTypes } from '../../Shared/Constants';
import RecommendedProgramCardV2 from './RecommendedProgramCardV2';
import { AppStateContext } from '../../Shared/UpAppStateProvider';
import { getPainAreaFromBriefAssessment, getPainAssessmentReport } from '../BriefAssessment/BriefAssessmentService';
import HealthReport from '../RecommendedProgram/HealthReport';
import styleClasses from './RecommendedProgramV2.module.scss';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';
import { getApplicablePageInfo } from '../../Shared/Services/PageService';
import { Constants } from '@aws-amplify/core';

class RecommendedProgramV2 extends Component {
    static contextType = AppStateContext;
    state = { 
        recommended: null, 
        programName: '', 
        plan: '', 
        flow: null, 
        toDisplayProducts: null, 
        painAssessmentReport: null,
        hasExistingSubscription: false,
        existingProductSubscriptionOption: null,
        ctaText: 'Get Started'
    };

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const plan = urlParams.get(QueryParameterNames.R) ?? '';
        const programName = urlParams.get(QueryParameterNames.P) ?? '';
        const flow = urlParams.get(QueryParameterNames.F) ?? '';
        
        if(!plan && !this.props.customerDetails.subscription) {
            this.props.dispatch(push(`/brief-assessment?p=${programName}&f=${flow}`));
            return;
        }
        const subscriptions = this.context.subscriptions;
        if(!subscriptions) {
            this.props.dispatch(push(`/brief-assessment?p=${programName}&f=${flow}`));
            return;
        }
        let ctaText = 'Get Started';
        if (flow && flow === AUTH_ACCESS_METHOD.BOOK_NOW)
            ctaText = 'Book Now';

        const assessmentResult = getPainAssessmentReport(this.props.briefAssessmentForm);
        const recommended = subscriptions.find((program) => program.isRecommended);
        let hasExistingSubscription = false;

        let toDisplayProducts = [];
        //always display Recommended 1st position
        this.addProductToDisplay(toDisplayProducts, recommended);
        
        if (!flow || (flow != AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION && flow !== AUTH_ACCESS_METHOD.BOOK_NOW))
        {       
            //add program product
            const painArea = getPainAreaFromBriefAssessment(this.props.briefAssessmentForm);
            if (painArea)
            {
                const painAreaProductIds = painArea.productIds;
                
                const rehabProgram = subscriptions.find((prog) => prog.id === painAreaProductIds.Rehab);                
                hasExistingSubscription = this.props.auth.user && this.props.auth.user.activeSubscription && rehabProgram && 
                this.props.auth.user.activeSubscription.subscriptionOption === rehabProgram.subscription.subscriptionOption;

                if (hasExistingSubscription) {
                    const activeSubscription = subscriptions.find((prog) => prog.id === this.props.auth.user.activeSubscription.subscriptionId);
                    if (recommended.subscription.subscriptionOption !== activeSubscription.subscription.subscriptionOption) { 
                        this.addProductToDisplay(toDisplayProducts, recommended);
                    }
                    this.addProductToDisplay(toDisplayProducts, activeSubscription);
                } 

                //Add VPA if the recommended is not VPA
                if (hasExistingSubscription) {
                    //Add VPA
                    if (recommended.subscription.group !== SubscriptionGroups.VPA)
                    {
                        //Add VPA
                        const vpaProductToAdd = subscriptions.find((program) => program.id === VpaProductId.VPA1);
                        this.addProductToDisplay(toDisplayProducts, vpaProductToAdd);
                    }
                } else {
                    if (recommended.subscription.group !== SubscriptionGroups.VPA)
                    {
                        //Add VPA
                        const vpaProductToAdd = subscriptions.find((program) => program.id === VpaProductId.VPA1);
                        this.addProductToDisplay(toDisplayProducts, vpaProductToAdd);
                    } else { //Recommended is VPA then add strength
                        const strengthRecommendation = assessmentResult.painAssessmentReport.capacityQuestion.result === 1 ? painArea.productIds.Strength1 : painArea.productIds.Strength2;
                        this.addProductToDisplay(toDisplayProducts, subscriptions.find((program) => program.id === strengthRecommendation));
                    }    
                }

            }
        }

        let existingProductSubscriptionOption = null;
        if (this.props.auth.user && this.props.auth.user.activeSubscription)
        {
            //For AddOn let the user purchase program which has same type as per current(active) product
            let selectedProgram = subscriptions.filter(s => s.subscription?.group == programName)[0];
            
            //Below is not right, if the person is already have LBP and the recommended is VPA. 
            //Also should cater when the recommendded is LBP. 
            //existingProductSubscription should be per displayed product not a shared variable
            if (selectedProgram.subscription.subscriptionType != SubscriptionTypes.AddOn)    
                existingProductSubscriptionOption = this.props.auth.user?.activeSubscription?.subscriptionOption; 

        }
       
              
        this.setState({ toDisplayProducts, 
            recommended, programName, plan, flow, 
            painAssessmentReport : assessmentResult?.painAssessmentReport, 
            hasExistingSubscription,
            existingProductSubscriptionOption,
            ctaText
        });    
    }

    goNextStep(subscriptionGroup) {      
        //program might have changed
        const groupPageInfo = getApplicablePageInfo(this.state.flow, subscriptionGroup, this.props.router.location.pathname);
        sendLinkClick('Get Started Button', 'Get Started', `${groupPageInfo.currentPage.nextPage}`, window.location.pathname, null);
    
        this.props.dispatch(push(`${groupPageInfo.currentPage.nextPage}?p=${subscriptionGroup}&f=${this.state.flow}`));
    }

    addProductToDisplay(currentProductToDisplay, subscription) {
        if (!subscription)
            return;
        const foundSubscription = currentProductToDisplay.find(x => x.id === subscription.id);
        if (!foundSubscription) {
            currentProductToDisplay.push(subscription);
        }
    }

    render() {
       
        return (
            <Box sx={{ maxWidth: 1280, display: 'flex', justifyContent: 'center', margin: 'auto', flexDirection: 'column' }}>
                <Box style={{marginTop: 50, marginBottom: 50 }}>
                    {
                     
                        <Typography align='center' variant='h5' style={{fontSize: 36, fontFamily: 'Recoleta'}}>
                        Your Health Report</Typography>
                    }
                </Box>
                <Box style={{marginBottom: 50 }}>
                    {
                        <Typography align='center' variant='h5' style={{fontSize: 22}}>Thank you for taking the time to tell us about yourself.<br/>Here is what we learned.</Typography>
                    }
                </Box>
                {
                    this.state.painAssessmentReport &&
                    <Box style={{marginBottom: 50 }}>
                        <HealthReport painAssessmentReport={this.state.painAssessmentReport}></HealthReport>
                    </Box>
                }
                {
                    this.state.toDisplayProducts && this.state.recommended &&
                    this.state.flow && this.state.flow === AUTH_ACCESS_METHOD.BOOK_NOW
                        ?
                        <Grid container direction="row" style={{marginBottom: 50}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Typography align='center' variant='h5'>
                                    Our <Typography variant='h5' component='span' className={styleClasses.recommendedProgramText} >Online Physiotherapy Appointment</Typography> is a safe and effective solution for your clinical needs and presentation.
                                </Typography>
                            </Grid>
                        </Grid>
                        : this.state.toDisplayProducts && this.state.recommended && 
                            <Grid container direction="row" style={{marginBottom: 50}}>
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    {                            
                                        this.state.hasExistingSubscription ?
                                            <Typography align='center' variant='h5'>
                                                Our expert team have matched your specific requirements with best practice medicine and clinical guidelines.<br/>
                                                Our record shows that you already have <Typography variant='h5' component='span' className={styleClasses.recommendedProgramText} >{this.props.auth.user.activeSubscription.name}</Typography>.
                                            </Typography>
                                            :
                                            <Typography align='center' variant='h5'>
                                                Our expert team have matched your specific requirements with best practice medicine and clinical guidelines.<br/>
                                                Our science-based <Typography variant='h5' component='span' className={styleClasses.recommendedProgramText} >{this.state.recommended.subscription.name}</Typography> is recommended for you.
                                            </Typography>
                                    
                                    }       
                                </Grid>
                            </Grid>
                }
                {
                    this.state.toDisplayProducts && this.state.recommended &&
                    <Grid container direction="row" style={{marginBottom: 50, justifyContent: 'center'}}>
                        {
                            this.state.toDisplayProducts.map(p =>
                                <Grid item xs={12} sm={12} md={4} lg={4} key={p.id}>
                                    <RecommendedProgramCardV2 
                                        onClick={(subscriptionGroup) => this.goNextStep(subscriptionGroup)} style={{ marginBottom: '40px' }} 
                                        key={p.id} 
                                        program={p} 
                                        isRecommended={p.id === this.state.recommended.id} 
                                        subscriptionOption={this.state.existingProductSubscriptionOption}
                                        otherProductWithInstalment={this.state.toDisplayProducts.find(x => x.subscription.instalmentPriceId?.length > 0 && x.subscription.numberOfInstalment > 0) !== null}
                                        ctaText={this.state.ctaText}/>
                                </Grid>
                            )
                        }
                    </Grid>
                }
            </Box >
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(RecommendedProgramV2);