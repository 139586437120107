import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxInput(props) {
    const setValue = (event) => {
        props.setFieldValue && props.setFieldValue(props.name, event.target.checked);
        props.onChange(event);
    };
    return (
        <Checkbox
            onChange={event => { setValue(event); }}
            name={props.name}
            style={{ color: 'white', paddingLeft: 0 }}
        />
    );
}
