import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box,  Grid } from '@material-ui/core';
import UPModal from '../../components/UPFormComponents/UPModal';
import styleClasses from './HealthReport.module.scss';
import Slot from '../BriefAssessment/Slot';
import { briefAssessmentSliderMap } from '../../Constants/BriefAssessment';
import StyledButton from '../../components/StyledButton';
import StyledTextField from '../../components/StyledTextField.js';
import { useDispatch, useSelector } from 'react-redux';
import { healthReportCaptured } from '../../Actions/CustomerDetailsActions';
import { callUpdateRegistrationDetails } from '../../HttpHelpers/CustomerServiceHttpHelper';
import { useEffect } from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { EmailRegex, NameRegex } from '../../Shared/RegexLibrary';
import { messages } from '../../Shared/Messages';
const HealthReport = ({ painAssessmentReport }) => {
    const painLevelColor = painAssessmentReport ? briefAssessmentSliderMap.whatisyourpainoutof10[painAssessmentReport.painLevel].color : '#ffffff';
    const [showEmailReport, setShowEmailReport]  = useState(false);
    const [modalOnSuccessText, setModalOnSuccessText]  = useState('');
    const [firstName, setFirstName]  = useState('');
    const [firstNameError, setFirstNameError]  = useState(false);
    const [email, setEmail]  = useState('');
    const [emailError, setEmailError]  = useState(false);
    const [modalOnLoading, setModalOnLoading]  = useState(false);
    
    const customerDetails = useSelector(state => state.customerDetails);
    const register = useSelector(state => state.register);
    const dispatch = useDispatch();
    
    const closeEmailReport = () => {
        setShowEmailReport(!showEmailReport);
    };
    useEffect(() => {
        painAssessmentReport.painLevelColor = painLevelColor;
        if (register && register.auth && register.auth.isAuthenticated)
        {
            const authFirstName = register.auth.user.firstName;
            const authUserName = register.auth.user.username;
            setFirstName(authFirstName);
            setEmail(authUserName);
            dispatch(healthReportCaptured(painAssessmentReport, authFirstName, authUserName));      
        }
        else
            dispatch(healthReportCaptured(painAssessmentReport, firstName, email));       
    }, []);
    useEffect(() => {
        dispatch(healthReportCaptured(painAssessmentReport, firstName, email));
    }, [firstName, email]);

    const emailReport = async (event) => {
        event.preventDefault();
        
        if (firstName && email)
        {   
            var isFirstNameValid = firstName && NameRegex.test(firstName);
            setFirstNameError(!isFirstNameValid);
            if (!isFirstNameValid)
                return;

            var isEmailValid = email && EmailRegex.test(email);
            setEmailError(!isEmailValid);
            if (!isEmailValid)
                return;

            setModalOnLoading(true);

            const authFirstName = register?.auth?.user?.firstName;
            const authUserName = register?.auth?.user?.username;
            const authLastName = register?.auth?.user?.lastName;
           
            const healthReport = customerDetails.healthReport? JSON.stringify(customerDetails.healthReport) : null;
            const registrationDetails = {
                firstName: firstName ? firstName : authFirstName, 
                lastName: authLastName ? authLastName : 'UniversalPracticeUser',
                emailAddress: email ? email : authUserName,
                timeZone: customerDetails.userDetails.timeZone,
                promoCode: customerDetails?.couponDetails?.promoCode ?? null,
                subscriptionCode: customerDetails.subscription?.crmProductCode,
                recurringPaymentPriceId: customerDetails.subscription?.priceId,
                crmPatientId: register?.auth?.user?.patientId,
                healthReport: healthReport,       
                emailReport: true 
            };

            await callUpdateRegistrationDetails(registrationDetails)
                .then((response) => {
                    if(response.ok) {
                        setModalOnSuccessText('Report has been sent');
                    }
                })
                .catch((e) => {
                    console.error(e);
                }).finally(() => {
                    setModalOnLoading(false);
                });
        }
    };
    return (
        <>
            <Grid container direction="row" style={{ marginBottom: 40, backgroundColor: '#000000' }}  id='healthReport'>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box className={styleClasses.healthReport__item}>
                            <Box className={styleClasses.item1}>
                                <Typography variant='h5' component='span' style={{ color: painLevelColor }}>
                                    {painAssessmentReport.painLevel}/10 &nbsp;
                                </Typography>
                                <Typography variant='h5' component='span' color='textPrimary' style={{ textDecoration: 'underline' }}>
                                    {painAssessmentReport.primaryPain.location}  {painAssessmentReport.primaryPain.concernType}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={styleClasses.healthReport__item}>
                            <Box className={styleClasses.item2}>
                                <Typography variant='h5' component='div'>
                                Your {painAssessmentReport.primaryPain.concernType.toLowerCase()} is &nbsp;
                                    <Typography variant='h5' component='span' style={{ textDecoration: 'underline' }}>
                                        {painAssessmentReport.chronicityConclusion}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={styleClasses.healthReport__item}>
                            <Box className={styleClasses.item3}>
                                <Typography variant='h5' component='div'>
                                    <Typography variant='h5' component='span' style={{ textDecoration: 'underline' }}>
                                        {painAssessmentReport.age}
                                    </Typography>
                                &nbsp;years old
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={styleClasses.healthReport__item}>
                            <Box className={styleClasses.item4}>
                                <Typography variant='h5' component='div'>
                                    <Typography variant='h5' component='span' style={{ textDecoration: 'underline' }}>
                                        {painAssessmentReport.capacityQuestion.conclusion} capacity
                                    </Typography> for excercise
                                </Typography>
                            </Box>
                        </Box>
                    </Box>


                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Slot value={painAssessmentReport.primaryPain.location} pointerColor={painLevelColor}></Slot>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 360, padding: 24 }}>
                        <Typography variant='h4' component='div' align='left' className={styleClasses.healthReport__suggestionitem}>
                        To recover we suggest:<br />
                            <br />
                            <Typography className={styleClasses.healthReport__suggestionitem}>- &nbsp; &nbsp; Pain management strategies</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem}>- &nbsp; &nbsp; Prescribed stretches and release</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem}>- &nbsp; &nbsp; Exercise and rehabilitation program</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem}>- &nbsp; &nbsp; Support and care</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem}>- &nbsp; &nbsp; A prevention or maintenance</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem__wrappedtext}>&nbsp; &nbsp; &nbsp; program to ensure you remain</Typography>
                            <Typography className={styleClasses.healthReport__suggestionitem__wrappedtext}>&nbsp; &nbsp; &nbsp; healthy and happy</Typography>

                        </Typography>
                    </Box>

                </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginBottom: 40 }}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>                    
                    <StyledButton type="button" roundedInversePlain onClick={() => closeEmailReport()} >
                        <FileCopyOutlinedIcon style={{ color: '#FFFDF9', marginRight: 10 }} />Please email me a copy</StyledButton>
                    <UPModal
                        toggleAlert={() => closeEmailReport()}
                        submitButtonText='Send'
                        onSubmit={(event) => emailReport(event)}
                        open={showEmailReport}
                        title="Please enter your name and email to receive a report"
                        onSuccessText ={modalOnSuccessText}
                        onLoading={modalOnLoading}
                    >
                        <StyledTextField
                            id="firstname"
                            key="firstname"
                            name="firstname"
                            type="text"
                            required={true}
                            onChange={(evt) => setFirstName(evt.target.value) }
                            value={firstName}
                            title="First Name"
                            length={50}
                            helperText={messages.InvalidFirstName}
                            error={firstNameError}
                            helperStyle={{
                                width: '100%',
                            }}
                        />
                        <StyledTextField
                            id="email"
                            key="email"
                            name="email"
                            type="text"
                            required={true}
                            onChange={(evt) => setEmail(evt.target.value) }
                            title="Email"
                            value={email}
                            length={400}
                            helperText={messages.InvalidEmail}
                            error={emailError}
                            helperStyle={{
                                width: '100%',
                            }}
                        />
                    </UPModal>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                </Grid>
               
            </Grid></>
    );
};

export default HealthReport;