import { React, forwardRef } from 'react';
import StyledTextField from '../StyledTextField';
const PhoneTextField = (props, ref) => {
    return (
        <StyledTextField
            {...props}
            inputRef={ref}
            onChange={props.onChange}
        />
    );
};
export default forwardRef(PhoneTextField);
