import { connect, useDispatch } from 'react-redux';
import React, { useEffect }  from 'react';
import { Card, CardActionArea, CardContent, Grid, Box, Divider, Typography, CardActions, CircularProgress } from '@material-ui/core';
import ImageMask from '../Shared/ImageMask';
import { useState } from 'react';
import moment from 'moment';
import StyledButton from '../components/StyledButton';
import { push } from 'connected-react-router';
import { saveAppointment } from '../HttpHelpers/UserServiceHttpHelper';
import { signOut } from '../Actions/RegisterActions';
import { QueryParameterNames, SubscriptionGroups } from '../Shared/Constants';

const ConfirmAppointment = (props) =>{
    const [appointmentDate, setAppointmentDate] = useState('');    
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [programName, setProgramName] = useState('');
    const [flow, setFlow] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bookingSuccessful, setBookingSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.appointment) {
            const appointmentTime = moment.utc(props.appointment.startTime).clone().tz(props.appointment.tz);
            setAppointmentDate(appointmentTime.format('dddd, Do MMM YYYY'));
            setStartTime(appointmentTime.format('kk:mm'));
            setEndTime(appointmentTime.add(30, 'minutes').format('kk:mm'));
        }
    }, [props.appointment]);

    useEffect(() => {
        if (props.router) {
            const searchQuery = props.router.location.search;
            const searchParams = new URLSearchParams(searchQuery); 
            const programName = searchParams.get(QueryParameterNames.P)?? '';
            const flow = searchParams.get(QueryParameterNames.F) ?? '';
            setProgramName(programName);
            setFlow(flow);
        }
    }, [props.router]);

    const confirm = () => {
        setIsLoading(true);
        saveAppointment(props.appointment, props.user.signInUserSession.accessToken.jwtToken)
            .then(result => result.json())
            .then(jsonResult => {
                if (jsonResult.isSuccess) {
                    setBookingSuccessful(true);
                    dispatch(signOut());
                } else {
                    setErrorMessage(jsonResult.errorMessage);
                }
            })
            .finally(setIsLoading(false));
    };

    const goBack = () => {
        
        dispatch(push(`/practitioners-availability?p=${programName}&hideStepper=true&f=${flow}`));
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}> 
            <Card style={{backgroundColor: '#000000', maxWidth: 558}}>
                <CardActionArea>
                    <CardContent>
                        <Grid container spacing={2} style={{ marginBottom: '28px' }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ color: '#000000', display: 'flex', justifyContent: 'left', gridGap: '20px' }}>
                                    <ImageMask imageUrl={props.appointment.practitionerPhotoUrl} />
                                    <Box sx={{ alignItems: 'flex-start', display: 'flex', margin: '20px', marginTop: 0, flexDirection: 'column' }}>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '24px', lineHeight: 1.5 }}>{programName === SubscriptionGroups.VPA ? 'Online Appointment' : `${programName.toUpperCase()} Follow Up`}</Typography>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: 1.5 }}>with {props.appointment.practitionerName}</Typography>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: 1 }}> on {appointmentDate}</Typography>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: 1 }}>  {`${startTime} - ${endTime}`}</Typography>
                                    </Box>
                                </Box>
                                <Divider style={{ backgroundColor: '#FFFFFF' }} orientation='horizontal' variant='fullWidth' />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{justifyContent: 'center'}}>
                        {
                            isLoading ?
                                <CircularProgress color='secondary' />
                                : bookingSuccessful ? 
                                    <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em' }}>{'Thank you. Your booking has been confirmed.'}</Typography>
                                    :
                                    errorMessage === '' ?
                                        <Box sx={{display: 'flex', justifyContent: 'center', gridGap: 30}}>
                                            <StyledButton roundedInverse type="button" onClick={goBack} disabled={isLoading}>Back</StyledButton>
                                            <StyledButton rounded type="button" onClick={confirm} disabled={isLoading}>Confirm</StyledButton>
                                        </Box>
                                        :
                                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', gridGap: 30}}>
                                            <Typography style={{ color: '#FFFFFF', fontSize: '18px', lineHeight: '150%', letterSpacing: '0.03em' }}>{`There is an error while confirming your booking. Error message: ${errorMessage}. Please use back button to choose another time and try again.`}</Typography>
                                            <StyledButton roundedInverse type="button" onClick={goBack} disabled={isLoading}>Back</StyledButton>
                                        </Box>
                        }

                    </CardActions>
                </CardActionArea>
            </Card>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        appointment: state.customerDetails.appointment,
        router: state.router,
        user: state.register.auth.user
    };
}

export default connect(mapStateToProps)(ConfirmAppointment);