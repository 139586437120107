import React from 'react';
import { Auth } from 'aws-amplify';
import { SignUp } from 'aws-amplify-react';

import { confirmSignUp, RegisterActions, signedIn, signIn, signUpFailure, redirectAfterLogin } from '../Actions/RegisterActions';
import { messages } from '../Shared/Messages';
import { QueryParameterNames, PrivacyPolicyLink, SessionStorageVariables, AUTH_ACCESS_METHOD, ErrorMessages, SubscriptionGroups, DateSettings } from '../Shared/Constants';
import { SecondaryTitles } from '../Shared/TitleTexts';
import ComponentContainerHeader from '../Shared/ComponentContainerHeader';
import ComponentButtons from '../Shared/ComponentButtons';
import CheckboxInput from '../Shared/CheckboxInput';
import StyledTextField from '../components/StyledTextField.js';
import { savePatient } from '../HttpHelpers/UserServiceHttpHelper';
import { callUpdateRegistrationDetails, commitCustomerDetails } from '../HttpHelpers/CustomerServiceHttpHelper';
import { appointmentCaptured, briefAssessmentCaptured, subscriptionCaptured } from '../Actions/CustomerDetailsActions';
import { Grid } from '@material-ui/core';
import { push } from 'connected-react-router';
import StyledButton from '../components/StyledButton';
import PhoneTextField from '../components/UPFormComponents/PhoneTextField';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { saveBriefAssessment } from '../HttpHelpers/CRMFormsHttpHelper';
import { connect } from 'react-redux';
import { validateEmailHost } from './RegistrationService';
import { getSubscriptionDetails } from '../HttpHelpers/CustomerServiceHttpHelper';
import { Loading } from '../components/SplashScreen/Loading';
import { getApplicablePageInfo } from '../Shared/Services/PageService';
import { EmailRegex, NameRegex } from '../Shared/RegexLibrary';

class MyDnaSignUp extends SignUp {
  
    constructor(props) {
        super(props);
        
        this._validAuthStates = [RegisterActions.SIGN_UP];
        this.state = {
            privacyPolicyURL: PrivacyPolicyLink,
            privacyPolicy: false,
            programName: null,
            isSubmitting: false,
            subscription: null,
            dob: null,
            contact: null,
            flow: null,
            emailError: false,
            emailHelperText: null,
            firstNameError: false,
            lastNameError: false
        };
    }

    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const programName = urlParams.get('p');
        const flow = urlParams ? urlParams.get(QueryParameterNames.F) : '';
        this.setState({ programName: programName, flow });
        if(programName?.length > 0 || flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION) {
            this.setState({ subscription: this.props.customerDetails.subscription });
        }
        else if (flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION)
        {
            getSubscriptionDetails(SubscriptionGroups.ISA).then((res) =>
            {
                if (res.ok)
                {
                    res.json().then(r => {
                        this.props.dispatch(subscriptionCaptured(r[0]));
                        this.setState({ subscription: subscriptionCaptured(r[0]) });
                    }
                    );
                }
                else
                    console.error(res.body);
            }).catch(e => console.error(e));
        }
    }

    signUp(e) {
        e.preventDefault();
        if(this.state.privacyPolicy) {
            const email = this.inputs.email?.trim();
            const password = this.inputs.password?.trim();
            const firstName = this.inputs.firstName?.trim();
            const lastName = this.inputs.lastName?.trim();
            const subscriptionDetails = this.state.subscription;

            if(password !== this.inputs.confirmPassword?.trim()) {
                this.setState({
                    errorMessage: messages.passwordDoesNotMatch
                });
                this.props.dispatch(signUpFailure(messages.passwordDoesNotMatch));
                return;
            }
               
            var isFirstNameValid = firstName && NameRegex.test(firstName);
            this.setState({firstNameError: !isFirstNameValid});
            if (!isFirstNameValid)
            {
                this.setState({ errorMessage: messages.InvalidFirstName });
                this.props.dispatch(signUpFailure(messages.InvalidFirstName));
                return;
            }

            
            var isLastNameValid = lastName && NameRegex.test(lastName);
            this.setState({lastNameError: !isLastNameValid});
            if (!isLastNameValid)
            {
                this.setState({ errorMessage: messages.InvalidLastName });
                this.props.dispatch(signUpFailure(messages.InvalidLastName));
                return;
            }

            var isEmailValid = email && EmailRegex.test(email);
            this.setState({emailError: !isEmailValid});

            if (!isEmailValid)
            {
                this.setState({ errorMessage: messages.InvalidEmail });
                this.props.dispatch(signUpFailure(messages.InvalidEmail));
                return;
            }


            this.setState({ isSubmitting: true });

            Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email
                },
            })
                .then(data => {
                    this.setState({
                        errorMessage: null
                    });
                    if(!data.userConfirmed) {
                        if(data.codeDeliveryDetails.DeliveryMedium === 'EMAIL') {
                            super.changeState(RegisterActions.CONFIRM_SIGN_UP,
                                { username: email, password: password });
                            this.props.dispatch(confirmSignUp(messages.pleaseCheckYourEmail));
                        }
                        this.setState({ isSubmitting: false });
                    }
                    else {
                        Auth.signIn({ username: email, password }).then(user => {
                            const dob = this.state.dob?.trim();
                            user.firstName = firstName;
                            user.lastName = lastName;
                            user.subscription = this.state.subscription;
                            user.dob = dob;
                            user.acceptedPrivacyPolicy = this.state.privacyPolicy;
                            user.contact = this.state.contact;

                            if(this.state.programName || 
                                this.state.flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION ||
                                this.state.flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION) {
                                this.savePatientAndCommitRegistration(user, subscriptionDetails);
                            } else {
                                this.setState({ isSubmitting: false });
                                this.registerPatient(user);
                                this.props.dispatch(signedIn(user));
                                super.changeState(RegisterActions.SIGNED_IN);
                                this.props.dispatch(redirectAfterLogin(this.props));
                            }
                        });
                    }
                })
                .catch(err => {
                    this.setState({ isSubmitting: false });
                    if(err.code === 'UsernameExistsException') {
                        this.props.dispatch(signUpFailure(messages.userExistsErrorMessage));
                        this.setState({
                            errorMessage: messages.userExistsErrorMessage
                        });
                    } else if(err.code === 'InvalidPasswordException') {
                        this.setState({
                            errorMessage: messages.invalidPassword
                        });
                    }
                    else if(err.code === 'InvalidParameterException') {
                        if(err.message.includes('password')) {
                            this.setState({
                                errorMessage: messages.invalidPassword
                            });
                        } else {
                            this.setState({
                                errorMessage: err.message
                            });
                        }
                    }
                    else {
                        this.setState({
                            errorMessage: 'Sign up failed'
                        });
                        this.props.dispatch(signUpFailure(err.message));
                    }
                });
        } else {
            this.setState({
                errorMessage: messages.acceptPrivacyPolicy,
            });
        }

    }

    signIn() {
        super.changeState(RegisterActions.SIGN_IN);
        this.props.dispatch(signIn());
    }

    showError(errorMessage) {
        this.setState({
            ...this.state,
            errorMessage
        });
    }
    
    registerPatient(user) {
        return savePatient(user)
            .then((result) => 
            { 
                if (result.status && (result.status === 200 || result.status === 201 || result.status === 204)) {
                    return result.json(); 
                }
                throw Error(messages.UnableToCreatePatient);
            }
            )
            .then((patient) => {
                if (patient)
                {
                    user.patientId = patient.result;
                    return user;
                }
            });
    }

    savePatientAndCommitRegistration(user, subscriptionDetails) {
        // Create patient in CRM        
        const appointmentData = this.props.customerDetails.appointment;
        user.appointmentData = appointmentData;
        savePatient(user)
            .then(res => res.json())
            .then(async (json) => {
                user.patientId = json.result;
                const healthReport = this.props.customerDetails.healthReport;
                const registrationDetails = {
                    emailAddress: user.username,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    subscriptionCode: subscriptionDetails.id,
                    crmPatientId: user.patientId,
                    recurringPaymentPriceId: subscriptionDetails.priceId,
                    timeZone: this.props.customerDetails.userDetails.timeZone,
                    healthReport: healthReport ? JSON.stringify(healthReport) : null,
                    emailReport: false,
                    promoCode: this.props.customerDetails?.couponDetails?.promoCode ?? null,
                };

                if (appointmentData) {
                    appointmentData.patientId = user.patientId;
                    this.props.dispatch(appointmentCaptured((appointmentData)));
                }
                
                const briefAssessmentForm = JSON.parse(sessionStorage.getItem(SessionStorageVariables.BriefAssessmentForm));
                if (briefAssessmentForm)
                {
                    try {
                        const res = await saveBriefAssessment(briefAssessmentForm, user?.signInUserSession?.accessToken?.jwtToken);
                        const saveForm = await res.json();
                        this.props.dispatch(briefAssessmentCaptured(briefAssessmentForm));              
                    } catch(err) {
                        this.showError('Failed save form.');
                        console.log(err);
                    }
                }

                // Update registration details
                await callUpdateRegistrationDetails(registrationDetails)
                    .then((result) => result.json())
                    .then((json) => {
                        if(json.message === 'Updated') {
                            if (this.state.flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION || 
                                this.state.flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION)
                            {
                                const customerDetails = {
                                    ... this.props.customerDetails,
                                    auth: {
                                        user: user,
                                        isAuthenticated: true,
                                    }
                                };

                                commitCustomerDetails(customerDetails)
                                    .then((response) => {
                                        if(!response.ok) {
                                            response.json()
                                                .then((data) => {
                                                    console.log(data);
                                                    this.showError(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                                });
                                        } else {
                                            this.props.dispatch(signedIn(user));
                                            this.props.dispatch(subscriptionCaptured(subscriptionDetails));
                                            this.props.dispatch(redirectAfterLogin(this.props));
                                            super.changeState(RegisterActions.SIGNED_IN);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        this.showError(ErrorMessages.FailedInUpdatingRegistrationDetails);
                                    });
                            }
                            else
                            {
                                this.props.dispatch(signedIn(user));
                                this.props.dispatch(subscriptionCaptured(subscriptionDetails));
                                this.props.dispatch(redirectAfterLogin(this.props));
                                super.changeState(RegisterActions.SIGNED_IN);
                            }
                        } else {
                            this.showError('Failed updating registration details');
                            
                        }
                        this.setState({ isSubmitting: false });
                    }).catch(() => {
                        this.showError('Failed updating registration details');
                    }).finally(() => {
                        this.setState({ isSubmitting: false });
                    });
            }).catch(() => {
                this.showError('Failed creating patient.');
            }).finally(() => {
                this.setState({ isSubmitting: false });
            });

    }

    goPrevStep() {
        const groupPageInfo = getApplicablePageInfo(this.state.flow, this.state.subscription.group, this.props.router.location.pathname);
        this.props.dispatch(push(`${groupPageInfo.currentPage.previousPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }
    
    handleEmailChanged(event)
    {
        if (this.state.flow && this.state.flow == AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION)
        {            
            const org = this.props.customerDetails.organisation;
            if (!validateEmailHost(event.target.value, org))
            {
                this.setState({ emailError: true, emailHelperText: `Invalid email. Please use ${org.name}'s email.`});
                return false;
            }
        }
        this.setState({ emailError: false, emailHelperText: null});
        this.handleInputChange(event);
    }
    showComponent(theme) {
        if(this.state.isSubmitting) {
            return <Loading />;
        }
        return (
            <>
                <form onSubmit={(e) => this.signUp(e)} className={'mydna-signup-form'}>
                    <ComponentContainerHeader primaryTitle={this.props.title || 'Create an account to continue'} secondaryTitle={SecondaryTitles.Create} />
                    <div style={{ width: '20rem', margin: 'auto' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <StyledTextField
                                    id="firstName"
                                    title="First Name"
                                    name="firstName"
                                    onChange={this.handleInputChange}
                                    required={true}   
                                    helperText={messages.InvalidFirstName}
                                    error={this.state.firstNameError}
                                    helperStyle={{
                                        width: '100%',
                                    }}
                                />

                            </Grid>
                            <Grid item xs={6} md={6}>
                                <StyledTextField
                                    id="lastName"
                                    title="Last Name"
                                    name="lastName"
                                    onChange={this.handleInputChange}
                                    required={true}   
                                    helperText={messages.InvalidLastName}
                                    error={this.state.lastNameError}
                                    helperStyle={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div>
                            <StyledTextField
                                id="email"
                                title="Email Address"
                                name="email"
                                onChange={(event) => this.handleEmailChanged(event)}
                                required={true}
                                length={254}
                                error={this.state.emailError}
                                helperText={this.state.emailHelperText}
                                helperStyle={{
                                    width: '100%',
                                }}
                            />
                        </div>
                        <div>
                            <StyledTextField
                                id="password"
                                title="Password"
                                name="password"
                                type="password"
                                onChange={this.handleInputChange}
                                required={true}
                                length={25}
                            />
                            <p className={'password-help-text'}>{messages.invalidPassword}</p>
                        </div>
                        <div>
                            <StyledTextField
                                id="confirmPassword"
                                title="Confirm Password"
                                name="confirmPassword"
                                onChange={this.handleInputChange}
                                type="password"
                                required
                                length={25}
                            />
                        </div>
                        <div>
                            <StyledTextField
                                id="date"
                                title="DOB"
                                type="date"
                                onChange={(event) =>
                                    this.setState({
                                        dob: event.target.value,
                                    })
                                }
                                required={true}
                                inputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{inputProps: { min: DateSettings.Min, max: DateSettings.Max} }}
                            />
                        </div>  
                 
                        <div>
                        
                            <PhoneInput   
                                id="mobile"
                                title="Mobile"
                                country='AU'
                                international
                                withCountryCallingCode={true}
                                value={this.state.contact}
                                onChange={(value) =>{
                                    this.setState({
                                        contact: value,
                                    });
                                }}
                                length={15}
                                //onChange={this.handleMobileChange}
                                inputComponent={PhoneTextField}
                            />

                            {/* <StyledTextField
                                id="contact"
                                title="Mobile"
                                onChange={(event) =>
                                    this.setState({
                                        contact: event.target.value,
                                    })
                                }
                                required={false}
                                length={20}
                            /> */}
                        </div>
                        <div className="terms-conditions-container">
                            <CheckboxInput
                                name={'termsAndConditions'}
                                onChange={(event) => 
                                    this.setState({
                                        privacyPolicy: event.target.checked,
                                        errorMessage: null,
                                    })
                                }
                            />
                            <span className={'terms-conditions-font'}>
                            I accept Universal Practice's{' '}
                                <a
                                    href={this.state.privacyPolicyURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={'TermsAndConditionsLink'}
                                >
                                Privacy Policy
                                </a>
                            </span>
                        </div>
                        {this.state.errorMessage && <div>
                            <p className="error">{this.state.errorMessage}</p>
                        </div>}
                        <div className='flex-center-vh' style={{ marginTop: '2rem' }}>
                            {
                                this.props.previousPage &&
                            <StyledButton roundedInverse style={{ marginRight: '16px' }} type="button" onClick={(event) => this.goPrevStep(event)}>BACK</StyledButton>
                            }
                      
                            <ComponentButtons
                                dispatch={this.props.dispatch}
                                previousPage={this.props.previousPage}
                                singleButton={true}
                                isSubmitting={this.state.isSubmitting}
                                submitText={'NEXT'}
                                buttonOnly
                                disableArrows={true} 
                                rounded />
                        </div>
                    </div>
                </form>
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(MyDnaSignUp);
