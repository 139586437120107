
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import moment from 'moment';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useEffect } from 'react';

const localeMap = {
    en: enLocale,
};
const theme = createTheme({
    palette: {
        background: '#000',
    },
    overrides: {
        MuiPickersCalendarHeader: {
            iconButton: {
                backgroundColor: '#282828',
                color: '#FFFFFF',
                border: 0,
                '&:hover': {
                    color: '#FFFFFF',
                    backgroundColor: 'rgba(249, 245, 236, 0.2) !important'
                }
            },
            current: {
                color: '#FFFFFF'
            },
            daySelected: {
                backgroundColor: '#FFFFFF',
                borderRadius: 0,
                '&:hover': {
                    borderRadius: 0,
                    backgroundColor: '#FFFFFF'
                }
            },
            daysHeader: {
                backgroundColor: '#282828',
                height: 45,
                maxHeight: 45,
                justifyContent: 'space-between'
            },
            dayLabel: {
                color: '#FFFFFF !important',
                width: '5px',
                padding: '8px',
                fontWeight: 'bold',
                fontSize: 'medium',
                backgroundColor: '#282828',
                margin: '0 18px',
                marginBottom: '6px',
                letterSpacing: '5px',
                overflow: 'hidden'
            },
            switchHeader: {
                color: '#FFFFFF',
                justifyContent: 'center',
                height: '52px',
                backgroundColor: '#282828',
                marginBottom: 0,
                width: '275px'
            },
            transitionContainer: {
                width: '260px',
                order: -1,
                marginLeft: 18,
                '& p': {
                    textAlign: 'left'
                }
            }
        },
        MuiPickersDay: {
            day: {
                backgroundColor: 'transparent',
                border: 0,
                color: '#FFFFFF',
                '&:hover': {
                    borderRadius: 0,
                    backgroundColor: '#c5c6c7',
                    color: '#000000'
                },
                dayDisabled: {
                    color: '#000000',
                    backgroundColor: '#c5c6c7 !important'
                },
                height: '32px',
                width: '32px',
                padding: '18px'
            },
            daySelected: {
                backgroundColor: 'rgba(249, 245, 236, 0.2)',
                color: '#FFFFFF',
                '&:hover': {
                    color: '#000000',
                    backgroundColor: 'rgba(249, 245, 236, 0.2)'
                }
            },
            current: {
                color: '#FFFFFF'
            },
        },
        MuiButtonBase: {
            root: {
                backgroundColor: '#000',
                borderRadius: 0,
                border: '1px solid #000',
                padding: '10px',
                spacing: '12px',
                margin: '10px'
            }
        },
        MuiIconButton: {
            root: {
                backgroundColor: '#FFFFFF',
                borderRadius: 0,
                padding: 10,
                spacing: 10,
                margin: 10,
                '&:hover': {
                    borderRadius: 0,
                    backgroundColor: '#FFFFFF !important'
                }
            }
        },
        MuiPickersCalendar: {
            transitionContainer: {
                minHeight: '276px',
                background: '#454545',
                margin: '0px !important',
                padding: '12px'
            },
            week: {
                marginTop: 12,
                justifyContent: 'space-around'
            }
        }

    }
});

function UPDatePicker({ appointmentDates, handleChangedDate, handleMonthChange, tz }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const locale = 'en';
    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleChangedDate(date);
    };

    const dates = (appointmentDates).map((date) => date.split('T')[0]);
    const disableDay = (date) => !dates.includes(moment(date).tz(tz).format('YYYY-MM-DD'));

    useEffect(() => {
        if (appointmentDates && appointmentDates.length == 0)
        {
            //setSelectedDate(new Date()); //reset selected date
            return;
        }
        const dates = appointmentDates[0].split('T')[0].split('-');
        const selectedDate = new Date(dates[0],dates[1] - 1,dates[2],0,0,0);
        setSelectedDate(selectedDate); //select first date
        handleChangedDate(selectedDate);
    }, [appointmentDates]);
    
    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                <Paper style={{ minHeight: '400px', overflow: 'hidden' }}>
                    <Calendar
                        date={selectedDate}
                        onChange={handleDateChange}
                        label="Select Day"
                        disablePast
                        shouldDisableDate={disableDay}
                        onMonthChange={handleMonthChange}
                        leftArrowIcon={<KeyboardBackspaceIcon />}
                        rightArrowIcon={<KeyboardBackspaceIcon style={{ transform: 'rotateY(180deg)' }} />}
                    />
                </Paper>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}

export default UPDatePicker;
