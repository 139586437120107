import { isProduction } from '../../Shared/Services/EnvironmentService';
export const getInsurerList = () => {
    if (isProduction())
        return InsurerListProd;
    return InsurerList;
};
const InsurerList =  [{
    'name': 'Private Health Insurance',
    'token': '059624f0-f218-4f3f-9b41-ae58a69377fd'
},
{
    'name': 'WorkCover',
    'token': 'e54a6180-d6f2-4554-8bd0-8ad8ed2fce98'
},
{
    'name': 'TAC',
    'token': '6634b7cd-6a11-48c6-ab0c-f8cfe135c8d9'
},
{
    'name': 'NDIS',
    'token': '838312c2-45bc-4f2b-abaf-a93a06c8bbd9'
},
{
    'name': 'Medicare',
    'token': 'b4f3dda8-1a4c-47bb-9e87-d05540d995ac'
},
{
    'name': 'DVA',
    'token': 'fb000380-ce59-4e07-84a9-10aa467e6bbc'
}];

const InsurerListProd =   [
    {
        'name': 'Private Health Insurance',
        'token': '27fce116-0de0-4940-9cd1-5e2fd36f1be9'
    },
    {
        'name': 'WorkCover',
        'token': '8563723b-efd1-43a1-b069-94798a908f65'
    },
    {
        'name': 'TAC',
        'token': '94d153f0-7cb3-4ef6-a14a-d0bd3ef255dc'
    },
    {
        'name': 'NDIS',
        'token': '0435c1fc-1d48-40ce-a818-0b39ba43fa9b'
    },
    {
        'name': 'Medicare',
        'token': 'a4ef3948-3488-450f-9e92-92c8feeb96ad'
    },
    {
        'name': 'DVA',
        'token': '6f217992-9613-42ae-9a9b-a9723b700380'
    }
];
    