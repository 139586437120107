import { notify } from '../Shared/Notifier';
import { AUTH_ACCESS_METHOD, NotificationSeverity, SessionStorageVariables, QueryParameterNames, SubscriptionGroups } from '../Shared/Constants';
import { push } from 'connected-react-router';
import { sendLinkClick } from '../Analytics/AnalyticsHelper';

export const RegisterActions = {
    SIGN_UP: 'signUp',
    SIGNED_UP: 'signedUp',
    SIGN_UP_FAILURE: 'signUp_failure',
    CONFIRM_SIGN_UP: 'confirmSignUp',
    SIGN_IN: 'signIn',
    SIGNED_IN: 'signedIn',
    SIGN_IN_FAILURE: 'signIn_failure',
    CONFIRM_SIGN_UP_FAILURE: 'confirmSignUp_failure',
    SIGN_OUT: 'signOut',
    SIGNED_OUT: 'signedOut',
    FORGOT_PASSWORD: 'forgotPassword',
    FORGOT_PASSWORD_FAILURE: 'forgotPasswordFailure',
    REQUIRE_NEW_PASSWORD: 'requireNewPassword'
};

export const signUp = () => dispatch => {
    dispatch({
        type: RegisterActions.SIGN_UP,
        error: null,
        payload: {
            auth: {
                user: {},
                isAuthenticated: false
            }
        }
    });
};

export function signUpFailure(error) {
    return dispatch => {
        // don't clear the storage, there is health report, briefAx and appoinment info
        // clearSessionStorage();
        dispatch({
            type: RegisterActions.SIGN_UP_FAILURE,
            error: error,
            payload: {
                auth: {
                    user: {},
                    isAuthenticated: false
                }
            }
        }
        );
    };
}

export function confirmSignUp(message) {
    return dispatch => {
        dispatch({
            type: RegisterActions.CONFIRM_SIGN_UP,
            error: null,
            message: message,
            payload: {
                auth: {
                    user: {},
                    isAuthenticated: false
                }
            }
        }
        );
        notify(message, NotificationSeverity.SUCCESS);
    };
}

export function signIn() {
    return dispatch => {
        dispatch({
            type: RegisterActions.SIGN_IN,
            payload: {
                auth: {
                    user: {},
                    error: null,
                    isAuthenticated: false,
                    stepNum: 3
                }
            }
        }
        );
    };
}

export function signedIn(user) {
    return dispatch => {
        if(user.patientId) {
            const patient = {
                patientId: user.patientId,
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username,
                activeSubscription: user.activeSubscription,
                subscriptions: user.subscriptions
            };
            sessionStorage.setItem(SessionStorageVariables.Patient, JSON.stringify(patient));
        }
        
        dispatch({
            type: RegisterActions.SIGNED_IN,
            error: null,
            payload: {
                auth: {
                    user: user,
                    isAuthenticated: true,
                }
            }
        }
        );
    };
}

export function signInFailure(error) {
    return dispatch => {
        clearSessionStorage();    
        dispatch({
            type: RegisterActions.SIGN_IN_FAILURE,
            error: error,
            payload: {
                auth: {
                    user: {},
                    isAuthenticated: false
                }
            }
        }
        );
    };
}

export function confirmSignUpFailure(error) {
    return dispatch => {
        clearSessionStorage();
        dispatch({
            type: RegisterActions.CONFIRM_SIGN_UP_FAILURE,
            error: error,
            payload: {
                auth: {
                    user: {},
                    isAuthenticated: false
                }
            }
        }
        );
    };
}

export function signOut() {
    return dispatch => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch({
            type: RegisterActions.SIGN_OUT,
            error: null,
            payload: {
                auth: {
                    user: {},
                    isAuthenticated: false
                }
            }
        });
    };
}

export function forgotPassword(forgotPassword) {
    return dispatch => {
        dispatch({
            type: RegisterActions.FORGOT_PASSWORD,
            error: null,
            payload: {
                auth: null,
                forgotPassword: forgotPassword
            }
        }
        );
    };
}

export function redirectAfterLogin(state) {
    return dispatch => {
        const searchQuery = state.router.location.search;
        const params = new URLSearchParams(searchQuery);
        const flow = params.get(QueryParameterNames.F) ?? '';
        const programName = params.get(QueryParameterNames.P) ?? '';
        const recommendedProduct = params.get(QueryParameterNames.R) ?? '';
        let redirectUrl = params.get(QueryParameterNames.RedirectTo);

        if (flow === AUTH_ACCESS_METHOD.ORGANISATION_REGISTRATION || 
            flow === AUTH_ACCESS_METHOD.ISA_REGISTRATION ||
            flow === AUTH_ACCESS_METHOD.PHYSIO_RECOMMENDATION)
        {
            dispatch(push(`${state.nextPage}?p=${programName}&f=${flow}&r=${recommendedProduct}`));
            sendLinkClick('Next Button','Next',`${state.nextPage}`, window.location.pathname, null);
            return;
        }
    
        if (redirectUrl == null) {
            redirectUrl = '/subscription'; 
            if (programName) {
                redirectUrl = state.nextPage;
              
                if (state.auth.user.activeSubscription && state.customerDetails.subscription) {
                    if (state.customerDetails && state.customerDetails.appointment 
                        && state.customerDetails.subscription.group === SubscriptionGroups.VPA)
                    {
                        //existing user buying vpa don't have to show existing products. straight to payment
                        redirectUrl = '/program-payment'; 
                    }
                    else if (state.auth.user.activeSubscription.subscriptionOption === state.customerDetails.subscription.subscriptionOption)
                    {
                        redirectUrl = '/recommended-program';
                    }
                }
            }
            sendLinkClick('Next Button','Next',redirectUrl, window.location.pathname, null);
        }
        
        params.forEach((value, key) => {
            if (key !== 'redirectTo' && key !== 't') {
                if (redirectUrl.includes('?')) {
                    redirectUrl = `${redirectUrl}&${key}=${value}`;
                } else {
                    redirectUrl = `${redirectUrl}?${key}=${value}`;
                }
            }

        });
        dispatch(push(redirectUrl));
    };
}

function clearSessionStorage() {
    sessionStorage.clear();
}

