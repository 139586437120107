import { EXP_API_BASE_URL, EXP_API_KEY } from '../Shared/Configs';
import { ExperienceApiEndpoints } from './HttpConstants';

import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import awsCognitoConfig from '../aws-cognito-config.json';
export async function getCognitoData(purpose) {
    const auth = await Auth.currentSession();
    if (!auth) {
        return null;
    }

    const cognitoIdToken = new CognitoIdToken({
        IdToken: auth?.getIdToken()?.getJwtToken()
    });
    const cognitoPrefix = `CognitoIdentityServiceProvider.${awsCognitoConfig.aws_user_pools_web_client_id}.`;
    const cognitoKey = `CognitoIdentityServiceProvider.${awsCognitoConfig.aws_user_pools_web_client_id}.${cognitoIdToken.payload.email}.`;
    const cognitoData = [
        {
            name: `${cognitoPrefix}LastAuthUser`,
            value: cognitoIdToken.payload.email,
        },
        {
            name: `${cognitoKey}idToken`,
            value: auth?.getIdToken()?.getJwtToken()
        },
        {
            name: `${cognitoKey}refreshToken`,
            value: auth?.getRefreshToken()?.getToken()
        },
        {
            name: `${cognitoKey}accessToken`,
            value: auth?.getAccessToken()?.getJwtToken()
        },
        {
            name: 'up-tokenPurpose',
            value: purpose
        },
        {
            name: 'up-token-source',
            value: 'Registration'
        },
        {
            name: 'up-token-type',
            value: 'S'
        }
    ];
    return cognitoData;
}


export async function saveToken(purpose) {
    const auth = await Auth.currentSession();
    if (!auth) {
        return null;
    }
    const data = await getCognitoData(purpose);
    const response = fetch(
        `${EXP_API_BASE_URL}${ExperienceApiEndpoints.SaveToken}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': EXP_API_KEY,
                Authorization: `Bearer ${auth?.getAccessToken()?.getJwtToken()}`
            },
            body: JSON.stringify(data),
        }
    ).then(response => {
        if (response.ok)
            return response.json();
        return null;
    }).catch((err) => console.log(err));
    return response;
}