import {createStore, applyMiddleware} from 'redux';
import rootReducer from './rootReducer';
import {routerMiddleware} from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const middleWares = [
        routerMiddleware(history), // for dispatching history actions
        thunk,
        promise
    ];

    if (process.env.NODE_ENV !== 'production') {
        middleWares.push(logger);
    }

    return createStore(
        rootReducer(history), // root reducer with router state
        preloadedState,
        composeWithDevTools(
            applyMiddleware(...middleWares)
        ),
    );
}
