import React from 'react';
import { SvgIcon } from '@material-ui/core';

const BodyMapping = ({ iconColor, style, ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 34 43" fill="none" style={{ fill: 'none', width: '34px', height: '43px', ...style }}>
            <g>
                <path d="M13.5673 9.99203C13.521 12.0575 13.3167 13.8436 12.557 15.4771C11.8026 17.0977 10.6535 18.0525 9.11486 18.3869C7.08063 18.8286 5.38136 18.0427 3.91628 16.4254C1.93379 14.2333 0.716526 11.5086 0.106531 8.38774C-0.220252 6.70551 0.231798 5.27659 1.18492 4.04902C2.6037 2.2174 4.35471 1.07102 6.38622 0.512439C8.00924 0.0642773 9.40896 0.570895 10.5827 1.94461C12.1567 3.78597 13.088 6.03977 13.4447 8.62806C13.5183 9.15741 13.5428 9.69326 13.57 9.99528L13.5673 9.99203Z" fill={iconColor} />
                <path d="M30.8836 39.483C29.6255 40.9054 28.4219 41.9966 26.9159 42.5097C25.4209 43.0195 24.043 42.7273 22.7576 41.6653C21.0583 40.2624 20.3231 38.2716 20.2468 35.8944C20.1434 32.6728 20.8977 29.7176 22.3192 26.9961C23.0844 25.5282 24.2527 24.9015 25.6551 24.8365C27.7438 24.7391 29.6609 25.4048 31.4283 26.7233C32.8416 27.7755 33.5306 29.3149 33.5469 31.2731C33.5687 33.9037 32.8906 36.2809 31.608 38.4113C31.3466 38.8464 31.047 39.2459 30.8864 39.483H30.8836Z" fill={iconColor} />
                <path d="M1.82164 39.6324C0.653387 37.2649 1.61195 34.144 2.64677 31.9649C4.00836 29.0876 6.24411 26.9215 8.99181 26.2525C10.22 25.9537 11.7913 25.9375 12.7335 27.0969C13.3299 27.834 13.5096 28.9252 13.496 29.9514C13.466 32.2475 12.63 34.3811 11.6987 36.3394C10.751 38.3334 9.56368 40.3533 7.76637 41.3471C6.41022 42.0973 4.76269 42.185 3.43105 41.3698C2.68217 40.9119 2.16204 40.3144 1.82436 39.6324H1.82164Z" fill={iconColor} />
                <path d="M27.2618 3.95483C27.7302 3.97757 28.2231 4.0003 28.6914 4.23088C29.2497 4.50692 29.6173 4.98106 29.7181 5.70202C29.7535 5.94883 29.7371 6.20863 29.7181 6.45869C29.6881 6.86789 29.6391 7.27708 29.5955 7.68302C29.5492 8.1052 29.5057 8.53063 29.4539 8.95281C29.3967 9.42695 29.3423 9.9011 29.2742 10.372C29.1843 10.9858 29.1598 11.6158 28.9365 12.1971C28.6179 13.0317 28.0706 13.4377 27.3053 13.4604C26.679 13.4799 26.1235 13.1941 25.5816 12.8791C24.5495 12.2815 23.8387 11.3495 23.531 10.0115C23.373 9.32303 23.3295 8.62481 23.3758 7.91035C23.4112 7.35502 23.4983 6.81592 23.6862 6.30606C24.111 5.16292 24.8708 4.50042 25.8566 4.16592C26.3059 4.01329 26.7661 3.96133 27.259 3.95483H27.2618Z" fill={iconColor} />
                <path d="M10.2609 5.57861L26.8914 9.85563V33.7933L8.22663 33.5075L5.88196 8.88787" stroke={iconColor} strokeWidth="0.697721" strokeMiterlimit="10" />
            </g>
        </SvgIcon>
    );
};

export default BodyMapping;