import MyDnaSignUp from './MyDnaSignUp';
import MyDnaSignIn  from './MyDnaSignIn';
import MyDnaConfirmSignUp from './MyDnaConfirmSignUp';
import { Authenticator } from 'aws-amplify-react/lib/Auth/Authenticator';
import React, { Component } from 'react';
import { RegisterActions } from '../Actions/RegisterActions';
import MyDnaForgotPassword from './MyDnaForgotPassword';
import MyDnaForgotPasswordConfirmation from './MyDnaForgotPasswordConfirmation';
import { Box } from '@material-ui/core';

export default class Authentication extends Component {
    constructor(props) {
        super(props);
        this.authState = props.authState;
    }

    render() {
        return (
            <Box>
                <Authenticator
                    authState={this.authState}
                    usernameAttributes='email'
                    hideDefault={true}>
                    <MyDnaSignUp override={RegisterActions.SIGN_UP} auth={this.props.auth}
                        dispatch={this.props.dispatch} router={this.props.router} title={this.props.title} nextPage={this.props.nextPage} previousPage={this.props.previousPage} />
                    <MyDnaConfirmSignUp override={RegisterActions.CONFIRM_SIGN_UP} auth={this.props.auth}
                        dispatch={this.props.dispatch} />
                    <MyDnaSignIn override={RegisterActions.SIGN_IN} auth={this.props.auth} router={this.props.router}
                        dispatch={this.props.dispatch} nextPage={this.props.nextPage} previousPage={this.props.previousPage} />
                    <MyDnaForgotPassword override={RegisterActions.FORGOT_PASSWORD}
                        dispatch={this.props.dispatch} />
                    <MyDnaForgotPasswordConfirmation override={RegisterActions.REQUIRE_NEW_PASSWORD}
                        dispatch={this.props.dispatch} />
                </Authenticator>
            </Box>
        );
    }
}
