import { responsiveFontSizes } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const colours = {
    primaryMain: '#000',
    secondaryMain: '#FFF',
    secondaryLight: '#000',
    titleDark: '#000',
    border: '#000',
    primaryText: '#FFF',
    secondaryText: 'rgb(255,255,255, 0.6)',
};

const typography = {
    fontFamily: 'Moderat',
    body1 : {
        fontSize: '16px',
        lineHeight: 1
    },
    body2 : {
        fontSize: '18px',
        lineHeight: 1.5
    },
    h1: {
        fontFamily: 'Recoleta', 
        marginTop: '12px',
        fontSize: '44px',
        textTransform: 'none',
        marginBottom: '6px'
    },
    h2: {
        fontSize: '24px',
        marginBottom: '20px',
        textAlign: 'left'
    },
    h4: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    h5: {
        fontSize: '24px',
        lineHeight: 1
    },
    h6: {
        fontSize: '16px',
        lineHeight: 1.2,
    },
    allVariants: {
        color: colours.primaryText
    },
    button: {
        fontSize: '18px',
        lineHeight: 1,
        textTransform: 'normal'
    }
};

export const materialTheme = responsiveFontSizes(
    createTheme({
        typography: {
            ...typography,
        },
        palette: {
            type: 'dark',
            common: {
                black: '#000',
                white: '#FFF'
            },
            primary: {
                main: colours.primaryMain,
                color: colours.secondaryMain
            },
            secondary: {
                main: colours.secondaryMain,
                light: colours.secondaryLight,
            },
            text: {
                primary: colours.primaryText,
                secondary: colours.secondaryText,
            }
        },
    })
);