import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Meditation = ({ iconColor, style, ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 35 33" fill="none" style={{ width: '35px', height: '33px', ...style }}>
            <path d="M17.755 5.39329C17.5192 5.70644 17.2668 6.03544 16.9068 6.26931C16.4724 6.54678 16.0131 6.61813 15.529 6.41201C15.3635 6.34066 15.2146 6.22967 15.0739 6.12265C14.8422 5.94427 14.6187 5.754 14.3953 5.5677C14.1636 5.37347 13.9277 5.17924 13.7002 4.98104C13.4436 4.75906 13.183 4.54105 12.9306 4.31114C12.6037 4.01385 12.2355 3.75223 11.9955 3.37963C11.6479 2.84053 11.6645 2.30541 12.0162 1.77424C12.3058 1.33425 12.7444 1.06471 13.1913 0.811016C14.0436 0.331384 14.9456 0.200575 15.8973 0.501832C16.3855 0.656424 16.8282 0.898222 17.2378 1.20344C17.5564 1.44128 17.8378 1.70686 18.0571 2.0319C18.5412 2.76126 18.5784 3.54214 18.3054 4.34682C18.1812 4.71546 17.9909 5.05239 17.7633 5.39329H17.755Z" fill={iconColor} />
            <path d="M33.8874 29.4782C32.9358 26.0375 30.9332 23.2351 28.0741 20.9955C24.3792 18.1058 20.2457 16.1991 15.3923 16.0247C12.8352 15.9336 10.3609 16.3577 8.03555 17.3883C5.34195 18.5854 3.47588 20.5872 2.21804 23.1399C0.736771 26.1485 0.364376 29.3633 0.244385 33.3946C0.306449 33.8782 0.36438 34.5639 0.455408 35.3408H34.4212C34.5453 33.3589 34.4212 31.4047 33.8874 29.4782Z" fill={iconColor} />
            <path d="M7.41931 9.40536C7.891 11.1138 8.88816 12.5051 10.3074 13.619C12.1403 15.0539 14.1926 16.0013 16.6049 16.0885C17.8751 16.1361 19.104 15.922 20.2584 15.4107C21.5948 14.8161 22.5217 13.8211 23.1464 12.5567C23.8829 11.0623 24.0691 9.46481 24.1271 7.46304C24.0981 7.22521 24.065 6.88035 24.0236 6.49585H7.1545C7.09243 7.4789 7.1545 8.45005 7.41931 9.40932V9.40536Z" fill={iconColor} />
        </SvgIcon>
    );
};

export default Meditation;