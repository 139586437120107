import React, { Component } from 'react';
import MenuAppBar from './MenuAppBar';

import UPStepper from './UPStepper';

class ComponentWrapper extends Component {

    render() {
        return (
            <div>
                <MenuAppBar
                    {...this.props}
                    dispatch={this.props.dispatch}
                    title={this.props.title}
                >
                    {
                        !this.props.hideStepper &&
                        <UPStepper activeStep={this.props.stepNum} numberOfSteps={this.props.numberOfSteps} />
                    }
                </MenuAppBar>
                {React.createElement(this.props.component, this.props)}
            </div>
        );
    }
}

export default ComponentWrapper;
