import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Appointments = ({ iconColor, style, ...props }) => {
    return (
        <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 20" fill="none" style={{ width: '50px', height: '50px', ...style }}>
            <g stroke="none" fill="none">
                <path d="M6.42872 6.30156C3.93699 6.83388 2.19649 8.07516 1.08665 9.86811C0.502358 10.8142 -0.0324674 11.7796 0.0015389 12.8709C0.0448196 14.1992 0.802232 15.1526 2.35415 15.7067C3.43308 16.0938 4.61093 16.135 5.75478 16.2922C6.63276 16.4132 7.51692 16.5076 8.40108 16.6068C8.57421 16.6261 8.74733 16.6431 8.92045 16.6624V6.00879C8.07957 6.03299 7.24796 6.12735 6.42872 6.30156V6.30156Z" fill={iconColor} />
                <path d="M13.0505 17.0631C13.5204 17.0946 14.0058 17.1212 14.4633 17.0631C15.8081 16.8865 16.6923 16.2453 17.2055 15.275C17.6352 14.4644 17.6785 13.603 17.7187 12.79C17.7032 11.9335 17.6074 11.1302 17.323 10.3486C16.7016 8.6331 15.465 7.30956 13.335 6.57157C12.3859 6.2425 11.3811 6.0949 10.3424 6.03199C9.86323 6.00296 9.39023 5.9957 8.91724 6.0078V16.6614C9.53244 16.7268 10.1476 16.7897 10.7628 16.8526C11.5234 16.9276 12.2839 17.0123 13.0444 17.0655L13.0505 17.0631Z" fill={iconColor} />
                <path d="M50 0.353027H21.3884V22.7467H50V0.353027Z" fill={iconColor} />
                <path d="M25.3055 11.0965C25.3797 10.2182 25.7105 9.3737 26.5081 8.65749C28.4866 6.87422 30.9041 5.58456 33.8287 4.97481C37.0624 4.29973 39.9993 4.92158 42.6301 6.47014C44.0275 7.29282 45.0538 8.3502 45.669 9.6568C46.2564 10.9102 45.8731 12.0232 44.9827 13.0709C43.1773 15.1953 40.6794 16.6423 37.6714 17.5787C35.9834 18.1037 34.2275 18.3941 32.4159 18.4038C29.006 18.4207 26.6657 17.0294 25.865 14.4307C25.5404 13.383 25.2189 12.2119 25.3086 11.1013L25.3055 11.0965Z" fill="white" />
                <path d="M15.2798 11.5491H23.5217" stroke={iconColor} strokeWidth="0.78" strokeMiterlimit="10" />
            </g>
        </SvgIcon>
    );
};

export default Appointments;