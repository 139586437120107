import { PractitionerActions } from '../Actions/PractitionerAction';


const initialValue = {
    practitioner: {
        state: PractitionerActions.FETCH_PRACTITIONERS,
        practitioner: {},
        error: null,
        message: null
    }
};

export function PractitionerReducer(state = { ...initialValue }, action) {
    if(!Object.values(PractitionerActions).includes(action.type)) return state;

    let practitioner = {};
    if(action.type === PractitionerActions.FETCH_PRACTITIONERS) {
        practitioner = action.payload.practitioners;
    }

    return Object.assign({}, state, { practitioner: practitioner });
}

