export const sendLinkClick = (classes, text, url, pagePath, section) =>
{
    // eslint-disable-next-line no-undef
    if (gtag) {
        var data =  {
            element_classes: classes,
            element_text: text,
            element_url: url,
            element_page_path: pagePath,
            element_page_section: section
        };
        // eslint-disable-next-line no-undef
        gtag('event', 'link_click', data);
    }
};

export const sendAddToCart = (currency, itemId, itemName, itemPrice) =>
{
    // eslint-disable-next-line no-undef
    if (gtag) {
        var data =  {
            currency: currency,
            value: itemPrice,
            items: [
                {
                    item_id: itemId,
                    item_name: itemName,
                    index: 0,
                    price: itemPrice,
                    quantity: 1
                }
            ]
        };
        // eslint-disable-next-line no-undef
        gtag('event', 'add_to_cart', data);
    }
};