import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { INFO_TEXTS } from '../../Constants/BriefAssessment';
const UPFormInfoText = (props) => {
    
    const useStyles = makeStyles({
        root: {
            '& a': {
                color: '#ffffff',
            },
            border: '1px solid #ffffff', 
            width: '70%',
            margin: '0px auto',
            marginTop: 24, 
            padding: 8
        },
    });
    const classes = useStyles(props);
    const getInfoText = (question, answer) => {
        if (!question | !answer)
            return null;
        const infoText = INFO_TEXTS[question] ? INFO_TEXTS[question][answer] : null;
        return infoText;
    };
    const infoText = getInfoText(props.question, props.answer);
    
    if(!infoText) {
        props.onDisableNextButton(false);
        return <></>;
    }
    else
        props.onDisableNextButton(!infoText.allowContinue);

    return (<Box className={classes.root} sx={{ ...props.style }}
        dangerouslySetInnerHTML={{__html: infoText.text}}></Box>);
};
                    
export default UPFormInfoText;