import StripePayment from '../CustomerDetails/Payment/StripePayment';
import Confirmation from '../CustomerDetails/Confirmation';
import Subscription from '../CustomerDetails/Subscription/Subscription.js';
import BriefAssessment from '../CustomerDetails/BriefAssessment';
import PractitionersAvailability from '../Practitioner/PractitionersAvailability';
import RecommendedProgramV2 from '../CustomerDetails/RecommendedProgram/RecommendedProgramV2';
import CalibrationWheel from '../CustomerDetails/CalibrationWheel';
import AccountInformation from '../Register/AccountInformation';
import ConfirmAppointment from '../Practitioner/ConfirmAppointment';
import SimpleConfirmation from '../CustomerDetails/SimpleConfirmation';
import AccountInformationDirect from '../Register/AccountInformationDirect';
import RecommendedProgramSimple from '../CustomerDetails/RecommendedProgram/RecommendedProgramSimple';
import RecommendedProgramSingle from '../CustomerDetails/RecommendedProgram/RecommendedProgramSingle';
import MembershipForm from '../CustomerDetails/Payment/MembershipForm';

export const ProgramPages = [
    {
        key: 100,
        path: '/brief-assessment',
        component: BriefAssessment,
        title: 'PAIN ASSESSMENT',
        previousPage: '/',
        nextPage: '/calibration-wheel',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/calibration-wheel',
        component: CalibrationWheel,
        title: ' ',
        previousPage: '/brief-assessment',
        nextPage: '/recommended-program',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 102,
        path: '/recommended-program',
        component: RecommendedProgramV2,
        title: 'RECOMMENDED PROGRAM',
        previousPage: '/calibration-wheel',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 1
    }, {
        key: 103,
        path: '/account-information',
        component: AccountInformation,
        title: 'ACCOUNT INFORMATION',
        previousPage:  '/recommended-program',
        nextPage: '/program-payment',
        hideInMenu: true,
        stepNum: 2
    },
    {
        key: 105, //this page is here to support switching to VPA on the fly. Otherwise, router won't know which component to render
        path: '/practitioners-availability',
        component: PractitionersAvailability,
        title: 'PRACTITIONERS AVAILABILITY',
        previousPage: '/recommended-program',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 2
    },
    {
        key: 106,
        path: '/program-payment',
        component: StripePayment,
        title: 'PAYMENT',
        previousPage: '/recommended-program',
        nextPage: '/booking-confirmation',
        hideInMenu: true,
        stepNum: 3
    },
    {
        key: 107,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'BOOKING CONFIRMATION',
        previousPage: null,
        nextPage: null, 
        hideInMenu: true,
        stepNum: 3
    }
];

export const VpaPages = [
    {
        key: 100,
        path: '/brief-assessment',
        component: BriefAssessment,
        title: 'PAIN ASSESSMENT',
        previousPage: '/',
        nextPage: '/calibration-wheel',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/calibration-wheel',
        component: CalibrationWheel,
        title: ' ',
        previousPage: '/brief-assessment',
        nextPage: '/recommended-program',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 102,
        path: '/recommended-program',
        component: RecommendedProgramV2,
        title: 'RECOMMENDED PROGRAM',
        previousPage: '/calibration-wheel',
        nextPage: '/practitioners-availability',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 105,
        path: '/practitioners-availability',
        component: PractitionersAvailability,
        title: 'PRACTITIONERS AVAILABILITY',
        previousPage: '/recommended-program',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 2
    }, {
        key: 106,
        path: '/account-information',
        component: AccountInformation,
        title: 'ACCOUNT INFORMATION',
        previousPage: '/practitioners-availability',
        nextPage: '/program-payment',
        hideInMenu: true,
        stepNum: 3
    },
    {
        key: 107,
        path: '/program-payment',
        component: StripePayment,
        title: 'PAYMENT',
        previousPage: '/practitioners-availability',
        nextPage: '/booking-confirmation',
        hideInMenu: true,
        stepNum: 4
    },
    {
        key: 108,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'BOOKING CONFIRMATION',
        previousPage: '/practitioners-availability',
        nextPage: null, //'/risk-assessment',
        hideInMenu: true,
        stepNum: 4
    }
];

export const VpaPagesForOrganisation = [
    {
        key: 100,
        path: '/brief-assessment',
        component: BriefAssessment,
        title: 'PAIN ASSESSMENT',
        previousPage: '/',
        nextPage: '/calibration-wheel',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/calibration-wheel',
        component: CalibrationWheel,
        title: ' ',
        previousPage: '/brief-assessment',
        nextPage: '/recommended-program',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 102,
        path: '/recommended-program',
        component: RecommendedProgramV2,
        title: 'RECOMMENDED PROGRAM',
        previousPage: '/calibration-wheel',
        nextPage: '/practitioners-availability',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 105,
        path: '/practitioners-availability',
        component: PractitionersAvailability,
        title: 'PRACTITIONERS AVAILABILITY',
        previousPage: '/recommended-program',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 2
    }, {
        key: 106,
        path: '/account-information',
        component: AccountInformation,
        title: 'ACCOUNT INFORMATION',
        previousPage: '/practitioners-availability',
        nextPage: '/membership-information',
        hideInMenu: true,
        stepNum: 3
    },
    {
        key: 107,
        path: '/membership-information',
        component: MembershipForm,
        title: 'PAYMENT',
        previousPage: '/practitioners-availability',
        nextPage: '/booking-confirmation',
        hideInMenu: true,
        stepNum: 4
    },
    {
        key: 108,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'BOOKING CONFIRMATION',
        previousPage: '/practitioners-availability',
        nextPage: null, //'/risk-assessment',
        hideInMenu: true,
        stepNum: 4
    }
];

export const PregnancyProgramPages = [
    {
        key: 100,
        path: '/brief-assessment',
        component: BriefAssessment,
        title: 'PAIN ASSESSMENT',
        previousPage: '/',
        nextPage: '/calibration-wheel',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/calibration-wheel',
        component: CalibrationWheel,
        title: ' ',
        previousPage: '/brief-assessment',
        nextPage: '/recommended-program',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 102,
        path: '/recommended-program',
        component: RecommendedProgramSimple,
        title: 'RECOMMENDED PROGRAM',
        previousPage: '/calibration-wheel',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 106,
        path: '/account-information',
        component: AccountInformation,
        title: 'ACCOUNT INFORMATION',
        previousPage: '/recommended-program',
        nextPage: '/program-payment',
        hideInMenu: true,
        stepNum: 2
    },
    {
        key: 107,
        path: '/program-payment',
        component: StripePayment,
        title: 'PAYMENT',
        previousPage: '/recommended-program',
        nextPage: '/booking-confirmation',
        hideInMenu: true,
        stepNum: 3
    },
    {
        key: 108,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'BOOKING CONFIRMATION',
        previousPage: null,
        nextPage: null, 
        hideInMenu: true,
        stepNum: 3
    }
];


export const OrganisationRegistrationPages = [
    {
        key: 100,
        path: '/brief-assessment',
        component: BriefAssessment,
        title: 'PAIN ASSESSMENT',
        previousPage: '/',
        nextPage: '/calibration-wheel',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/calibration-wheel',
        component: CalibrationWheel,
        title: ' ',
        previousPage: '/brief-assessment',
        nextPage: '/recommended-program',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 102,
        path: '/recommended-program',
        component: RecommendedProgramV2,
        title: 'RECOMMENDED PROGRAM',
        previousPage: '/calibration-wheel',
        nextPage: '/account-information',
        hideInMenu: true,
        stepNum: 1
    }, {
        key: 106,
        path: '/account-information',
        component: AccountInformation,
        title: 'ACCOUNT INFORMATION',
        previousPage: '/brief-assessment',
        nextPage: '/account-confirmation',
        hideInMenu: true,
        stepNum: 2
    },
    {
        key: 108,
        path: '/account-confirmation',
        component: SimpleConfirmation,
        title: 'ACCOUNT CONFIRMATION',
        previousPage: null,
        nextPage: null,
        hideInMenu: true,
        stepNum: 3
    }
];

export const InStudioRegistrationPages = [
    {
        key: 100,
        path: '/in-studio',
        component: AccountInformationDirect,
        title: 'ACCOUNT INFORMATION',
        previousPage: null,
        nextPage: '/account-confirmation',
        hideInMenu: true,
        stepNum: 1
    },
    {
        key: 101,
        path: '/account-confirmation',
        component: SimpleConfirmation,
        title: 'ACCOUNT CONFIRMATION',
        previousPage: null,
        nextPage: null,
        hideInMenu: true,
        stepNum: 2
    }
];

export const DailyWorkoutPages = [
    {
        key: 1,
        path: '/subscription',
        component: Subscription,
        title: 'Select your membership',
        nextPage: '/payment',
        stepNum: 1
    },
    {
        key: 2,
        path: '/payment',
        component: StripePayment,
        title: 'Payment',
        nextPage: '/confirmation',
        previousPage: '/subscription',
        stepNum: 2
    }, {
        key: 3,
        path: '/confirmation',
        component: Confirmation,
        title: 'COMPLETE',
        previousPage: '/payment',
        hideInMenu: true,
        stepNum: 3
    }
];


export const FollowUpBookingPages = [
    {
        key: 501,
        path: '/practitioners-availability',
        component: PractitionersAvailability,
        title: 'PRACTITIONERS AVAILABILITY',
        nextPage: '/payment',
        hideInMenu: false,
        stepNum: 1
    },
    {
        key: 502,
        path: '/payment',
        component: StripePayment,
        title: 'Payment',
        nextPage: '/booking-confirmation',
        previousPage: '/practitioners-availability',
        stepNum: 2
    }, 
    {
        key: 503,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'BOOKING CONFIRMATION',
        previousPage: null,
        hideInMenu: true,
        stepNum: 2
    }
];


export const PhysioRecommendationPages = [
    {
        key: 601,
        path: '/recommended-product',
        component: RecommendedProgramSingle,
        title: 'Recommended Program',
        nextPage: '/payment',
        hideInMenu: false,
        stepNum: 1
    },
    {
        key: 602,
        path: '/payment',
        component: StripePayment,
        title: 'Payment',
        nextPage: '/booking-confirmation',
        previousPage: '/recommended-product',        
        hideInMenu: false,
        stepNum: 2
    }, 
    {
        key: 603,
        path: '/booking-confirmation',
        component: Confirmation,
        title: 'CONFIRMATION',
        previousPage: null,
        hideInMenu: true,
        stepNum: 2
    }
];


export const AllowedPatterns = {
    AlphabetNumberDotCommaCheck: /^[A-Za-z0-9,.'-]+$/,
    AlphabetNumberDotCommaCheckWithSpace: /^[A-Za-z0-9 ,.'-]+$/,
    NumericCheck: /^\d+$/,
    NumericRangeCheck: /^[0-9]+$/,
    YearRangeCheck: /^[20-9]+$/
};

export const ErrorMessages = {
    AlphabetNumberDotCommaCheck:
        'Invalid values. The only values permitted are [A-Z 0-9 . , \' - ]',
    NumericCheck: 'Invalid values. The only values permitted are [0-9]',
    AlphabetNumberDotCommaCheckWithSpace:
        'Invalid values. The only values permitted are [A-Z 0-9 . , \' - ]. You must have 2 or more words.',
    FailedInUpdatingRegistrationDetails:
        'Failed in updating registration details',
};

export const NotificationSeverity = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export const AuthResult = {
    SUCCESS: 'SUCCESS',
};

export const SnackbarAutoHideDuration = 2000;

export const CVCPlaceHolder = 'CVV/CVC';

export const AuthenticationTitle = {
    SignUp: { title: 'Create your account', progress: 0 },
    VerifySignUp: { title: 'VERIFY YOUR ACCOUNT', progress: 1 },
    SignIn: { title: 'Login', progress: 0 },
    ForgotPassword: { title: 'FORGOT PASSWORD', progress: 0 },
    ForgotPasswordConfirmation: { title: 'CHANGE PASSWORD', progress: 0 },
};

export const ConfirmationLinks = {
    Account: 'https://www.mydna.life/',
    myDNA: 'https://www.mydna.life/',
};

export const PrivacyPolicyLink = 'https://www.universalpracticeapp.com.au/privacy-policy/';

export const TermsConditionsLink = 'https://www.universalpracticeapp.com.au/terms-conditions/';

export const LocalStorageVariables = {
    PatientBriefAssessmentFormId: 'PatientBriefAssessmentFormId',
    Subscription: 'Subscription',
    PractitionerImage: 'PractitionerImage',
    SelectedProgram: 'SelectedProgram',
    Templates: 'Templates'
};

export const SessionStorageVariables = {    
    AccessStatus: 'AccessStatus',
    BriefAssessmentForm: 'BriefAssessmentForm',
    Patient: 'Patient',
    RiskAssessmentForm: 'RiskAssessmentForm',
    PatientRiskAssessmentFormId: 'PatientRiskAssessmentFormId',    
    PatientAppointmentData: 'PatientAppointmentData',
    HealthReport: 'HealthReport',
    Organisation: 'Organisation',
    PrdCouponCode: 'up-prd-couponcode',
    PrdReferralId: 'up-prd-referralId',
    PrdReferralName: 'up-prd-referralName',
};

export const CRMFormTypes = {
    RADIOBUTTONS: 'radiobuttons',
    CHECKBOXES: 'checkboxes',
    TEXT: 'text',
    PARAGRAPH: 'paragraph',
    DATE: 'date',
    SIGNATURE: 'signature'
};

export const CRMFormNames = {
    BRIEF_ASSESSMENT: 'Brief_Assessment',
    BRIEF_ASSESSMENT_PREGNANCY: 'Brief_Assessment_Pregnancy',
    RISK_ASSESSMENT: 'Risk_Assessment'
};

export const QueryParameterNames = {
    RedirectTo: 'redirectTo',
    P: 'p', //program
    T: 't',
    F: 'f', //flow
    O: 'o',
    R: 'r' //recommended
}; 

export const EMERGENCY_STRING = 'medicalemergency';

export const AUTH_ACCESS_METHOD = {
    DEFAULT: 'def',
    REGISTRATION: 'reg',
    RISK_ASSESSMENT: 'ria',
    FOLLOWUP_BOOKING: 'fub',
    NEW_CONDITION_BOOKING: 'ncb',
    ORGANISATION_REGISTRATION: 'org',
    ISA_REGISTRATION: 'isa',
    PHYSIO_RECOMMENDATION: 'prd',
    BOOK_NOW: 'bnw'
};

export const SubscriptionGroups = {
    VPA: 'vpa',
    MBS: 'mbs',
    LBP: 'lbp',
    ISA: 'isa',
    PRG: 'prg',
    HIP: 'hip',
    NCK: 'nck',
    UPB: 'upb',
    SHL: 'shl',
};

export const SubscriptionTypes = {
    AddOn: 'AddOn',
    Premium: 'Premium',
    Program: 'Program'
};
// export const LbpProductId = {
//     Rehab: 'unipr_lbp_onetime_au001',
//     Strength1: 'unipr_lbp_onetime_au002',
//     Strength2: 'unipr_lbp_onetime_au003',
// };


export const VpaProductId = {
    VPA1: 'unipr_vpa_onetime_au001'
};

export const OrgProductId = {
    Generic: 'unipr_org_generic_au001'
};

export const PregnancyProductId = {
    AnteNatal: 'unipr_prg_subscription_au001',
    PostNatal: 'unipr_prg_subscription_au002'
};

export const IsrProductId = {
    Isa1: 'unipr_isa_onetime_au001'
};
export const EntryPaths = 
{
    InStudio: 'in-studio'
};
export const AllowedEntryPaths = [
    'login', //entry for mbs
    'brief-assessment', //entry for vpa & lbp
    EntryPaths.InStudio //entry for isa
];

export const DateSettings = 
{
    Min: '1900-01-01',
    Max: '2099-12-31'
};

export const TokenPurposes = {
    Login: 'Login',
};


export const PatientCustomFieldNames = {
    MEMBERSHIP_NUMBER: 'Membership Number',
    INSURER: 'Insurer',
    PRIVATE_HEALTH_INSURANCE_NAME: 'Private Health Insurance Name',
};