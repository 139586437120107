export const AUTO_LOGIN = process.env.REACT_APP_EXP_API_AUTO_LOGIN;
import { AUTH_ACCESS_METHOD, SessionStorageVariables, QueryParameterNames } from '../Shared/Constants';

export async function autoLogin(token) {
    try {
        const res = await fetch(`${AUTO_LOGIN}/Token/${token}`, { method: 'GET' });
        if (!res.ok) {
            throw new Error('Invalid server response');
        }
  
        const data = await res.json();
        return data;
    } catch (e) {
        return null;
    }
}

export async function setAuthAccessMethod()
{ 
    const searchQuery = window.location.search;
    if (searchQuery)
    {
        const urlParams = new URLSearchParams(searchQuery);
        const redirectTo = urlParams ? urlParams.get(QueryParameterNames.RedirectTo) : '';
        if (redirectTo && redirectTo === 'risk-assessment')
        {
            const lbpAccessStatus = { program: this.state.programName, access: AUTH_ACCESS_METHOD.RISK_ASSESSMENT };
            sessionStorage.setItem(SessionStorageVariables.AccessStatus, JSON.stringify(lbpAccessStatus));
        }
        else
        {
            const flow = urlParams ? urlParams.get(QueryParameterNames.F) : '';
            const program = urlParams ? urlParams.get(QueryParameterNames.P) : '';
            if (flow)
            {
                switch (flow)
                {
                    case AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING:
                    {
                        const lbpAccessStatus = { program: program, access: AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING };
                        sessionStorage.setItem(SessionStorageVariables.AccessStatus, JSON.stringify(lbpAccessStatus));
                        break;
                    }
                    case AUTH_ACCESS_METHOD.NEW_CONDITION_BOOKING:
                    {
                        const lbpAccessStatus = { program: program, access: AUTH_ACCESS_METHOD.NEW_CONDITION_BOOKING };
                        sessionStorage.setItem(SessionStorageVariables.AccessStatus, JSON.stringify(lbpAccessStatus));
                        break;
                    }
                    default:
                        break;
                }
           
            }
        }
    }
}