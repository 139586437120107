import React from 'react';
import { Tab, Tabs, Typography } from '@material-ui/core';
import TabPanel from './TabPanel';
import Authentication from './Authentication';
import { RegisterActions } from '../Actions/RegisterActions';
import { useState } from 'react';
export function AuthTab({ tabValue, dispatch, router, register, nextPage, previousPage }) {
    return (
        <>
            <TabPanel value={tabValue} index={0}>
                <Authentication
                    authState={RegisterActions.SIGN_IN}
                    {...register}
                    dispatch={dispatch}
                    router={router}
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>          
                <Typography variant="body2" >To complete your booking and create your personalised online practice we need to ask for your:</Typography>
                <Authentication
                    authState={RegisterActions.SIGN_UP}
                    {...register}
                    dispatch={dispatch}
                    router={router}
                    title=' '
                    nextPage={nextPage}
                    previousPage={previousPage}
                />
            </TabPanel>
        </>
    );
}


export function AccountTab(props) {
    const [tabValue, setTabValue] = useState(props.tabValue);


    return (<>
     
        <div style={{
            color: '#ffffff', display: 'flex', justifyContent: 'center', marginTop: '43px'
        }}>
            <Tabs
                value={tabValue} onChange={(e, value) => setTabValue(value)}
            >
                <Tab label="Existing User" value={0} className="up-tab" />
                <Tab label="New User" value={1} className="up-tab"  />
            </Tabs>
        </div>
        <AuthTab tabValue={tabValue} dispatch={props.dispatch} register={props.register} router={props.router} nextPage={props.nextPage} previousPage={props.previousPage}/></>);
}
