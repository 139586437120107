import React from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { TextField } from '@material-ui/core';

const MyDNACardNumber = (otherProps) => {
    const [errorMessage, setErrorMessage] = React.useState(otherProps.errorMessage);
    const [shrink, setShrink] = React.useState(false);
    const [empty, setEmpty]=React.useState(true);

    function handleElementChange({ error, empty }) {
        setEmpty(empty);
        if (error) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage(null);
        }
    }

    const hasError = errorMessage?.length > 0;

    return (
        <TextField
            className="up-standard-input"
            fullWidth
            label="Card number"
            error={hasError}
            helperText={hasError ? errorMessage || 'Invalid' : ''}
            onChange={handleElementChange}
            InputLabelProps={{
                shrink
            }}
            onFocus={()=> setShrink(true)}
            onBlur={()=> setShrink(!empty)}  
            InputProps={{
                inputProps: {
                    component: CardNumberElement,
                    options: {
                        iconStyle:'solid',
                        placeholder: '',
                        style: {
                            base: {
                                fontSize: '18px',
                                color: '#fff',
                                '::placeholder': {
                                    color: '#fff',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },

                    },                    
                },
                inputComponent: StripeInput,
                className: 'up-standard-input',
            }}
            {...otherProps}
        />
    );
};

export const StripeInput = (props) => {
    const { component: Component, inputRef, ...other } = props;
    const elementRef = React.useRef();
  
    React.useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current.focus
    }));
  
    return (
        <Component onReady={element => (elementRef.current = element)} {...other} />
    );
};

export default MyDNACardNumber;
