import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { selectApplinkURL } from '../Reducers/CustomerDetailsReducer';
import {  Typography } from '@material-ui/core';

class SimpleConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AppLinkURL: selectApplinkURL(props.barcode?.partner),
        };
        this.AnimationContainer = React.createRef();
    }
    componentDidMount() {
        const url = document.URL;
        window.history.pushState(null, null, url);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, url);
        });
        //end of registration, clear everything
        sessionStorage.clear();
        localStorage.clear();
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', () => { });
    }

    goNextStep() {
        this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}`));
    }

    render() {
        return (
          
            <div className={'confirmation-container'}>
                <div style={{marginTop:48}}>
                    <Typography variant='h5' style={{fontSize: 36, fontFamily: 'Recoleta'}}>
                    Welcome to the Universal Practice Virtual Physiotherapy.
                    </Typography>
                    <Typography gutterBottom variant="h6" component="p">
                        <br/><br/>Please download the below app to your phone. <br/>
                        If you are currently on a computer, please check your emails (make sure to check your junk) via your phone and you will find a link to download our app.
                    </Typography><br/>
                    <a href={this.state.AppLinkURL.IOS_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/app-store-badge.png')} alt="IOS store icon" className={'store-badges'} /></a>
                    <a href={this.state.AppLinkURL.Android_URL} target="_blank" rel="noopener noreferrer"><img src={require('../Assets/google-play-badge.png')} alt="Play store icon" className={'store-badges'} /></a>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SimpleConfirmation);
