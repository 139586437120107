import { Button, Icon, InputAdornment, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MyDNATextInput from '../../Shared/MyDNATextInput.js';
import { QueryParameterNames, AUTH_ACCESS_METHOD, SessionStorageVariables } from '../../Shared/Constants.js';


const Coupon = ({ setFieldValue, onClick, value, errorMessage, discountAmount, onClearCode }) => {
    const [couponCode, setCouponCode] = useState('');

    useEffect(() => {
        const searchQuery = location.search;
        const flow = new URLSearchParams(searchQuery).get(QueryParameterNames.F);

        if (flow && flow === AUTH_ACCESS_METHOD.PHYSIO_RECOMMENDATION)
        {
            var couponCode = sessionStorage.getItem(SessionStorageVariables.PrdCouponCode);
            if (couponCode)
            { 
                setFieldValue('coupon', couponCode);
                value = couponCode;
                setCouponCode(couponCode);
            }
        }        
    },[]);

    useEffect(() => {
        if (couponCode)
            onClick();
    },[couponCode]);
    
    const onApplyClick = (event) => {
        onClick();
        event.preventDefault();
    };

    const onRemoveClick = (event) => {
        onClearCode();
        event.preventDefault();
    };

    return (
        <MyDNATextInput
            name="coupon"
            label="Enter promo code"
            setFieldValue={setFieldValue}
            value={value}
            inputProps={{
                endAdornment:<InputAdornment position="end">{!discountAmount || discountAmount.length === 0 ? <Button variant='text' onClick={onApplyClick}>Apply</Button> : <Button variant='text' onClick={onRemoveClick}>Remove</Button>}</InputAdornment>
            }}
            validationErrorText={errorMessage}
        />          
    );
};

export default Coupon;
