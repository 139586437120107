import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, Box, FormGroup, Typography, TextField, Grid } from '@material-ui/core';

import Brightness1Icon from '@material-ui/icons/Brightness1';
import { CRMFormTypes, DateSettings } from '../../Shared/Constants';
import StyledTextField, { StyledTextFieldAlignment } from '../StyledTextField';
import UPFormHelperText from './UPFormHelperText';
import { clone, removeSpecialCharacters } from '../../utils';

import UPSwitch from './UPSwitch';
import { UPSlider } from './UPSlider';

import classes from './UPForm.module.scss';
import UPFormInfoText from './UPFormInfoText';

const getMaxWidth = (answers) => {
    const width = (Math.max(...(answers.map(el => el.value.length))) * 12) + 42;
    return width < 100 ? '100px' : width;
};

const UPFormSection = ({ questions, onChange, sectionIndex, conditions, slot, sliderMapping, onDisableNextButton }) => {
    const [lastAnsweredQuestion, setLastAnsweredQuestion]  = useState(null);
    const [lastAnswer, setLastAnswer]  = useState(null);

    useEffect(() => {
        if(process.env.NODE_ENV === 'test') {
            return;
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    
    useEffect(() => {
        setLastAnsweredQuestion(null);
        setLastAnswer(null);
        onDisableNextButton(false);
    },[sectionIndex]);
   
    
    const handleFormChange = (mainIndex, event, result, subIndex, reset) => {
        const data = clone(questions);

        setLastAnsweredQuestion(data[mainIndex]?.name);
        setLastAnswer(event?.target?.value);

        if(data[mainIndex]?.answers?.[subIndex] && !reset) {
            data[mainIndex].answers[subIndex] = { ...data[mainIndex].answers[subIndex], selected: result ? result : undefined };
            onChange(data, sectionIndex);
            return;
        }

        if(data[mainIndex]?.answers?.[subIndex] && reset) {
            data[mainIndex].answers = data[mainIndex].answers.map((res) => ({ ...res, selected: undefined }));
            data[mainIndex].answers[subIndex] = { ...data[mainIndex].answers[subIndex], selected: result ? result : undefined };
            onChange(data, sectionIndex);
            return;
        }
      
        data[mainIndex].answer = event.target.value;
        onChange(data, sectionIndex);
    };


    const CheckBoxes = (field, key, index) => {
        if(!field) {
            return null;
        }

        const calculatedWidth = getMaxWidth(field.answers);
        const width = calculatedWidth > 400 ? calculatedWidth : 'auto';
        return field.answers.map((p, i) => (
            <FormControlLabel key={`${key}-${p.value}-${i}`}
                style={{ width }}
                control={<UPSwitch checked={!!p.selected} name={p.value} onChange={(e) => handleFormChange(index, e, e.target.checked, i)} />}
                label={p.value}
            />
        ));
    };

    const RadioButtons = (field, key, index, calculateMax) => {
        if(!field) {
            return null;
        }
        
        if(field?.answers?.[0]?.value && !isNaN(field?.answers?.[0]?.value) && field?.answers?.length >= 5) {
            const labelMap = sliderMapping?.[removeSpecialCharacters(field?.name)?.toLowerCase()];
            const step = Number(field.answers[1].value) - Number(field.answers[0].value);
            const marks = [];
            let selected = 0;
            field.answers.forEach((p, i) => {
                if(p.selected) {
                    selected = i;
                }

                marks.push({ value: Number(p.value), label: p.value });
            });

            selected = selected && selected > -1 ? selected : 0;
            return {
                RadioButtonComponent: <UPSlider
                    value={Number(field?.answers?.[selected]?.value)}
                    step={step}
                    min={Number(field?.answers?.[0].value)}
                    max={Number(field?.answers?.[field.answers.length - 1].value)}
                    marks={marks}
                    labelMap={labelMap}
                    onChangeCommitted={(e, value) => {
                        const i = field?.answers?.findIndex((p) => Number(p.value) === value);
                        handleFormChange(index, e, true, i, true);
                    }}
                />,
                selectedValue: field?.answers?.[selected]?.value
            };
        }

        const calculatedWidth = getMaxWidth(field.answers);
        const width = (calculatedWidth > 400 || calculateMax) ? calculatedWidth : 'auto';
        const space = field?.answers?.length > 2 ? 'space-between' : 'center';
        let selectedValue;
        return {
            RadioButtonComponent: (<><RadioGroup className={classes.upform__rbwrapper} row style={{ color: 'white', justifyContent: calculateMax ? 'space-left' : space }}>
                {field.answers.map((p, i) => {
                    if(p.selected) {
                        selectedValue = p.value;
                    }

                    return <FormControlLabel className={classes.upform__rbitem}  value={p.value} key={`${key}-${p.value}-${i}`}
                        style={{ width }}
                        control={<Radio checkedIcon={<Brightness1Icon />} checked={!!p.selected} onChange={(e) => handleFormChange(index, e, e.target.checked, i, true)} style={{ color: 'white' }} />}
                        label={p.value}
                    />;
                })}
                {/* {field?.other &&                
                    <Box sx={{ m: 4, mt: 5, mb: 5, textAlign: 'center', justifyContent: 'center', width: '100%' }} key={key}>
                        <Typography variant='h5' className={classes.upform__questions} component="legend">If other, please specify{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                        <Box sx={{ maxWidth: '100%' }} >
                            <StyledTextField
                                id={field.name}
                                title=""
                                name={`${field.name}${index}`}
                                onChange={(e) => handleFormChange(index, e)}
                                value={field?.other.value || ''}
                                error={!!field.invalid}
                                helperText="Please fill out"
                                helperStyle={{
                                    textAlign: 'center',
                                    width: '100%',
                                    fontStyle: 'italic',
                                    color: '#f44336'
                                }}
                                style={{
                                    maxWidth: '360px'
                                }}
                            />
                        </Box>
                    </Box>
                } */}
            </RadioGroup>
            <UPFormInfoText answer={lastAnswer} question={lastAnsweredQuestion} onDisableNextButton={onDisableNextButton}></UPFormInfoText>
            </>),
            selectedValue
        };
    };

    const getName = (name) => {
        if(!conditions || !Object.keys(conditions).length || !conditions?.conditions || !conditions?.answers) {
            return name;
        }

        const cleanedName = removeSpecialCharacters(name)?.toLowerCase();
        const selected = conditions?.conditions[cleanedName];
        if(!selected) {
            return name;
        }

        return name.replace(selected.replaceText.toLowerCase(), conditions.answers[selected.source].toLowerCase());
    };

    return ((questions || []).map((field, index) => {
        const key = `${field.type}-${index}-${sectionIndex}`;
        if(conditions?.defaultFields?.[sectionIndex]?.[index]?.hidden) {
            return null;
        }

        switch(field.type) {
            case CRMFormTypes.CHECKBOXES:
                return <Box sx={{ m: 3, textAlign: 'center', mt: 5, mb: 5, width: '100%' }} key={key}>
                    <FormControl className={classes.form__control} component="fieldset" variant="standard" error={field.invalid}>
                        <Typography variant='h5' className={classes.upform__questions} component="legend">{getName(field.name, index)}{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                        <FormGroup style={{ color: 'white', justifyContent: field?.answers?.length > 2 ? 'space-between' : 'center' }} row>{CheckBoxes(field, key, index)}</FormGroup>
                        <UPFormHelperText makeVisible={field.invalid} >Please select one</UPFormHelperText>
                    </FormControl>
                </Box>;
            case CRMFormTypes.RADIOBUTTONS: {
                const ImageSlot = slot?.[CRMFormTypes.RADIOBUTTONS]?.[removeSpecialCharacters(field.name)?.toLowerCase()] || '';
                if(ImageSlot) {
                    const { RadioButtonComponent, selectedValue } = RadioButtons(field, key, index, true);
                    return <Box sx={{ m: 3, textAlign: 'left', mt: 5, mb: 5, width: '100%', display: 'flex' }} key={key}>
                        <FormControl className={classes.form__control} component="fieldset" variant="standard" error={field.invalid}>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <Typography variant='h5' style={{ textAlign: 'left', marginBottom: '50px' }} className={'up-form-test-rb-label'} component="legend">{getName(field.name, index)}{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                                    {RadioButtonComponent}
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <ImageSlot value={selectedValue} />
                                </Grid>
                            </Grid>
                            <UPFormHelperText makeVisible={field.invalid} >Please select one</UPFormHelperText>
                        </FormControl>
                    </Box >;
                }

                const { RadioButtonComponent } = RadioButtons(field, key, index);
                return <Box sx={{ m: 3, textAlign: 'center', mt: 5, mb: 5, width: '100%', display: 'flex' }} key={key}>
                    <FormControl className={classes.form__control} component="fieldset" variant="standard" error={field.invalid}>
                        <Typography variant='h5' className={`${classes.upform__questions} up-form-test-rb-label`} component="legend">{getName(field.name, index)}{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                        {RadioButtonComponent}
                        <UPFormHelperText makeVisible={field.invalid} >Please select one</UPFormHelperText>
                    </FormControl>
                </Box >;
            }
            case CRMFormTypes.TEXT:
                return <Box sx={{ m: 4, mt: 5, mb: 5, textAlign: 'center', justifyContent: 'center', width: '100%'}} key={key}>
                    <Typography variant='h5' className={classes.upform__questions} component="div">{field.name}{field.required && <span style={{ marginLeft: '5px', width: '100%' }}>*</span>}</Typography>
                    <Box sx={{ maxWidth: '100%', justifyContent: 'center', flexDirection: 'row' }} >
                        <StyledTextField
                            id={field.name}
                            title=""
                            name={`${field.name}${index}`}
                            onChange={(e) => handleFormChange(index, e)}
                            value={field.answer || ''}
                            error={!!field.invalid}
                            helperText="Please fill out"
                            helperStyle={{
                                textAlign: 'center',
                                width: '100%',
                                fontStyle: 'italic',
                                color: '#f44336'
                            }}
                            style={{
                                maxWidth: '360px',
                            }}
                            textClassName="up-standard-input__center"
                            fieldAlignment={StyledTextFieldAlignment.CENTER}
                        />
                    </Box>
                </Box>;
            case CRMFormTypes.DATE:
                return <Box sx={{ m: 4, mt: 5, mb: 5, textAlign: 'center', justifyContent: 'center', width: '100%'}} key={key}>
                    <Typography variant='h5' className={classes.upform__questions} component="div">{field.name}{field.required && <span style={{ marginLeft: '5px', width: '100%' }}>*</span>}</Typography>
                    <Box sx={{ maxWidth: '100%', justifyContent: 'center', flexDirection: 'row' }} >
                        <StyledTextField
                            id={field.name}
                            title=""
                            name={`${field.name}${index}`}
                            onChange={(e) => handleFormChange(index, e)}
                            value={field.answer || ''}
                            error={!!field.invalid}
                            helperText="Please fill out"
                            helperStyle={{
                                textAlign: 'center',
                                width: '100%',
                                fontStyle: 'italic',
                                color: '#f44336'
                            }}
                            style={{
                                maxWidth: '360px',
                            }}
                            textClassName="up-standard-input__center"
                            fieldAlignment={StyledTextFieldAlignment.CENTER}
                            type="date"
                            inputProps={{inputProps: { min: DateSettings.Min, max: DateSettings.Max} }}
                        />
                    </Box>
                </Box>;
            case CRMFormTypes.PARAGRAPH:
                return <Box sx={{ m: 4, mt: 5, mb: 5, textAlign: 'center', width: '100%' }} key={key}>
                    <FormControl className={classes.form__control} component="fieldset" variant="standard" error={field.invalid}>
                        <Typography variant='h5' className={classes.upform__questions} component="legend">{field.name}{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                        <Box className='up-standard-input'>
                            <TextField
                                multiline
                                className={`${classes.textarea}`}
                                aria-label={field.name}
                                minRows={1}
                                value={field.answer || ''}
                                onChange={(e) => handleFormChange(index, e)}
                            />
                        </Box>
                        <UPFormHelperText makeVisible={field.invalid} >Please fill out</UPFormHelperText>
                    </FormControl>
                </Box>;
            default:
                return <Box sx={{ color: '#f44336', m: 4, mt: 5, mb: 5, textAlign: 'center', width: '100%' }} key={key}>
                    <FormControl className={classes.form__control} component="fieldset" variant="standard" error={true}>
                        <Typography variant='h5' style={{ color: '#f44336' }} className={classes.upform__questions} component="legend">{field.name}{field.required && <span style={{ marginLeft: '5px' }}>*</span>}</Typography>
                        <UPFormHelperText makeVisible={true}>{`Input type ${field.type} is not supported. Please remove this from CRM.`}</UPFormHelperText>
                    </FormControl>
                </Box>;
        }
    }));
};

UPFormSection.propTypes = {};

export default UPFormSection;
