import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { QueryParameterNames } from '../../Shared/Constants';
import RecommendedProgramCardV2 from './RecommendedProgramCardV2';
import { AppStateContext } from '../../Shared/UpAppStateProvider';
import styleClasses from './RecommendedProgramV2.module.scss';
import { sendLinkClick } from '../../Analytics/AnalyticsHelper';

class RecommendedProgramSingle extends Component {
    static contextType = AppStateContext;
    state = { recommended: null, programName: '', plan: '', flow: null, toDisplayProducts: null, hasExistingSubscription: false };
    
    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const urlParams = new URLSearchParams(searchQuery);
        const recommendedProduct = urlParams.get(QueryParameterNames.R) ?? '';
        const programName = urlParams.get(QueryParameterNames.P) ?? '';
        const flow = urlParams.get(QueryParameterNames.F) ?? '';
        
        const subscriptions = this.context.subscriptions;
        const recommended = subscriptions.find((program) => program.isRecommended);
      
        let toDisplayProducts = [];
        let hasExistingSubscription = false;
        if (this.props.auth.user && this.props.auth.user.activeSubscription && recommended && 
            this.props.auth.user.activeSubscription.subscriptionOption === recommended.subscription.subscriptionOption) {
            toDisplayProducts = [];
            // place existing user product which on the same group
            const activeSubscription = subscriptions.find((prog) => prog.id === this.props.auth.user.activeSubscription.subscriptionId);
            if (recommended.subscription.subscriptionOption !== activeSubscription.subscription.subscriptionOption) {
                toDisplayProducts.push(recommended);                        
            }
            toDisplayProducts.push(activeSubscription);
            hasExistingSubscription = true;
        } else {
            //always display Recommended 1st position
            toDisplayProducts.push(recommended);
        }

        this.setState({ toDisplayProducts, recommended, programName, plan: recommendedProduct, flow, hasExistingSubscription });    
    }

    goNextStep() {
        sendLinkClick('Get Started Button', 'Get Started', `${this.props.nextPage}`, window.location.pathname, null);
        this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }

    render() {
       
        return (
            <Box sx={{ maxWidth: 1280, display: 'flex', justifyContent: 'center', margin: 'auto', flexDirection: 'column', marginTop: 120 }}>         
                {
                    this.state.toDisplayProducts && this.state.recommended && 
                    <Grid container direction="row" style={{marginBottom: 50}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography align='center' variant='h5'>
                                Our expert team have matched your specific requirements with best practice medicine and clinical guidelines.<br/>
                                Our science-based <Typography variant='h5' component='span' className={styleClasses.recommendedProgramText} >{this.state.recommended.subscription.name}</Typography> is recommended for you.
                            </Typography>
                        </Grid>
                    </Grid>
                }
                {
                    this.state.toDisplayProducts && this.state.recommended &&
                    <Grid container direction="row" style={{marginBottom: 50, justifyContent: 'center'}}>
                        {
                            this.state.toDisplayProducts.map(p =>
                                <Grid item xs={12} sm={12} md={4} lg={4} key={p.id}>
                                    <RecommendedProgramCardV2 
                                        onClick={() => this.goNextStep()} style={{ marginBottom: '40px' }} 
                                        key={p.id} 
                                        program={p} 
                                        isRecommended={p.id === this.state.recommended.id} 
                                        subscriptionOption={this.props.auth?.user?.activeSubscription?.subscriptionOption}/>
                                </Grid>
                            )
                        }
                    </Grid>
                }
            </Box >
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(RecommendedProgramSingle);