import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createRiskAssessmentForm, getFormTemplates, getRiskAssessmentFormByName, getRiskAssessmentForm, saveRiskAssessment } from '../HttpHelpers/CRMFormsHttpHelper';
import { CRMFormNames, LocalStorageVariables, SessionStorageVariables } from '../Shared/Constants';
import { clone, formatCRMFormData } from '../utils';
import UPForm from '../components/UPFormComponents/UPForm';
import UPLoader from '../Shared/UPLoader';
import { completedRequiredBriefAssessment } from '../HttpHelpers/EventsHttpHelper';
import { Box, Typography } from '@material-ui/core';
import { clearSession } from '../HttpHelpers/AuthServiceHelper';

let timeout;
class RiskAssessment extends Component {
    state = {
        programName: null,
        user: null,
        riskAssessmentForm: null,
        isSubmitting: false,
        isDraftSubmitting: false,
        error: '',
        success: '',
        userFormId: null,
        riskAssessmentFormTemplateId: null,
        isDraft: false,
        submittedAndCompleted: false
    };

    async componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const programName = new URLSearchParams(searchQuery).get('p');
        const patientId = this.props.register?.auth?.user?.patientId;
        localStorage.removeItem(LocalStorageVariables.patientAppointmentData);

        const draft = await this.checkForDraft();
        if(draft) {
            this.setState(draft);
            return;
        }

        let PatientRiskAssessmentFormId = sessionStorage.getItem(SessionStorageVariables.PatientRiskAssessmentFormId);
        let riskAssessmentForm = sessionStorage.getItem(SessionStorageVariables.RiskAssessmentForm);
        riskAssessmentForm = riskAssessmentForm ? JSON.parse(riskAssessmentForm) : null;
        riskAssessmentForm = riskAssessmentForm && riskAssessmentForm?.patientId === patientId ? { ...riskAssessmentForm, formTemplateContent: riskAssessmentForm.content } : null;

        this.setState({
            programName,
            user: this.props.register?.auth.user,
            isSubmitting: false,
            userFormId: riskAssessmentForm?.id || PatientRiskAssessmentFormId || null,
            isDraft: false
        });

        if(riskAssessmentForm) {
            return;
        }

        this.init();
    }

    goNextStep() {
        if (this.props.nextPage)
            this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}`));
    }

    showError(message) {
        this.setState({ error: message });
    }

    showSuccess(message) {
        clearTimeout(timeout);
        this.setState({ success: message });
        timeout = setTimeout(() => {
            this.setState({ success: '' }, () => {
                clearTimeout(timeout);
            });
        }, 5000);
    }

    async checkForDraft(programName) {
        try {
            const token = this.state.user?.signInUserSession?.accessToken?.jwtToken;
            const draft = await this.getDraft(token);
            if(draft && draft?.name === CRMFormNames.RISK_ASSESSMENT && draft?.content?.sections?.length && !draft.completed_at) {
                const data = { formTemplateContent: { sections: formatCRMFormData(draft) } };
                return {
                    programName,
                    user: this.state.user,
                    isSubmitting: false,
                    riskAssessmentForm: data,
                    isDraft: true,
                    userFormId: draft.id
                };
            }

            return null;
        } catch(e) {
            this.showError('Failed to get saved form');
            return null;
        }
    }

    async init() {
        const token = this.state.user?.signInUserSession?.accessToken?.jwtToken;
        if (token) {
            const templates = await this.getTemplates(token);
            if (templates) {
                const template = templates.find((form) => form.name === CRMFormNames.RISK_ASSESSMENT);
                if(!template) {
                    this.showError('Form not found.');
                    return;
                }

                this.setState({ riskAssessmentFormTemplateId: template.id });

                const data = await this.getForm(template.id, token);
                if(data.errorMessages) {
                    return this.showError('Failed get patient form.');
                }

                data.formTemplateContent.sections = formatCRMFormData(data);
                this.setState({ riskAssessmentForm: data });
            }
        }
    }

    async getTemplates(token) {
        try {
            if(!token) {
                this.showError('Invalid token');
                return;
            }

            const res = await getFormTemplates(token);
            const json = await res.json();
            return json;
        } catch(err) {
            this.showError('Failed creating risk assessment form');
            return;
        }
    }

    async getForm(riskAssessmentFormTemplateId, token) {
        try {
            if(!riskAssessmentFormTemplateId || !token) {
                this.showError('Invalid arguments');
                return;
            }

            const res = await getRiskAssessmentForm(riskAssessmentFormTemplateId, token);
            const json = await res.json();
            return json;
        } catch(err) {
            this.showError('Failed creating patient form.');
            return;
        }
    }

    async getDraft(token) {
        try {
            if (token) {
                const res = await getRiskAssessmentFormByName(token);
                const json = await res.json();
                return json;    
            } 
            return null;
        } catch(err) {
            this.showError('Failed get saved form.');
            return;
        }
    }

    async createForm(patientId, briefAssessmentFormTemplateId, token) {
        try {
            if(!patientId || !briefAssessmentFormTemplateId || !token) {
                this.showError('Invalid arguments');
                return;
            }

            const res = await createRiskAssessmentForm(patientId, briefAssessmentFormTemplateId, token);
            const json = await res.json();
            return json;
        } catch(err) {
            this.showError('Failed creating patient form.');
            return;
        }
    }

    async saveForm(formData, token) {
        try {
            if(!formData || !token) {
                this.showError('Invalid arguments');
                return;
            }

            const res = await saveRiskAssessment(formData, token);
            const json = await res.json();
            return json;
        } catch(err) {
            this.showError('Failed save form.');
            return;
        }

    }

    async handleSubmit(event, sections, isCompleted = true) {
        event.preventDefault();

        this.setState(isCompleted ? { isSubmitting: true } : { isDraftSubmitting: true });
        const submittedForm = clone(this.state.riskAssessmentForm);
        submittedForm.formTemplateContent.sections = sections;

        this.setState({ riskAssessmentForm: submittedForm });

        let userFormId = this.state.userFormId;
        const token = this.state.user?.signInUserSession?.accessToken?.jwtToken;
        const patientId = this.props.register?.auth.user?.patientId;
        if(!patientId) {
            this.showError('Patient not found');
            return;
        }

        if(!userFormId && !this.state.isDraft && this.createForm) {
            const created = await this.createForm(patientId, this.state.riskAssessmentFormTemplateId, token);
            if(!created.isSuccess) {
                this.showError('Failed to create the form.');
                this.setState(isCompleted ? { isSubmitting: false } : { isDraftSubmitting: false });
                return;
            }

            userFormId = created.result;
            sessionStorage.setItem(SessionStorageVariables.PatientRiskAssessmentFormId, userFormId);
            this.setState({ userFormId: userFormId });
        }

        const formData = {
            id: userFormId,
            completed: isCompleted,
            content: { sections }
        };

        if(!this.saveForm || !userFormId) {
            this.showError('Failed to create the form.');
        }

        const result = await this.saveForm(formData, token);
        this.setState(isCompleted ? { isSubmitting: false } : { isDraftSubmitting: false });
        if(!result || !result?.isSuccess) {
            this.showError('Failed to save the form.');
            return;
        }

        sessionStorage.setItem(SessionStorageVariables.RiskAssessmentForm, JSON.stringify({ patientId: patientId, ...formData }));
        sessionStorage.removeItem(SessionStorageVariables.PatientRiskAssessmentFormId);
        if(!isCompleted) {
            this.showSuccess('Form has been saved as draft.');
            return;
        }
        else
        {
            this.setState({ submittedAndCompleted: true });
        }
        this.setState({ isSubmitting: true });
        await completedRequiredBriefAssessment(token);
        clearSession();
        this.setState({ isSubmitting: false }, () => {
            this.goNextStep(event);
        });
    }

    render() {
        if (this.state.submittedAndCompleted){
            return (<Box display='flex' flex='row' height='80vh' justifyContent='center' alignItems='center'><Typography variant='h5'>Thank you for completing the form. The form has been successfully submitted.</Typography></Box>
            );
        }
        return (<>
            {
                this.state.riskAssessmentForm?.formTemplateContent?.sections?.length ?
                    <UPForm
                        sections={this.state.riskAssessmentForm.formTemplateContent.sections}
                        onSubmit={(e, sections) => this.handleSubmit(e, sections)}
                        onDraftSubmit={(e, sections) => this.handleSubmit(e, sections, false)}
                        isSubmitting={this.state.isSubmitting}
                        isDraftSubmitting={this.state.isDraftSubmitting}
                        error={this.state?.error}
                        success={this.state?.success}
                        showError={(msg) => this.showError(msg)}
                        submitButtonText='SUBMIT'
                    /> :
                    <UPLoader />
            }
        </>);
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(RiskAssessment);
