import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
const ImageMask = ({ imageWidth, imageHeight, imageUrl, ...props }) => {
    const useStyles = makeStyles((theme) => ({
    
        naturalShape: {
            objectFit: 'cover',
            clipPath: 'path(\'M 77.9994 42.568 C 77.9256 59.6721 70.3609 76.7004 54.2582 82.4408 C 36.7978 88.6652 16.6941 83.3861 6.00805 68.232 C -4.28403 53.6366 -0.339809 34.3688 10.1863 19.9414 C 20.6264 5.63194 37.8101 -3.98379 54.6071 1.6207 C 71.0861 7.1191 78.0744 25.1867 77.9994 42.568 Z\')'
        }
    }));
    const classes = useStyles();
    return (
        <img width={imageWidth || '78px'} height={imageHeight || '85px'} src={imageUrl}  className={classes.naturalShape} >
        </img>
    );
};


ImageMask.propTypes = {
    children: PropTypes.node,
    imageUrl: PropTypes.string,
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string,
};

export { ImageMask };
export default ImageMask;

