
export function validateEmailHost(emailAddress, organisation)
{
    if (organisation && organisation.domains)
    {
        const emailHost = emailAddress.substring(emailAddress.indexOf('@') + 1);
        const valid = organisation.domains.includes(emailHost.toLowerCase());          
        return valid;  
    }
    return false;
}

export function getDefaultTimeZone()
{
    let timeZone = 'Australia/Sydney';
    if (window.Intl && typeof window.Intl === 'object') {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return timeZone;
}