import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar, useMediaQuery } from '@material-ui/core';

import { textCapitalize } from '../utils';

import appLogo from '../Assets/Universal_Practice_CMYK_White-300-100.png';

import { AUTH_ACCESS_METHOD, SessionStorageVariables } from './Constants';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#000'
    },
    logo: {
        width: 'auto',
        height: '43px',
    },
    appTitle: {
        display: 'flex',
        justifyContent: 'right',
        textAlign: 'right',
        textTransform: 'capitalize',
        fontSize: '34px',
        zIndex: 100
    },
    alignLeft: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        gap: '5px'
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    logoutBtn: {
        padding: '10px 20px',
        height: '32px',
        marginLeft: '12px',
        position: 'relative',
        top: '8px'
    }
}));

export default function MenuAppBar({ children, title, auth, dispatch, }) {
    const [display, setDisplay] = useState(true);
    const [organisation, setOrganisation] = useState();
    const theme = useTheme();
    const breakPointSM = useMediaQuery(theme.breakpoints.down('md'));
    const logoRef = useRef();

    useEffect(() => {
        let stored = sessionStorage.getItem(SessionStorageVariables.LBPAccessStatus);
        const organisationJson = sessionStorage.getItem(SessionStorageVariables.Organisation);
        if (organisationJson) {
            const org = JSON.parse(organisationJson);
            setOrganisation(org);
        }

        if(!stored) {
            return;
        }

        stored = JSON.parse(stored);
        if(stored.access === AUTH_ACCESS_METHOD.REBOOKING || stored.access === AUTH_ACCESS_METHOD.RISK_ASSESSMENT) {
            setDisplay(false);
        }
    }, []);

    const classes = useStyles();
    return (
        <Box className={classes.root} sx={{ padding: !breakPointSM ? '30px 120px 30px 120px' : '35px' }}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
                    <Box className={children ? classes.alignLeft : classes.alignCenter}>
                        <img src={appLogo} alt={'Universal Practice Logo'} className={classes.logo} ref={logoRef} />
                    </Box>
                    <Box>{!breakPointSM && display && (<>{children}</>)}</Box>
                    <Box className={children ? classes.appTitle : ''} >
                        <>
                            <Box sx={{ width: breakPointSM ? '210px' : 'max-content', fontSize: breakPointSM ? '22px' : '34px' }} > {textCapitalize(title)}</Box>
                            {/* {(auth?.isAuthenticated && !display) && <StyledButton onClick={() => dispatch(signOut())} outlined className={classes.logoutBtn}>Logout</StyledButton>} */}
                        </>
                    </Box>
                </Toolbar>
                {
                    organisation &&
                    <Box style={{ textAlign: 'center', width: logoRef?.current?.width }}>
                        <img src={organisation.logoUrl} alt={`${organisation.name} Logo`} className={classes.logo} />
                    </Box>
                }
            </AppBar>
        </Box >
    );
}
